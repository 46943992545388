import React, { createContext, useContext, useState, useReducer, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchGroupsAndFriends, getMessages, getTotalMessage, selectChat, selectChatType, sendMessage, resetChatMsg } from "../../store/features/chat/chatSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Create Context
const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const location = useLocation(); // Access the passed state

  const dispatch = useDispatch();
  const { groups, friends, selectedChat, chatType, messages, loading, error, isNewMsg, currentPage, hasMore, loadingMessages } = useSelector((state) => state.chat);
  const timerRef = useRef(null);
  const prevSelectedUserChatRef = useRef(null);
  const messageContainerRef = useRef(null);

  const [page, setPage] = useState(1);
  const [perPagePosts, setPerPagePosts] = useState(10);

  useEffect(() => {
    dispatch(fetchGroupsAndFriends()); // Fetch friends and groups on component mount
  }, [dispatch]);

  // SEARCH
  const [searchTerm, setSearchTerm] = useState("");

  const filteredFriends = friends.filter((friend) => friend?.details?.pet_name?.toLowerCase().includes(searchTerm.toLowerCase()));
  const filteredGroups = groups.filter((group) => group?.name?.toLowerCase().includes(searchTerm.toLowerCase()));
  // SEARCH OVER

  // GET MESSAGES FROM ID
  useEffect(() => {
    if (!id) return;
    dispatch(resetChatMsg());

    const { friend, group } = location.state || {};
    const chatUser = friend || group;
    const type = friend ? 1 : group ? 2 : null;

    if (chatUser && type) {
      dispatch(selectChatType(type));
      dispatch(selectChat(chatUser));
      setSelectedUserChat(chatUser);
      setActiveTab(friend ? "friends" : "groups");
      prevSelectedUserChatRef.current = chatUser;

      // Fetch messages immediately and start the timer
      const fetchMessages = () => {
        // if (loadingMessages) return;
        dispatch(getMessages({ receiverId: id, type, page: 1, getMsgType :"New" }));
      };
      fetchMessages();

      // Set up a 10-second interval
      if (timerRef.current) clearInterval(timerRef.current); // Clear any previous timer
      timerRef.current = setInterval(() => {
        prevSelectedUserChatRef.current = null;
        fetchMessages();
        // if (!isNewMsg) {
        //   messageContainerRef.current.scrollTop = messageContainerRef.current.scrollTop;
        // }
      }, 8000); // Run fetchMessages every 10 seconds
      dispatch(fetchGroupsAndFriends());
      dispatch(getTotalMessage());
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current); 
    };
  }, [id, location.state, dispatch]);

  const handleSelectChat = (chat) => {
    // navigate(`messages/${chat?.id}`);
    // dispatch(selectChat(chat)); // Select a chat to open
  };

  const fetchMoreMessages = useCallback(() => {
    // console.log('scroll fetchMoreMessages ')
    if (hasMore && !loadingMessages) {
      dispatch(getMessages({ receiverId: id, type : chatType, page: currentPage + 1, getMsgType :"Old" }));
    }
  }, [dispatch, id, chatType, currentPage, loadingMessages]);
  // const fetchMoreMessages = useCallback(() => {
  //   console.log('scroll fetchMoreMessages ')
  //   if (hasMore && !loadingMessages) {
  //     dispatch(getMessages({ receiverId: id, type : chatType, page: currentPage + 1, getMsgType :"Old" }));
  //   }
  // }, [dispatch, id, chatType, currentPage, loadingMessages]);

  const [activeTab, setActiveTab] = useState("friends");
  const [selectedUserChat, setSelectedUserChat] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef(null);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Handle emoji selection
  const handleEmojiSelect = (emojiObject) => {
    setMessage((prev) => prev + emojiObject.emoji);
    // setShowEmojiPicker(false);
  };

  // Handle file selection
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const fileType = files[0]?.type.split("/")[0];

    // Ensure all files are of the same type
    if (files.every((file) => file.type.split("/")[0] === fileType)) {
      setSelectedFiles((prev) => [...prev, ...files]);
    } else {
      toast.error("Please select files of the same type.");
    }
  };

  // Remove a file from the list
  const removeFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message?.trim() && selectedFiles.length === 0) {
      toast.error("Please type a message or choose a file!");
      return;
    }
    // Prepare the data to send
    const messageData = {
      message: message, // the text message
      receiver_id: selectedChat.id, // the ID of the selected chat user/group
      type: chatType, // 1 or 2, depending on the chat type
      files: selectedFiles, // files selected for sending
    };

    // Dispatch sendMessage and refresh messages once message is sent
    dispatch(sendMessage(messageData))
      .then(() => {
        // After message is sent, call getMessages to refresh chat
        // dispatch(getMessages({ receiverId: selectedChat.id, type: chatType }));
        setSelectedFiles([]);
        setMessage("");
      })
      .catch((error) => {});
  };

  const openDeleteModal = (id) => {
    setMessageId(id);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = useCallback(() => {
    setMessageId(null);
    setIsDeleteModalOpen(false);
  }, []);

  const value = {
    selectedUserChat,
    setSelectedUserChat,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    openDeleteModal,
    closeDeleteModal,
    message,
    setMessage,
    selectedFiles,
    setSelectedFiles,
    showEmojiPicker,
    setShowEmojiPicker,
    fileInputRef,
    activeTab,
    setActiveTab,
    searchTerm,
    setSearchTerm,

    handleMessageChange,
    handleEmojiSelect,
    handleFileSelect,
    removeFile,
    handleSubmit,
    fetchMoreMessages,

    // redux
    handleSelectChat,
    groups,
    friends,
    filteredFriends,
    filteredGroups,
    selectedChat,
    messages,

    dispatch,
    messageContainerRef,
    prevSelectedUserChatRef,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export { ChatContext, ChatProvider };
