import React, { useState, useEffect, Fragment } from "react";
import ReportPost from "./reportview";
import NewsfeedRight from "../../../birthDayCoponent/newsfeedright";

const Reportviewindex = () => {

  return (
    <Fragment>
    <div className="mainDiv">
      <div className="main-content-container">
        <div className="ossn-system-messages">
          <div className="row">
            <div className="col-md-11">
              <div className="ossn-system-messages-inner"></div>
            </div>
          </div>
        </div>
        <div className="ossn-layout-newsfeed d-lg-flex">
          <div className="newsfeed-container">
            <div className="newsfeed-middle">
              <ReportPost />
            </div>
          </div>
          <div className="newsfeed-right">
            <NewsfeedRight />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
  );
};

export default Reportviewindex;
