import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo/logo.png";
import man from "../assets/images/user/user_default.jpg";
import auth from "../assets/images/auth/left-image2.webp";
import Divider from "../assets/icoicon/divider.svg";
import Facebook from "../assets/icoicon/facebook.svg";
import Google from "../assets/icoicon/google.svg";
import Twitter from "../assets/icoicon/twitter.svg";
import Loader from "../components/common/Loader";
import API from "../utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../store/features/user/userSlice";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";

const Logins = ({ setGoogleOauth }) => {
  const dispatch = useDispatch();
  const { BASE_URL, ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [value, setValue] = useState(localStorage.getItem("profileURL"));
  // const [isMobile, setIsMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);
  const [facebookOauth, setFacebookOauth] = useState(null);

  useEffect(() => {
    if (value) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  useEffect(() => {
    const isLogin = localStorage.getItem("login");
    if (isLogin) {
      navigate("/");
    }
    // dispatch(getSoicalAuthKey("facebook"));
    // setLoading(true);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize the state on load
    // setLoading(false);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSoicalAuthKey = async (name) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.sitesettings}?name=${name}`);
      console.log(response?.data, "response");

      if (response.data.status) {
        if (!response.data.payload) return;
        if (name === "google") {
          setGoogleOauth(JSON.parse(response?.data?.payload[0].value)?.OAuth);
          // console.log(JSON.parse(response?.data?.payload[0].value)?.OAuth, "OAuth");
        } else if (name === "facebook") {
          setFacebookOauth(JSON.parse(response?.data?.payload[0].value)?.FFMPEG);
          // console.log(JSON.parse(response?.data?.payload[0].value)?.FFMPEG, "FFMPEG");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getSoicalAuthKey("google");
    getSoicalAuthKey("facebook");
  }, []);

  const validate = () => {
    const newErrors = {};

    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/; // email regex
    const mobilePattern = /^[0-9]{10}$/; // mobile number regex (10 digits)

    // Check if email is valid (email, username, or mobile)
    if (!email.trim()) {
      newErrors.email = "Username/Email is required";
    } else if (emailPattern.test(email.trim())) {
      // Valid email format
    } else if (mobilePattern.test(email.trim())) {
      // Valid mobile number format
    } else if (!/^[a-zA-Z0-9]{3,20}$/.test(email.trim())) {
      newErrors.email = "Invalid username format"; // Valid username (alphanumeric, length 3 to 20)
    } else {
      // newErrors.email = "Please enter a valid Username/Email/Mobile";
    }

    // Password validation (required and min length)
    if (!password.trim()) {
      newErrors.password = "Password is required";
    } else if (password.trim().length < 8) {
      newErrors.password = "Password must be at least 8 characters";
    }

    setErrors(newErrors); // Set the error state

    // Return true if no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const loginAuth = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      const loginForm = new FormData();
      loginForm.append("role", "user");
      loginForm.append("username", email ?? "");
      loginForm.append("password", password ?? "");

      const response = await axios.post(`${BASE_URL}${ENDPOINTS.login}`, loginForm);
      const { status, message, payload } = response.data;
      if (status) {
        toast.success(message || "Login successful");
        localStorage.setItem("login", true);
        if (payload) {
          localStorage.setItem("token", payload?.token);
          localStorage.setItem("user", JSON.stringify(payload));
          dispatch(setUser(payload));
          localStorage.setItem("user_id", payload?.id);
        }
        navigate(`/home`);
      } else toast.error(message || "Login failed");
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const socialAuth = (platform) => {
    // Simulate redirection for social logins
    navigate(`/home`);
    toast.success(`Logged in with ${platform}!`);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;
        const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${access_token}` },
        });

        // Send user data or token to your backend for authentication
        // console.log('User Info:', userInfo.data);
        const { given_name, family_name, email, name } = userInfo.data;

        const data = new FormData();
        data.append("name", given_name || "First Name");
        data.append("last_name", family_name || "Last Name");
        data.append("email", email);
        data.append("username", email.split("@")[0]);

        await authentication(data);

        // window.location.href = `https://app.xpets.com/action/social/login/google?token=${access_token}`;
      } catch (error) {
        console.error("Google Login Failed", error);
      }
    },
    onError: (error) => {
      toast.error(`Google authentication Failed! ${error}`);
      console.error("Login Failed:", error);
    },
  });

  const handleLoginSuccess = (response) => {
    // console.log('Facebook login success:', response);
    // Send access token to your backend for further processing
    // const redirectUrl = `https://app.xpets.com/action/social/login/facebook?token=${response.accessToken}`;
    // window.location.href = redirectUrl;
  };

  const handleProfileSuccess = async (profile) => {
    // console.log('Get Profile Success!', profile);

    const { name, email, id } = profile;

    const nameParts = name.split(" ");
    const firstName = nameParts[0] || "First Name";
    const lastName = nameParts.slice(1).join(" ") || " ";

    const data = new FormData();
    data.append("name", firstName);
    data.append("last_name", lastName || " ");
    data.append("email", email);
    data.append("username", email.split("@")[0]);

    await authentication(data);
  };

  const handleLoginFailure = (error) => {
    console.error("Facebook login failed:", error);
  };

  const authentication = async (formData) => {
    try {
      const response = await axios.post(`${BASE_URL}${ENDPOINTS.socialauth}`, formData);
      const { status, message, payload } = response?.data;
      // console.log('response:', response);

      if (status) {
        toast.success(message || "User authenticated successfully.");
        localStorage.setItem("login", true);
        if (payload) {
          localStorage.setItem("token", payload?.token);
          localStorage.setItem("user", JSON.stringify(payload));
          dispatch(setUser(payload));
          localStorage.setItem("user_id", payload?.id);
        }
        // navigate(`/users/userEdit`);
        navigate(`/home`);
      } else toast.error(message || "User authentication failed");
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error || error.message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="ossn-layout-startup">
      <div className="container-fluid">
        <div className={isMobile ? "" : `ossn-home-container`}>
          <div className="row ">
            <div className="col-center ossn-page-contents">
              <div className="row register-row">
                <div className="col-md-6 home-left-contents">
                  <div>
                    {/* style={{width:'767px'}} */}
                    <img className="img-responsive" width={767} src={auth} alt="Welcome" loading="lazy" />
                  </div>
                </div>
                <div className="col-md-6 home-right-contents">
                  <div className="logo">
                    <img src={logo} alt="Logo" loading="eager" />
                  </div>

                  {isMobile ? (
                    // Mobile-specific content
                    <div className="row ossn-page-contents pt-0">
                      <div className="widget-heading-home">Welcome to xPets</div>
                      <div className="widget-contents">
                        <p className="subtitle">Register now and start your adventure.</p>
                      </div>

                      <div className="login-container">
                        <div className="social-login-container">
                          <a href="https://app.xpets.com/action/social/login/facebook" className="btn btn-block facebooklogin">
                            <img src={Facebook} alt="Facebook" />
                            <div className="social-login-text">Login or Sign Up with Facebook</div>
                          </a>
                          <a href="https://app.xpets.com/action/social/login/google" className="btn btn-block facebooklogin">
                            {/* <a className="btn btn-block facebooklogin" onClick={() => handleGoogleLogin()}> */}
                            <img src={Google} alt="Google" />
                            <div className="social-login-text">Login or Sign Up with Google</div>
                          </a>
                        </div>

                        <div>
                          <img className="divider-login" src={Divider} alt="Divider" />
                          <div className="login-box-form">
                            <label>Username/Email</label>
                            <div className="form-group ossn-form-group">
                              <input type="text" name="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                              {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <label className="custom-label">Password</label>
                            <Link className="loginlink" style={{ float: "right", marginBottom: "6px" }} to="/resetlogin">
                              Reset Password
                            </Link>
                            <div className="form-group ossn-form-group">
                              <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                              {errors.password && <div className="error">{errors.password}</div>}
                            </div>
                            <div style={{ marginBottom: "39px" }}>
                              <button type="button" style={{ width: "100%", padding: "13px" }} className=" btn btn-primary" onClick={loginAuth}>
                                Login
                              </button>

                              <div style={{ marginTop: "10px", textAlign: "center" }}>
                                <span> Don’t have an account? </span>
                                <Link to="/signup" className="loginlink">
                                  Register now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  ) : (
                    // Normal screen content
                    <div className="ossn-widget-login ">
                      <div className="widget-heading-home">Welcome to xPets</div>
                      <div className="widget-contents">
                        <p className="subtitle">Register now and start your adventure.</p>
                      </div>

                      <div className="login-container">
                        <div className="social-login-container">
                          {/* <a href="https://app.xpets.com/action/social/login/facebook" className="btn btn-block facebooklogin">
                            <img src={Facebook} alt="Facebook" />
                            <div className="social-login-text">Login or Sign Up with Facebook</div>
                          </a> */}
                          <FacebookLogin
                            appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`} // Replace with your actual Facebook App ID
                            onSuccess={handleLoginSuccess}
                            onFail={handleLoginFailure}
                            onProfileSuccess={handleProfileSuccess}
                            render={({ onClick }) => (
                              <a onClick={onClick} className="btn btn-block facebooklogin">
                                <img src={Facebook} alt="Facebook" />
                                <div className="social-login-text">Login or Sign Up with Facebook</div>
                              </a>
                            )}
                          />
                          {/* <a href="https://app.xpets.com/action/social/login/google" className="btn btn-block facebooklogin"> */}
                            <a className="btn btn-block facebooklogin" onClick={() => handleGoogleLogin()}>
                            <img src={Google} alt="Google" />
                            <div className="social-login-text">Login or Sign Up with Google</div>
                          </a>
                        </div>

                        <div>
                          <img className="divider-login" src={Divider} alt="Divider" />
                          <div className="login-box-form">
                            <label>Username/Email</label>
                            <div className="form-group ossn-form-group">
                              <input type="text" name="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                              {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <label className="custom-label">Password</label>
                            <Link className="loginlink" style={{ float: "right", marginBottom: "6px" }} to="/resetlogin">
                              Reset Password
                            </Link>
                            <div className="form-group ossn-form-group">
                              <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                              {errors.password && <div className="error">{errors.password}</div>}
                            </div>
                            <div style={{ marginBottom: "39px" }}>
                              <button type="button" style={{ width: "100%", padding: "13px" }} className=" btn btn-primary" onClick={loginAuth}>
                                Login
                              </button>
                              <div style={{ marginTop: "10px", textAlign: "center" }}>
                                <span> Don’t have an account? </span>
                                <Link to="/signup" className="loginlink">
                                  Register now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <footer>
                <div className="footer-contents">
                  <div className="ossn-footer-menu">
                    <a className="menu-footer-a_copyrights" href="/">
                      © COPYRIGHT xpets
                    </a>
                    <a className="menu-footer-about" href="https://www.xpets.com/work/emerge-f42y8">
                      About
                    </a>
                    <a className="menu-footer-site" href="https://xpets.com/terms-of-service">
                      Terms and Conditions
                    </a>
                    <a className="menu-footer-privacy" href="https://www.xpets.com/privacy-policy">
                      Privacy
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logins;
