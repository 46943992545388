import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import API from "../../utils";
import axios from "axios";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import Loader from "../common/Loader";
import defalutimages from "../../assets/images/user/user_default.jpg";
import { IoMdShare } from "react-icons/io";
import SharereelModal from "./shareModal";

const Reels = () => {
    const [reelsData, setReelsData] = useState([]);
    const [currentReel, setCurrentReel] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedReelId, setSelectedReelId] = useState(null);
    const [newComment, setNewComment] = useState("");
    const [likedReels, setLikedReels] = useState(new Set());
    const [showShareModal, setShowShareModal] = useState(false); // Move this hook here
    const token = localStorage.getItem("token");
    const { BASE_URL, ENDPOINTS } = API;
    const reelRefs = useRef([]); // Ref array to track reel elements
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [perPagePosts, setPerPagePosts] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [lastReelId, setLastReelId] = useState(null)

    useEffect(() => {
        setReelsData([])
        setCurrentReel(0)
        setPage(1)
        setHasMore(true)
        setLastReelId(null)
        // fetchReels();
    }, []);

    const fetchReels = async (offset=1,limit=perPagePosts) => {
        setLoading(true); 
        try {
            const response = await axios.get(`${BASE_URL}${ENDPOINTS.posts}?forcereel=${1}&offset=${offset}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status) {
                const payload = response.data.payload;
                if (payload.length) {
                    setLastReelId(payload[payload.length - 1].id);
                    // if (response.data.payload.length < limit) {
                    //     setHasMore(false); // No more data to fetch
                    // }
                    const newReels = payload.map(reel => ({
                        id: reel.id,
                        posted_by: reel.posted_by,
                        url: reel.video_links[0],
                        description: reel.title,
                        pet_name: reel.posted_user.pet_name,
                        name: reel.posted_user.name,
                        profile_img: reel.posted_user.profile_img,
                        likes: reel.like_comment_count.like,
                        comments: reel.like_comment_count.comment,
                        commentList: [],
                        is_liked_by_user: reel.is_liked_by_user,
                    }));
                    setReelsData((prev)=> [...prev, ...newReels]);
                    if (page > 1 && newReels.length > 0) {
                        setCurrentReel(prevReel => prevReel + 1);
                    }
                    if (newReels.length < limit) {
                        setHasMore(false);
                    }
                } else {
                    setHasMore(false); // Explicitly handle empty response
                }
                // setReelsData(response.data.payload.map(reel => ({
                //     id: reel.id,
                //     posted_by: reel.posted_by,
                //     url: reel.video_links[0],
                //     description: reel.title,
                //     pet_name: reel.posted_user.pet_name,
                //     name: reel.posted_user.name,
                //     profile_img: reel.posted_user.profile_img,
                //     likes: reel.like_comment_count.like,
                //     comments: reel.like_comment_count.comment,
                //     commentList: [],
                //     is_liked_by_user: reel.is_liked_by_user,
                // })));
            }
        } catch (error) {
            console.error("Error fetching reels:", error);
            setHasMore(false);
        } finally {
            setLoading(false); // Ensure loading is set to false after data is fetched
        }
    };

    useEffect(() => {
        fetchReels(page, perPagePosts);
    }, [page]);

    // useEffect(() => {setCurrentReel(prevReel => prevReel + 1);}, [reelsData]);

    // Use Intersection Observer to handle autoplay on scroll
    useEffect(() => {
        // if (currentReel === reelsData.length - 1 && hasMore && !loading) {
        //     setCurrentReel(prevReel => prevReel + 1); // Move to the next reel after new data is appended
        // }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = reelRefs.current.indexOf(entry.target);
                    if (entry.isIntersecting && index !== -1) {
                        setCurrentReel(index);
                    }
                });
            },
            { threshold: 0.5 } // Video is considered visible when 50% in view
        );

        reelRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            reelRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [reelsData]);

    if (loading) {
        return <Loader />;
    }

    // Handle video change and reset others
    const handleReelClick = (index) => {
        setCurrentReel(index);
    };

    // Handle like count
    const likeReel = async (id) => {
        try {
            const response = await axios.post(
                `${BASE_URL}${ENDPOINTS.likescomments}`,
                { post_id: id, type: "like" },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.data.status) {
                const {is_liked_by_user, like_comment_count} = response?.data?.payload?.posts_details;
                // console.log('reelsData', reelsData)
                setReelsData((prevReelsData) => prevReelsData.map((reel) => 
                        reel.id === id ? { ...reel, is_liked_by_user: is_liked_by_user, likes: like_comment_count?.like || reel.likes + 1 }: reel
                    )
                );
                // fetchReels();
            }
        } catch (error) {
        }
    };

    // Handle unlike count
    const unlikeReel = async (likeId,id) => {
        try {
            const response = await axios.delete(
                `${BASE_URL}${ENDPOINTS.likescomments}/${likeId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.data.status) {
                // console.log('reelsData', reelsData)
                setReelsData((prevReelsData) =>prevReelsData.map((reel) => reel.id === id ? { ...reel, is_liked_by_user: null, likes: reel.likes - 1 } : reel));
                // fetchReels();
            }
        } catch (error) {
        }
    };

    // Add a new comment and make an API call
    const handleSubmitComment = async () => {
        if (newComment.trim() !== "") {
            try {
                // Send the comment request to the backend
                await axios.post(`${BASE_URL}${ENDPOINTS.likescomments}`, {
                    post_id: selectedReelId,
                    type: 'comment',
                    comment: newComment
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                });

                // Update the UI
                setReelsData((prevReels) =>
                    prevReels.map((reel) =>
                        reel.id === selectedReelId
                            ? {
                                ...reel,
                                comments: reel.comments + 1,
                                commentList: [...reel.commentList, { text: newComment, userImage: "https://i.ibb.co/x36chgX/Untitled.png" }]
                            }
                            : reel
                    )
                );
                setNewComment("");
                setIsModalOpen(false);
            } catch (error) {
            }
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedReelId(null);
    };

    const handleAddComment = async (id) => {
        setSelectedReelId(id);
        setIsModalOpen(true);

        try {
            // Fetch comments for the selected reel
            const response = await axios.get(`${BASE_URL}${ENDPOINTS.likescomments}?post_id=${id}&type=comment`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status) {
                const comments = response.data.payload.map((comment) => ({
                    text: comment.comment,
                    userImage: comment.commented_user?.profile_img || defalutimages, // Use a default image if not available
                    name: comment.commented_user?.pet_name, // Default name if not available
                    createdAt: comment.created_at,
                }));

                // Update the specific reel's comment list
                setReelsData((prevReels) =>
                    prevReels.map((reel) =>
                        reel.id === id
                            ? { ...reel, commentList: comments }
                            : reel
                    )
                );
            }
        } catch (error) {
        }
    };

    const handleShare = (id) => {
        const selectedReel = reelsData.find((reel) => reel.id === id);
        const shareUrl = `${window.location.origin}/reel/${id}`;

        if (navigator.share) {
            // Use Web Share API
            navigator.share({
                title: selectedReel.description,
                text: `Check out this reel: ${selectedReel.description}`,
                url: shareUrl,
            })
                .then(() => console.log("Reel shared successfully"))
                .catch((error) => console.error("Error sharing the reel:", error));
        } else {
            // Fallback: Copy URL to clipboard
            navigator.clipboard.writeText(shareUrl)
                .then(() => alert("Reel URL copied to clipboard!"))
                .catch((error) => console.error("Error copying URL:", error));
        }
    };
    const nextReel = () => {
        // console.log('currentReel', currentReel);
        
        // if (currentReel.id == lastReelId && hasMore) {
        //     setPage((prevPage) => prevPage + 1); // Increment page number
        //     fetchReels(page + 1,perPagePosts); // Fetch next page of videos
        // }else 
        if (currentReel === reelsData.length - 1) {
            if (hasMore && !loading) {
                setPage(prevPage => prevPage + 1);
            }else {
                setCurrentReel(0); // Loop back to the first reel
            }
        } else {
            setCurrentReel(prevReel => prevReel + 1);
        }
        // setCurrentReel((prevReel) => (prevReel + 1) % reelsData.length); // Wrap around to first reel
    };

    // Navigate to the previous reel
    const prevReel = () => {
        setCurrentReel((prevReel) => (prevReel - 1 + reelsData.length) % reelsData.length); // Wrap around to last reel
    };

    const handleSharePost = (id) => {
        setSelectedReelId(id);
        setShowShareModal(true);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };

    return (
        <div className="reels-wrapper">
            {loading ? (
                <Loader /> // If loading, show the loader
            ) : reelsData.length === 0 ? (
                <div className="no-data-message">
                    <p>No reels available at the moment.</p>
                </div>
            ) : (
                <div className="reels-container">
                    <div className="reels-list">
                        {reelsData.map((reel, index) => (
                            <div
                                key={`${reel.id}${Date.now()}${index}`}
                                className={`reel ${index === currentReel ? "active" : ""}`}
                                ref={(el) => (reelRefs.current[index] = el)}
                            // style={{ display: index === currentReel ? "block" : "none" }}
                            >
                                <ReactPlayer
                                    url={reel.url}
                                    playing={index === currentReel}
                                    width="100%"
                                    height={window.innerWidth < 768 ? "70vh" : "558px"}
                                    loop
                                />
                                <div className="reel-info">
                                    <div className="reel-user">
                                        <a href={`/user-profile-view/${reel.posted_by}`} className="d-flex" rel="noopener noreferrer" >
                                            <img
                                                src={reel.profile_img || defalutimages}
                                                alt="avatar"
                                                className="reel-avatar"
                                            />
                                            <p className="reel-username ms-2" style={{ color: '#fff' }}>{reel.pet_name}</p>
                                        </a>
                                    </div>
                                    {/* <p className="reel-caption">{reel.description}</p> */}
                                    <div className="reel-options">
                                        <span
                                            className="like-button"
                                            onClick={() =>
                                                reel.is_liked_by_user
                                                    ? unlikeReel(reel.is_liked_by_user, reel.id)
                                                    : likeReel(reel.id)
                                            }
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill={reel.is_liked_by_user ? "red" : "white"}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                            </svg>
                                            {reel.likes}
                                        </span>

                                        <span
                                            className="comment-button"
                                            onClick={() => handleAddComment(reel.id)}
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="white"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2zm0 14H4V4h16v12z" />
                                            </svg> {reel.comments}
                                        </span>
                                        <span
                                            className="share-button"
                                            onClick={() => handleSharePost(reel.id)}
                                        >
                                            <IoMdShare style={{ width: '24px', height: '24px' }} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {reelsData.length > 0 && (
                <div className="reel-navigation mx-4">
                    <button className="reels-navigation-Previous" onClick={prevReel}>
                        <FaArrowUp />
                    </button>
                    <button className="reels-navigation-Next" onClick={nextReel}>
                        <FaArrowDown />
                    </button>
                </div>
            )}

            {/* Comment Modal */}
            <Modal isOpen={isModalOpen} toggle={closeModal}>
                <div className="title d-flex justify-content-between">
                    Comments
                    <div className="close-box" onClick={closeModal} style={{ cursor: 'pointer' }}>X</div>
                </div>
                <hr />
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form">
                                <fieldset>
                                    <div className="main-comments-list">
                                        {reelsData.find((reel) => reel.id === selectedReelId)?.commentList.length === 0 ? (
                                            <p>No comments available for this reel.</p>
                                        ) : (
                                            reelsData.find((reel) => reel.id === selectedReelId)?.commentList.map((comment, index) => (
                                                <>
                                                    <div key={index} className="comment-item d-flex align-items-start mb-2">
                                                        <img
                                                            src={comment.userImage}
                                                            alt="user"
                                                            className="comment-avatar"
                                                            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                                                        />
                                                        <div className="ms-2">
                                                            <p className="comment-name mb-1"><strong>{comment.name}</strong></p>
                                                            <p className="comment-text mb-1">{comment.text}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            ))
                                        )}
                                    </div>

                                    <Input
                                        type="textarea"
                                        placeholder="Add a comment"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls my-2 float-end">
                        <a href="javascript:void(0);" onClick={handleSubmitComment} className="btn btn-primary btn-sm ms-2">Submit</a>
                        <a href="javascript:void(0);" onClick={closeModal} className="btn btn-default btn-sm ms-2">Cancel</a>
                    </div>
                </div>
            </Modal>

            <SharereelModal
                showModal={showShareModal}
                handleClose={handleCloseShareModal}
                post_id={selectedReelId}
            />
        </div >
    );
};

export default Reels;