import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../../store/features/user/userSlice";
import { deleteData, getData, postData } from "../../../utils/axiosApiReq";
import API from "../../../utils";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

const Supplements = () => {
  const { ENDPOINTS } = API;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isViewerOpen, setViewerOpen] = useState(false);
  const profileImg = useSelector(selectUserProfile);

  const [loading, setLoading] = useState(false);
  const [supplementsList, setSupplementsList] = useState([]);

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  //   const formatDateKey = (date) => date?.toISOString().split("T")[0];
  const formatDateKey = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString().split("T")[0];
  };

  const handleDateClick = async (date, id) => {
    const formattedDate = formatDateKey(date);
    try {
      setSelectedDate(date);
      setLoading(true);
      const response = id ? await deleteData(`${ENDPOINTS.supplements}/${id}`) : await postData(ENDPOINTS.supplements, { date: formattedDate });
      const { status, message } = response;
      if (status) {
        toast.success(message);
        getSupplements();
      } else {
        toast.error(message || "Error deleting supplement");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Error processing request");
    } finally {
      setLoading(false);
      setSelectedDate(null);
    }
  };

  const getSupplements = async () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based
    try {
      setLoading(true);
      const response = await getData(ENDPOINTS.supplements, { month, year });
      const { status, message, payload } = response;
      if (status) {
        const processedPayload = payload.map((item) => ({
          ...item,
          date: new Date(item.date).toISOString().split("T")[0],
        }));
        setSupplementsList(processedPayload);
        // setSupplementsList(payload);
      } else {
        toast.error(message || "Error fetching supplyments");
      }
    } catch (error) {
      toast.error(error || "Error fetching video log");
    } finally {
      setLoading(false);
    }
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const totalDays = daysInMonth(month, year);

    // const supplementsDates = new Set(supplementsList.map((supplement) => supplement.date));
    const supplementsMap = supplementsList.reduce((acc, supplement) => {
      acc[supplement.date] = supplement.id; // Map date to its corresponding ID
      return acc;
    }, {});

    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      const currentDateKey = formatDateKey(new Date(year, month, day));
      const supplementId = supplementsMap[currentDateKey];

      calendarDays.push(
        <div
          key={day}
          //   className={`day ${supplementsDates.has(currentDateKey) ? "has-story" : ""} ${
          className={`day ${supplementId ? "has-story" : ""} ${selectedDate?.getDate() === day && selectedDate.getMonth() === month && selectedDate.getFullYear() === year ? "selected" : ""
            }`}
          onClick={() => handleDateClick(new Date(year, month, day), supplementId)}
        >
          <div className="inner-div-date">{String(day).padStart(2, "0")}</div>
          {/* <div className="inner-div-date">{day}</div> */}
        </div>
      );
    }

    return calendarDays;
  };

  const changeMonth = (direction) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  useEffect(() => {
    getSupplements(); // Fetch data on initial load
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getSupplements();
    }, 500); // 300ms debounce delay

    return () => clearTimeout(debounceTimeout);
  }, [currentDate]); //monthChange Only run when `currentDate` or `monthChange` changes

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="calendar-section">
      <div className="custom-calendar">
        <div className="calendar-header">
          <button onClick={() => changeMonth(-1)}><i className="fa fa-arrow-left"></i></button>
          <span>
            {currentDate.toLocaleString("default", { month: "long" })} {currentDate.getFullYear()}
          </span>
          <button onClick={() => changeMonth(1)}><i className="fa fa-arrow-right"></i></button>
        </div>        
        {/* <hr /> */}

        <div className="calendar-grid supplements mt-3">
          <div className="day-label">Sun</div>
          <div className="day-label">Mon</div>
          <div className="day-label">Tue</div>
          <div className="day-label">Wed</div>
          <div className="day-label">Thu</div>
          <div className="day-label">Fri</div>
          <div className="day-label">Sat</div>
          {renderCalendar()}
        </div>
      </div>
    </div>
  );
};

export default Supplements;
