import React, { useEffect } from 'react';

const InfoModal = ({ show, onHide }) => {

    return (
        <div
            className={`modal fade ${show ? "show" : ""}`}
            id="infoModal"
            tabIndex="-1"
            aria-labelledby="infoModalLabel"
            aria-hidden={!show}
            style={{ display: show ? "block" : "none" }}
        >      <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="infoModalLabel">Information</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide}></button>
                    </div>
                    <div className="modal-body">
                        <div className="my-4 text-start">
                            <h5 className="mb-2">Furry Profile</h5>
                            <p className="text-muted">
                                Add your personal details and your pet’s information in one place. All info shows on your profile, except date of birth and gender, which stay private. This helps other pet lovers connect with you.
                            </p>
                        </div>
                        <div className="mb-4 text-start">
                            <h5 className="mb-2">Daily Diary</h5>
                            <p className="text-muted">
                                Upload one photo or video daily to your calendar to track memories. Once uploaded, it can’t be deleted, so pick your best moments! These daily updates will help you create a timeline of your pet’s adventures.
                            </p>
                        </div>
                        <div className="mb-4 text-start">
                            <h5 className="mb-2">Timeline</h5>
                            <p className="text-muted">
                                Your timeline showcases all your posts and any shared posts from friends. It’s the best way to keep track of your pet’s story and see what’s trending.
                            </p>
                        </div>
                        <div className="mb-4 text-start">
                            <h5 className="mb-2">Furry Friends</h5>
                            <p className="text-muted">
                                Easily find and connect with your friends on the app. Stay updated on their posts and enjoy making new friends with pets you’ll love.
                            </p>
                        </div>
                        <div className="mb-4 text-start">
                            <h5 className="mb-2">Photobook</h5>
                            <p className="text-muted">
                                Unlike the timeline, Photobook albums won’t appear on your friends’ feed. Create organized albums to store special pet memories for yourself or share them with others.
                            </p>
                        </div>
                        <div className="mb-4 text-start">
                            <h5 className="mb-2">Video Log</h5>
                            <p className="text-muted">
                                Save your favorite videos in personalized albums, just like photos. Videos here won’t show up in reels, making this a private or curated collection.
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default btn-sm" data-bs-dismiss="modal" onClick={onHide}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;
