import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData } from "../../../utils/axiosApiReq";
import { selectUserId } from "../user/userSlice";
import { toast } from "react-toastify";
import API from "../../../utils";
const { ENDPOINTS } = API;

export const fetchGroupsAndFriends = createAsyncThunk("chat/fetchGroupsAndFriends", async () => {
  const response = await postData(ENDPOINTS.chatGroupUserList);
  return response;
});

// Async function to fetch chat messages
export const getMessages = createAsyncThunk("chat/getMessages", async ({ receiverId, type, page = 1, per_page = 20, getMsgType }, { getState }) => {
  const state = getState();
  const senderId = selectUserId(state); // Get userId from userSlice
  if (!senderId) {
    throw new Error("User ID not available");
  }
  const response = await postData(ENDPOINTS.getChatMessages, { sender_id: senderId, receiver_id: receiverId, type, page, per_page });
  return {responsePayload : response.payload, page, getMsgType}; // Assuming the API returns a payload with the messages
});

export const sendMessage = createAsyncThunk("chat/sendMessage", async ({ message, receiver_id, type, files }, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const senderId = selectUserId(state); // Get userId from userSlice

    if (!senderId) {
      throw new Error("User ID not available");
    }

    const response = await postData(ENDPOINTS.sendMessage, { sender_id: senderId, receiver_id, message, type, files }, true);

    if (!response.status) {
      toast.error(response?.message);
      return rejectWithValue(response);
    }

    return response.payload;
  } catch (error) {
    toast.error(error.response?.data?.message || "An unexpected error occurred. Please try again.");
    return rejectWithValue(error.response?.data || { message: "An unexpected error occurred. Please try again.", code: 500 });
  }
});

export const getTotalMessage = createAsyncThunk("chat/getTotalMessage", async (_, { rejectWithValue }) => {
  try {
    const response = await getData(ENDPOINTS.getTotalMessage);
    if (!response.status) {
      rejectWithValue(response);
    }
    return response.payload;
  } catch (error) {
    return rejectWithValue(error.response?.data || { message: "An unexpected error occurred. Please try again.", code: 500 });
  }
});

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    groups: [],
    friends: [],
    selectedChat: null, // currently opened friend or group chat
    chatType: null,
    loading: false,
    error: null,
    // Get Messages
    messages: [], // Holds messages for the selected chat
    isNewMsg: false,
    loadingMessages: false, // Loading state for messages
    errorMessages: null, // Error state for messages
    currentPage:1,
    hasMore:true,
    // Send Message
    sendingMessage: false, // Loading state for sending message
    errorSendingMessage: null, // Error state for sending message
    sentMessage: null, // Holds the most recently sent message,
    // Get Total Messages
    unreadMsgCount: null,
  },
  reducers: {
    selectChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    resetSentMessage: (state) => {
      state.sentMessage = null; // Reset the sent message state
    },
    selectChatType: (state, action) => {
      state.chatType = action.payload;
    },
    resetChatMsg : (state,action)=>{
      state.messages = [];
      state.isNewMsg = false;
      state.currentPage=1;
      state.hasMore=true;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle groups and friends fetch
      .addCase(fetchGroupsAndFriends.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupsAndFriends.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.payload.group || [];
        state.friends = action.payload.payload.user || [];
      })
      .addCase(fetchGroupsAndFriends.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "An error occurred while fetching groups and friends.";
      })
      // Handle fetching messages
      .addCase(getMessages.pending, (state) => {
        state.isNewMsg = false;
        state.loadingMessages = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loadingMessages = false;
        // state.messages = action.payload.reverse() || [];
        // if(action.payload.length > state.messages.length){state.isNewMsg = true;}

        // const messages = action.payload.reverse();
        // const messages = [...action.payload]//.reverse();
        const {responsePayload: messages, page, getMsgType} = action.payload;
        const existingIds = new Set(state.messages.map((msg) => msg.id));
        const uniqueMessages = messages.filter((msg) => !existingIds.has(msg.id));

        if (page === 1) {
          if (uniqueMessages.length > 0) {
            // state.messages = uniqueMessages;
            state.messages = [...uniqueMessages, ...state.messages];
          }
          // if (uniqueMessages.length > 0)  state.messages = [...uniqueMessages, ...state.messages];
        } else {
            // state.messages = [...uniqueMessages, ...state.messages];
            state.messages = [...state.messages, ...uniqueMessages];
        }
        // if (state.currentPage === 1) {
        //   state.messages = messages;
        // } else {
        //   // Append older messages for subsequent pages
        //   // state.messages = [...messages, ...state.messages];
        //   const existingIds = new Set(state.messages.map((msg) => msg.id));
        //   const uniqueMessages = messages.filter((msg) => !existingIds.has(msg.id));
        //   state.messages = [...uniqueMessages, ...state.messages];
        // }


       
        if(getMsgType == "Old" && messages.length > 0){
          state.currentPage = page;
        }
          state.hasMore = messages.length > 0; 
          // console.log('currentPage:', state.currentPage, 'hasMore:', state.hasMore);
        // }
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isNewMsg = false;
        state.loadingMessages = false;
        state.errorMessages = action.payload?.message || "An error occurred while fetching messages.";
      })
      // Handle sending message
      .addCase(sendMessage.pending, (state) => {
        state.sendingMessage = true;
        state.errorSendingMessage = null; // Reset error when a new message is being sent
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendingMessage = false;
        state.sentMessage = action.payload; // Store the sent message
        state.messages.push(action.payload); // Add the sent message to the messages list
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.sendingMessage = false;
        state.errorSendingMessage = action.payload?.message || "Failed to send message.";
      })
      // Get Total Messages (Msg Unread Count)
      .addCase(getTotalMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTotalMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.unreadMsgCount = action?.payload?.total_message ?? null;
      })
      .addCase(getTotalMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Capture the error
      });
  },
});

export const { selectChat, selectChatType, resetSentMessage, resetChatMsg } = chatSlice.actions;

export default chatSlice.reducer;
