import axios from "axios";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import API from "../../../utils";
import { getData } from "../../../utils/axiosApiReq";
import { toast } from "react-toastify";

const GroupInvites = () => {
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchInvites = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.groupRequestList}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        if (response.data.status && response.data.payload) {
          const formattedInvites = response.data.payload.map((invite) => ({
            id: invite.id,
            groupName: invite.group.name,
            name: invite.user.name,
            status: invite.status || "pending", // Assuming status is included in the payload
          }));
          setInvites(formattedInvites);
        }
      } catch (error) {}
    };

    fetchInvites();
  }, []);

  const handleStatusChange = async (id, status) => {
    const token = localStorage.getItem("token");

    try {
      // Create a new FormData instance
      const formData = new FormData();
      formData.append("id", id); // Append the invite ID
      formData.append("status", status); // Append the status (1 for accept, 2 for reject)

      // Send FormData in the POST request
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.groupRequestStatus}`, // Endpoint for accepting/rejecting invites
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        }
      );

      if (response.data.status === true) {
        // Update status based on the action
        setInvites((prevInvites) => prevInvites.map((invite) => (invite.id === id ? { ...invite, status: status === 1 ? "approved" : "rejected" } : invite)));
      } else {
        console.error("Error changing invitation status:", response.data.message);
      }
    } catch (error) {}
  };

  const rejectGroupInvites = async (id) => {
    try {
      const response = await getData(`${API.ENDPOINTS.rejectGroupInvite}/${id}`);
      const { status, message } = response;
      if (status) {
        toast.success(message);
      } else {
        toast.error(message || "Error reject group invite.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="group-invites">
      <div className="title">Group Invities</div>
      <hr />

      <Table striped bordered hover tyle={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Group Name</th>
            <th style={{ width: "25%" }}>Name</th>
            <th style={{ width: "25%" }}>Status</th>
            <th style={{ width: "25%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invites.length > 0 ? (
            invites.map((invite) => (
              <tr key={invite.id}>
                <td>{invite.groupName}</td>
                <td>{invite.name}</td>
                <td>{invite.status || "Pending"}</td>
                <td>
                  {invite.status !== "approved" && (
                    <>
                      <button
                        className="btn btn-primary btn-sm m-0"
                        onClick={() => handleStatusChange(invite.id, 1)} // Accept (status: 1)
                      >
                        Accept
                      </button>{" "}
                    </>
                  )}

                  {invite.status !== "rejected" && (
                    <button
                      className="btn btn-default btn-sm m-0"
                      onClick={() => rejectGroupInvites(invite.id)} // Reject (status: 2)
                    >
                      Reject
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No invites available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default GroupInvites;
