import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Share from "../../../../assets/images/sidebar/share.png";
import Comment from "../../../../assets/images/sidebar/message-blank.png";
import Like from "../../../../assets/images/sidebar/heart_new.png";
import FilledHeart from "../../../../assets/images/filled_heart.svg";
import Menu from "../../../../assets/images/sidebar/dots.png";
import API from "../../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import EditCommentModal from "../../../dashboard/defaultCompo/postModals/editCommentModal";
import ReportModal from "../../../dashboard/defaultCompo/postModals/reportModal";
import ValidationModal from "../../../dashboard/defaultCompo/postModals/validationModal";
import ShareModal from "../../../dashboard/defaultCompo/postModals/shareModal";
import EditModal from "../../../dashboard/defaultCompo/postModals/editModal";
import ConfirmationModal from "../../../dashboard/defaultCompo/postModals/confirmationModal";
import VideosCarousel from "../../videoscarousel";
import Carousel from "../../carousel";
import defalutimages from "../../../../assets/images/user/user_default.jpg";
import moment from "moment";
import Loader from "../../Loader";
import NewsfeedRight from "../../../birthDayCoponent/newsfeedright";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../../../store/features/user/userSlice";
import { format } from "date-fns";

const PostDetails = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const { postId } = useParams();
  const dropdownRef = useRef();
  const commentRefs = useRef({});
  const videoContainerRefs = useRef([]);
  const [showComments, setShowComments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);
  const [editingCommentText, setEditingCommentText] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const userAvatar = useSelector(selectUserProfile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [postData, setPostData] = useState({
    id: null,
    posted_by: null,
    type: null,
    title: "",
    description: "",
    image_links: [],
    is_liked_by_user: null,
    like_comment_count: { like: 0, comment: 0 },
    posted_user: {
      id: null,
      name: "",
      email: "",
      cover_img: "",
      profile_img: "",
      pet_name: "",
    },
    priority: "",
    related_post_details: null,
    tag_friends: null,
    tagged_users: [],
    title: "",
    type: null,
    updated_at: "",
    video_links: [],
    created_at: "",
  });

  useEffect(() => {
    const fetchUserAvatar = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          // setUserAvatar(response.data.payload.profile_img);
        }
      } catch (error) {
        // console.error("Error fetching user avatar:", error);
      } finally {
        setLoading(false);
      }
    };
    // fetchUserAvatar();
  }, [token]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.singleposts}/${postId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status) {
        setPostData(response.data.payload);
        setIsLiked(response.data.payload.is_liked_by_user !== null);
        setLikes(response.data.payload.like_comment_count.like);
        setCommentCount(response.data.payload.like_comment_count.comment);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error("Error fetching owner data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, [postId, token]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.likesComments}?post_id=${postId}&type=comment`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setComments(response.data.payload);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [postId, token]);

  const handleCommentImageClick = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.likesComments}?post_id=${postId}&type=comment`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setComments(response.data.payload);
        setShowComments((prev) => !prev);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdowns({});
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDeletePost = async () => {
    try {
      const response = await axios.delete(
        `${API.BASE_URL}${API.ENDPOINTS.posts}/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        navigate("/home"); // Navigate to the /home route

        toast.success(response.data.message);
        handleCloseModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error("Error fetching owner data:", error);
    }
  };

  const handleCommentPost = async () => {
    if (!commentText.trim()) {
      setShowValidationModal(true);
      return;
    }

    const formData = new FormData();
    formData.append("post_id", postId);
    formData.append("type", "comment");
    formData.append("comment", commentText);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.likesComments}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setCommentText("");
        commentRefs.current[postId].textContent = "";
        setCommentCount((prevCommentCount) => prevCommentCount + 1);
        fetchComments();
      } else {
        toast.error(response.data.message || "Failed to post the comment.");
      }
    } catch (error) {
      // console.error("Error posting comment:", error);
      toast.error("Error posting comment. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachmentPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLikePost = async (e) => {
    e.preventDefault();
    try {
      if (isLiked) {
        const response = await axios.delete(
          `${API.BASE_URL}${API.ENDPOINTS.likesComments}/${postData.is_liked_by_user}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          setIsLiked(false);
          setLikes((prevLikes) => prevLikes - 1);
        } else {
          toast.error(response.data.message);
        }
      } else {
        const formData = new FormData();
        formData.append("post_id", postId);
        formData.append("type", "like");
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.likesComments}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          setIsLiked(true);
          setLikes((prevLikes) => prevLikes + 1);
          postData.is_liked_by_user = response?.data?.payload?.id;
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      // console.error("Error updating settings:", error);
      toast.error("Error updating settings.");
    }
  };

  const handleSharePost = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleEditPost = () => {
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditComment = (index) => {
    setEditingCommentIndex(index);
    setEditingCommentText(comments[index].text);
    setShowEditCommentModal(true);
  };

  const handleSaveCommentEdit = () => {
    const updatedComments = [...comments];
    updatedComments[editingCommentIndex].text = editingCommentText;
    setComments(updatedComments);
    setEditingCommentIndex(null);
    setEditingCommentText("");
    setShowEditCommentModal(false);
  };

  const handleShowReportModal = () => setShowReportModal(true);
  const handleCloseReportModal = () => setShowReportModal(false);
  const handleCloseEditCommentModal = () => setShowEditCommentModal(false);
  const handleCloseValidationModal = () => setShowValidationModal(false);

  const handleEmojiClick = (emoji) => {
    const commentBox = commentRefs.current[postId];
    if (commentBox) {
      const newText = commentBox.textContent + emoji;
      commentBox.textContent = newText;
      setCommentText(newText);
    }
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const videoContainer = entry.target;
            const videoPlayer = videoContainer.querySelector('video');
            if (videoPlayer) {
              videoPlayer.currentTime = 0;
              videoPlayer.play();
            }
          } else {
            const videoContainer = entry.target;
            const videoPlayer = videoContainer.querySelector('video');
            if (videoPlayer) {
              videoPlayer.pause();
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    videoContainerRefs.current.forEach((container) => {
      if (container) {
        observer.observe(container);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [postData.video_links]);

  const {
    title,
    description,
    image_links,
    video_links,
    posted_user,
    created_at,
    priority,
    updated_at,
    profile_img,
    related_post_details,
  } = postData;

  // Format the created_at field
  let formattedCreatedAt = "N/A"; // Default value if date is invalid
  if (created_at) {
    const parsedDate = new Date(created_at);
    if (!isNaN(parsedDate)) {
      formattedCreatedAt = format(parsedDate, "MM/dd/yyyy, hh:mm:ss a");
    }
  }

  let formattedserdCreatedAt = "N/A"; // Default value if date is invalid
  if (related_post_details && related_post_details.created_at) {
    const parsedDate = new Date(related_post_details.created_at);
    if (!isNaN(parsedDate)) {
      formattedserdCreatedAt = format(parsedDate, "MM/dd/yyyy, hh:mm:ss a");
    }
  }

  if (loading) {
    return <Loader />;
  }

  const formattedVideoLinks = (video_links || []).map((url) => ({
    type: "video",
    url: url,
  }));

  const relatedPostVideoLinks = (related_post_details?.video_links || []).map((url) => ({
    type: "video",
    url: url,
  }));

  const fromNowTime = (datetime) => {
    if (!datetime) return "";

    // Parse the date string in a consistent format
    const formattedDate = moment(datetime, "MM/DD/YYYY, hh:mm:ss A", true);

    // Validate if Moment successfully parsed the date
    if (!formattedDate.isValid()) {
      // console.warn("Invalid date format provided:", datetime);
      return "";
    }

    // Return the relative time
    return formattedDate.fromNow();
  };

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          {/* System Messages */}
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>

          {/* Newsfeed Layout */}
          <div className="ossn-layout-newsfeed d-lg-flex">
            <div className="newsfeed-container">
              <div className="newsfeed-middle">
                <div className="user-activity row">
                  <div
                    className="ossn-wall-item ossn-wall-item-476"
                    id={`activity-item-${postId}`}
                  >
                    <div className="row">
                      <div className="meta">
                        <img
                          className="user-icon-small user-img"
                          src={posted_user.profile_img || defalutimages}
                          alt="User Avatar"
                        />
                        <div className="post-menu">
                          {userId == posted_user.id && (
                            <div className="dropdown" ref={dropdownRef}>
                              <a
                                role="button"
                                className="btn btn-link p-0"
                                onClick={() => toggleDropdown(`post-${postId}`)}
                              >
                                <img src={Menu} alt="Menu" />
                              </a>
                              {openDropdowns[`post-${postId}`] && (
                                <ul
                                  className="dropdown-menu multi-level dropdown-menu-end"
                                  role="menu"
                                  style={{
                                    position: "absolute",
                                    inset: "0px 0px auto auto",
                                    margin: "0px",
                                    transform: "translate(0px, 42px)",
                                  }}
                                >
                                 {related_post_details === null && (
                                    <li>
                                      <a
                                        className="dropdown-item post-control-report"
                                        onClick={handleEditPost}
                                      >
                                        Edit
                                      </a>
                                    </li>
                                  )}
                                  <li>
                                    <a
                                      className="dropdown-item post-control-delete ossn-wall-post-delete"
                                      onClick={handleShowModal}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="user d-flex align-items-center">
                          <Link
                            to={`/user-profile-view/${posted_user.id}`}
                            className="ossn-output-user-url"
                          >
                            {posted_user.pet_name}
                          </Link>

                          <i
                            className="fa fa-check-circle verified-user verified-title"
                            title="Verified Profile"
                          ></i>
                          {related_post_details !== null && (
                            <div className="ossn-wall-item-type ms-1">Shared a post</div>
                          )}
                        </div>
                        <div className="post-meta">
                          <spen className="time-created ossn-wall-post-time">
                            {fromNowTime(formattedCreatedAt)}
                          </spen>
                          <span className="time-created" title={priority === 0 ? "Public" : "Friends"}>
                            - <img
                              width="20px"
                              height="20px"
                              src={priority === 0 ? "https://app.xpets.com/themes/xpets/images/public.png" : "https://app.xpets.com/themes/xpets/images/friends-gray.png"}
                              alt={priority === 0 ? "Public" : "Friends"}
                            />
                          </span>
                          {/* <span className="time-created" title="Public">
                            - <img width="20px" height="20px" src={priority === 0 ? "https://app.xpets.com/themes/xpets/images/friends-gray.png" : "https://app.xpets.com/themes/xpets/images/public.png"} alt={priority === 0 ? "Public" : "Friends"} />
                          </span> */}
                        </div>
                      </div>

                      {related_post_details === null && (
                        <div className="meta">
                          <div className="post-contents">
                            <p>{title}</p>
                          </div>
                          {Array.isArray(image_links) && image_links.length > 0 && (
                            <div className="post-images">
                              <Carousel images={image_links} />
                            </div>
                          )}
                          {Array.isArray(formattedVideoLinks) && formattedVideoLinks.length > 0 && (
                            <div className="post-videos">
                              <VideosCarousel videos={formattedVideoLinks} />
                            </div>
                          )}
                        </div>
                      )}

                      {related_post_details && (
                        <div className="post-share-wall-item">
                          <div className="ossn-shared-wrapper">
                            <div className="row">
                              <div className="meta">
                                <img
                                  className="user-icon-small user-img"
                                  src={related_post_details.posted_user?.profile_img || defalutimages}
                                  alt="User Avatar"
                                />
                                <div className="user">
                                  <Link
                                    to={`/user-profile-view/${related_post_details.posted_user?.id}`}
                                    className="ossn-output-user-url"
                                  >
                                    {related_post_details.posted_user?.name}{" "}
                                    <i
                                      className="fa fa-check-circle verified-user verified-title"
                                      title="Verified Profile"
                                    ></i>
                                  </Link>
                                </div>
                                <div className="post-meta">
                                  <spen className="time-created ossn-wall-post-time">
                                    {/* {fromNowTime(related_post_details.created_at)}
                                   */}
                                    {fromNowTime(formattedserdCreatedAt)}
                                  </spen>
                                  <span className="time-created" title={related_post_details.priority === 0 ? "Public" : "Friends"}>
                                    - <img
                                      width="20px"
                                      height="20px"
                                      src={related_post_details.priority === 0 ? "https://app.xpets.com/themes/xpets/images/public.png" : "https://app.xpets.com/themes/xpets/images/friends-gray.png"}
                                      alt={related_post_details.priority === 0 ? "Public" : "Friends"}
                                    />
                                  </span>
                                </div>
                                <div className="post-contents">
                                  <p>{related_post_details.title}</p>
                                  {Array.isArray(related_post_details.image_links) &&
                                    related_post_details.image_links.length > 0 && (
                                      <div className="post-images">
                                        <Carousel images={related_post_details.image_links} />
                                      </div>
                                    )}
                                  {Array.isArray(relatedPostVideoLinks) &&
                                    relatedPostVideoLinks.length > 0 && (
                                      <div className="post-images">
                                        <VideosCarousel videos={relatedPostVideoLinks} />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="comments-likes">
                        <div className="menu-likes-comments-share">
                          <li>
                            <a className="post-control-like" onClick={handleLikePost}>
                              <img src={isLiked ? FilledHeart : Like} alt="Like" />{" "}
                              <span>{likes}</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="post-control-comment comment-post"
                              onClick={handleCommentImageClick}
                            >
                              <img src={Comment} alt="Comment" />
                              <span>{commentCount}</span>
                            </a>
                          </li>
                          {related_post_details == null && (
                            <li>
                              <a
                                className="post-control-shareobject share-object-select"
                                onClick={handleSharePost}
                              >
                                <img src={Share} alt="Share" />
                              </a>
                            </li>
                          )}
                        </div>
                      </div>

                      {showComments && (
                        <div className="comments-list">
                          {comments.map((comment, index) => (
                            <div className="comments-item" key={index}>
                              <div className="row">
                                <div className="col-md-1">
                                  <img
                                    className="comment-user-img"
                                    src={comment.commented_user.profile_img || defalutimages}
                                    alt="User Avatar"
                                  />
                                </div>
                                <div className="col-md-10">
                                  <div className="comment-contents">
                                    <p>
                                      <Link
                                        to={`/user-profile-view/${comment.commented_user.postedId}`}
                                        className="ossn-output-user-url owner-link"
                                      >
                                        {comment.commented_user.pet_name}
                                      </Link>

                                    </p>
                                    <div className="comment-metadata">
                                      {/* <div className="time-created">
                                        {fromNowTime(comment?.created_at)}
                                      </div> */}
                                      {/* <a className="ossn-like-comment">Like it</a> */}
                                      <span className="comment-text">{comment.comment}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  {/* <div className="ossn-comment-menu">
                                    <div className="dropdown" ref={dropdownRef}>
                                      <a
                                        role="button"
                                        onClick={() => toggleDropdown(`comment-${index}`)}
                                        className="btn btn-link p-0"
                                      >
                                        <img src={Menu} alt="Menu" />
                                      </a>
                                      {openDropdowns[`comment-${index}`] && (
                                        <ul
                                          className="dropdown-menu multi-level dropdown-menu-end"
                                          role="menu"
                                          style={{
                                            position: "absolute",
                                            inset: "0px 0px auto auto",
                                            margin: "0px",
                                            transform: "translate(0px, 42px)",
                                          }}
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item post-control-report ossn-report-this"
                                              onClick={handleShowReportModal}
                                            >
                                              Report this
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item post-control-delete ossn-wall-post-delete"
                                              onClick={handleShowModal}
                                            >
                                              Delete
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item post-control-edit"
                                              onClick={() => handleEditComment(index)}
                                            >
                                              Edit
                                            </a>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="comments-item">
                        <div className="row">
                          <div className="col-md-1">
                            <img
                              className="comment-user-img"
                              src={userAvatar || defalutimages}
                              alt="User Avatar"
                            />
                          </div>
                          <div className="col-md-11">
                            <div
                              className="ossn-comment-attach-photo"
                              onClick={() => toggleSection("emojiSelector")}
                            >
                              <i className="fa fa-smile"></i>
                            </div>
                            <span
                              id={`comment-box-${postId}`}
                              className="comment-box"
                              contentEditable
                              onInput={(e) => setCommentText(e.currentTarget.textContent)}
                              placeholder="Leave a comment..."
                              suppressContentEditableWarning
                              ref={(el) => (commentRefs.current[postId] = el)}
                            ></span>
                            <button
                              className="btn btn-primary btn-sm comment-post-btn"
                              onClick={handleCommentPost}
                            >
                              Post
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      id="file-input"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {attachmentPreview && (
                      <div className="attachment-preview">
                        <img
                          src={attachmentPreview}
                          alt="Attachment Preview"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                    {activeSection === "emojiSelector" && (
                      <div id="ossn-wall-emoji">
                        <div className="emoji-list">
                          {[
                            "😊",
                            "😂",
                            "😍",
                            "😎",
                            "😢",
                            "😅",
                            "🤔",
                            "😜",
                            "😇",
                            "😉",
                            "😱",
                            "🤗",
                            "🤩",
                            "🥳",
                            "🤐",
                            "😋",
                            "🤪",
                            "🥺",
                            "😌",
                            "🤧",
                            "😤",
                            "😞",
                            "🙄",
                            "😏",
                            "😜",
                            "🥰",
                            "😶",
                            "😯",
                            "😴",
                            "😜",
                            "👻",
                            "💀",
                            "🎃",
                            "🐱",
                            "🐶",
                            "🐵",
                            "🐮",
                            "🦁",
                            "🐯",
                            "🐷",
                            "🐮",
                            "🐸",
                            "🐣",
                            "🐦",
                            "🐍",
                            "🐛",
                            "🦋",
                            "🐜",
                            "🦄",
                            "🦑",
                            "🐙",
                            "🦞",
                            "🦐",
                            "🐡",
                            "🐠",
                            "🐟",
                            "🦓",
                            "🦒",
                            "🦘",
                            "🐪",
                            "🐫",
                            "🐘",
                            "🦏",
                            "🐗",
                            "🐖",
                            "🦃",
                            "🦢",
                            "🦦",
                            "🦨",
                            "🦩",
                            "🦡",
                            "🐆",
                            "🐅",
                            "🐈",
                            "🐇",
                            "🦔",
                            "🐉",
                            "🐍",
                            "🦅",
                            "🦆",
                            "🦢",
                            "🐦",
                            "🦋",
                            "🐜",
                            "🦇",
                            "🐕",
                            "🐩",
                            "🐈‍⬛",
                            "🐦",
                            "🐧",
                            "🐓",
                            "🐢",
                            "🦎",
                            "🐉",
                            "🦖",
                            "🦦",
                            "🦩",
                            "🦨",
                            "🦦",
                            "🐁",
                            "🐀",
                            "🐇",
                            "🐿",
                            "🦔",
                            "🐾",
                            "🦨",
                            "🐚",
                            "🦻",
                            "🦶",
                            "🐁",
                            "🐿",
                            "🐇",
                            "🐊",
                            "🦦",
                            "🦧",
                            "🐒",
                            "🦉",
                            "🦋",
                            "🐜",
                            "🦁",
                            "🐅",
                            "🦄",
                            "🦥",
                            "🦩",
                          ].map((emoji, index) => (
                            <span
                              key={index}
                              onClick={() => handleEmojiClick(emoji)}
                              style={{ fontSize: "24px", cursor: "pointer", margin: "5px" }}
                            >
                              {emoji}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    <ConfirmationModal
                      showModal={showModal}
                      handleClose={handleCloseModal}
                      handleConfirm={handleDeletePost}
                    />
                    <EditModal
                      showModal={showEditModal}
                      handleClose={handleCloseEditModal}
                      postId={postId}
                      title={title}
                      fetchData={fetchData}
                    />
                    <ShareModal
                      showModal={showShareModal}
                      handleClose={handleCloseShareModal}
                      postId={postId}
                    />

                    <ValidationModal
                      showModal={showValidationModal}
                      handleClose={handleCloseValidationModal}
                      postId={postId}
                    />
                    <ReportModal
                      showModal={showReportModal}
                      handleClose={handleCloseReportModal}
                      postId={postId}
                    />
                    <EditCommentModal
                      showModal={showEditCommentModal}
                      handleClose={handleCloseEditCommentModal}
                      commentText={editingCommentText}
                      setCommentText={setEditingCommentText}
                      handleSave={handleSaveCommentEdit}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PostDetails;