import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import { ChatContext } from "./ChatContext";
import { FaTrashCan, FaEllipsisVertical } from "react-icons/fa6";
import { RxPaperPlane } from "react-icons/rx"; //
import { FaPaperclip, FaSmile, FaBell, FaTrash } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";
import DeleteModal from "./DeleteModal";
import paperClip from "../../assets/images/themes/paper-clip.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import errImg from "../../assets/images/themes/fozzy.jpg";
import searchIconImg from "../../assets/images/themes/search.png";
import Loader from "../common/Loader";
import { selectUserProfile } from "../../store/features/user/userSlice";
import InfiniteScroll from "react-infinite-scroll-component";
var images = require.context("../../assets/images", true);

const ChatUI = () => {
  const { chatType, loading, error, sendingMessage, errorSendingMessage, loadingMessages, isNewMsg, hasMore } = useSelector((state) => state.chat);
  const userAvatar = useSelector(selectUserProfile);

  const { userInfo } = useSelector((state) => state.user);
  const [petErrImg, setPetErrImg] = useState(errImg);
  const {
    selectedUserChat,
    setSelectedUserChat,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    openDeleteModal,
    closeDeleteModal,
    message,
    setMessage,
    selectedFiles,
    setSelectedFiles,
    showEmojiPicker,
    setShowEmojiPicker,
    fileInputRef,
    activeTab,
    setActiveTab,
    searchTerm,
    setSearchTerm,

    handleMessageChange,
    handleEmojiSelect,
    handleFileSelect,
    removeFile,
    handleSubmit,

    //redux
    handleSelectChat,
    groups,
    friends,
    filteredFriends,
    filteredGroups,
    selectedChat,
    messages,
    fetchMoreMessages,

    messageContainerRef,
    prevSelectedUserChatRef,
  } = useContext(ChatContext);

  // Create a ref for the message container
  // const messageContainerRef = useRef(null);

  // useEffect(() => {
  //   // if (messageContainerRef.current) {messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;}
  //   if (messageContainerRef.current && prevSelectedUserChatRef.current !== null) {
  //     messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  //   }
  //   // if (isNewMsg) {
  //   //   messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  //   // }
  // }, [messages, isNewMsg]);

  // const widgetContent = (profile_img = "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg", name = "", lastMessage = "", unread_messages_count = 0) => (
  //   <div className="widget-contents" style={{ marginTop: 0 }}>
  //     <div id="get-recent" style={{ display: "none" }}></div>
  //     <div className="messages-from">
  //       <div className="inner">
  //         <div
  //           data-guid="277"
  //           className="ossn-recent-message-item d-flex flex-row user-item ossn-recent-message-status-offline"
  //           onClick={() => console.log("Redirecting to messages/message/fozzy")}
  //         >
  //           <div className="msg-flex-c1">
  //             {profile_img == "groupTrue" ? (
  //               <div className="icon" style={{ height: '40px', width: '40px', borderRadius: '8px', textAlign: 'center', verticalAlign: 'middle', background: '#cbe6fb', lineHeight: '38px' }}>
  //                 <span>{name?.charAt(0).toUpperCase()}</span>
  //               </div>
  //             ) : (
  //               <img
  //                 className="image user-icon-smaller"
  //                 src={profile_img || "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg"}
  //                 alt="User Avatar"
  //               />
  //             )}
  //             {/* <span className="ossn-inmessage-status-circle"></span> */}
  //           </div>
  //           <div className="msg-flex-c2 d-flex justify-content-between data">
  //             <div className="">
  //               <div className="name">{name}</div>
  //               <div className="reply-text">
  //                 {lastMessage.length > 20 ? `${lastMessage.slice(0, 20)}...` : lastMessage}
  //               </div>
  //             </div>
  //             {unread_messages_count > 0 &&
  //               (<div className="d-flex align-items-center">
  //                 <span className="ossn-notification-container-massges">{unread_messages_count}</span>
  //               </div>)}

  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const widgetContent = (
    profile_img = "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg",
    name = "",
    lastMessage = "",
    unread_messages_count = 0
  ) => (
    <div className="widget-contents" style={{ marginTop: 0 }}>
      <div id="get-recent" style={{ display: "none" }}></div>
      <div className="messages-from">
        <div className="inner">
          <div
            data-guid="277"
            className="ossn-recent-message-item d-flex flex-row user-item ossn-recent-message-status-offline"
            // onClick={() => console.log("Redirecting to messages/message/fozzy")}
          >
            <div className="msg-flex-c1">
              {profile_img == "groupTrue" ? (
                <div
                  className="icon"
                  style={{ height: "40px", width: "40px", borderRadius: "8px", textAlign: "center", verticalAlign: "middle", background: "#cbe6fb", lineHeight: "38px" }}
                >
                  <span>{name?.charAt(0).toUpperCase()}</span>
                </div>
              ) : (
                <img
                  className="image user-icon-smaller"
                  src={profile_img || "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg"}
                  alt="User Avatar"
                  loading="eager"
                />
              )}
              {/* <span className="ossn-inmessage-status-circle"></span> */}
            </div>
            <div className="msg-flex-c2 d-flex justify-content-between data">
              <div className="">
                <div className="name">{name}</div>
                <div className="reply-text">{lastMessage ? (lastMessage.length > 20 ? `${lastMessage.slice(0, 20)}...` : lastMessage) : ""}</div>
              </div>
              {unread_messages_count > 0 && (
                <div className="d-flex align-items-center">
                  <span className="ossn-notification-container-massges">{unread_messages_count}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const videoExtensions = ["mp4", "mkv", "avi", "mov"];
  const documentExtensions = ["pdf", "doc", "docx", "ppt", "txt"];

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mainDiv" id="messages">
        <div className="container-fluid">
          <div className="ossn-layout-contents">
            <div className="ossn-system-messages">
              <div className="row">
                <div className="col-md-11">
                  <div className="ossn-system-messages-inner"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="col-md-12">
                <div className="ossn-messages">
                  <div className="row g-0">
                    <div className="col-md-4" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                      <div className="search-users-messages-container">
                        <div className="search-users-messages-section">
                          <img src={searchIconImg} />
                          <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search friends" className="form-control" />
                          <div id="message-user-search-results" className="dropdown-menu"></div>
                        </div>
                      </div>
                      <div className="ossn-widget messages-recent">
                        {/* <div className="widget-heading">
                          Inbox (0)
                          <span className="d-inline d-sm-none ossn-recent-messages-toggle">
                            <i className="fas fa-angle-down"></i>
                          </span>
                        </div> */}

                        {/* Tab Navigation */}
                        <div className="button-group">
                          <button className={activeTab === "friends" ? "group-button active" : "group-button"} onClick={() => setActiveTab("friends")}>
                            Friends
                          </button>
                          <button className={activeTab === "groups" ? "group-button active" : "group-button"} onClick={() => setActiveTab("groups")}>
                            Groups
                          </button>
                        </div>

                        {/* Tab Content */}
                        <div className="tab-content">
                          {activeTab === "friends" && (
                            <div className="friends-tab friends-tab-fixhight">
                              {filteredFriends && filteredFriends.length > 0 ? (
                                filteredFriends.map((friend) => (
                                  <Link to={`/messages/${friend?.id}`} state={{ friend }} key={friend.id}>
                                    {widgetContent(friend?.profile_img, friend?.details?.pet_name, friend?.last_message, friend?.unread_messages_count)}
                                  </Link>
                                ))
                              ) : (
                                <h6 className="text-center mt-4">No chats currently found</h6>
                              )}
                            </div>
                          )}
                          {activeTab === "groups" && (
                            <div className="groups-tab groups-tab-fixhight">
                              {filteredGroups && filteredGroups.length > 0 ? (
                                filteredGroups.map((group) => (
                                  <Link to={`/messages/${group?.id}`} state={{ group }} key={group.id}>
                                    {widgetContent(group?.cover_image || "groupTrue", group?.name, group?.last_message, group?.unread_messages_count)}
                                  </Link>
                                ))
                              ) : (
                                <>
                                  <h6 className="text-center mt-4">No group chats currently found</h6>
                                  <div className="text-center">
                                    <Link to={"/groups"}>
                                      <button className="btn btn-primary">Create Group</button>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      {!selectedUserChat ? (
                        <div className="h-100 d-flex align-items-center justify-content-center ossn-messages-select-conv">
                          <i className="fa fa-envelope"></i>
                        </div>
                      ) : (
                        <>
                          <div className="ossn-widget messages-with ossn-inmessage-status-offline" id="message-with-user-widget" data-guid="277">
                            <div className="widget-heading">
                              <img
                                src={chatType === 1 ? selectedChat?.profile_img || petErrImg : chatType === 2 ? selectedChat?.cover_image || petErrImg : petErrImg}
                                className="user-icon-smaller"
                                style={{ width: "32px", height: "32px" }}
                              />
                              {/* <span className="ossn-inmessage-status-circle" style={{ marginLeft: "18px" }}></span> */}
                              <span className="headingname">{chatType === 1 ? selectedChat?.details?.pet_name : chatType === 2 ? selectedChat?.name : `${chatType}`} </span>
                              {/* <a data-guid="277" className="ossn-message-delete-conversation" href="javascript:void(0);">
                                <FaTrashCan />
                              </a> */}
                            </div>
                            <div className="widget-contents">
                              <div className="message-with">
                                <div
                                  className="message-inner"
                                  id="scrollableDiv"
                                  ref={messageContainerRef}
                                  data-guid="277"
                                  style={{
                                    // minHeight: "100%",
                                    height: "100%", //400,
                                    overflowY: "auto",
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  }}
                                >
                                  <InfiniteScroll
                                    dataLength={messages.length} // Total loaded messages
                                    next={fetchMoreMessages}
                                    hasMore={hasMore} // Whether more data is available
                                    inverse={true} // Enable scroll-up loading
                                    // loader={
                                    //   <h6 className="text-center">
                                    //     <Spinner animation="border" role="status" className="content-loader">
                                    //       <span className="visually-hidden">Loading...</span>
                                    //     </Spinner>
                                    //   </h6>
                                    // }
                                    scrollableTarget="scrollableDiv"
                                    style={{ display: "flex", flexDirection: "column-reverse", overflow: "hidden", height: "100%" }}
                                  >
                                    {messages && messages.length > 0 ? (
                                      messages.map((msg) => (
                                        <div className="row" id={`message-item-${msg.id}`} key={msg.id}>
                                          {/* Check if the message is sent by the current user */}
                                          {Number(msg.sender_id) === userInfo?.id ? (
                                            <div className={`col-md-12 pull-right`}>
                                              <div className="message-row">
                                                <div className={`message-box-sent d-flex ${msg.is_read === "0" ? "unread" : ""}`}>
                                                  {msg.file &&
                                                    msg.file.length > 0 &&
                                                    msg.file.map((fileUrl, index) => {
                                                      const fileExtension = fileUrl.split(".").pop().toLowerCase();

                                                      const isImage = imageExtensions.includes(fileExtension);
                                                      const isVideo = videoExtensions.includes(fileExtension);
                                                      const isDocument = documentExtensions.includes(fileExtension);

                                                      return (
                                                        <div key={index} className="file-preview">
                                                          {/* Display image */}
                                                          {isImage && (
                                                            <div>
                                                              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                  className="mx-1"
                                                                  src={fileUrl}
                                                                  alt={`file-${index}`}
                                                                  style={{ maxWidth: "200px", maxHeight: "200px", cursor: "pointer" }}
                                                                />
                                                              </a>
                                                            </div>
                                                          )}

                                                          {/* Display video */}
                                                          {isVideo && (
                                                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                              <video controls style={{ maxWidth: "300px", cursor: "pointer" }}>
                                                                <source src={fileUrl} type={`video/${fileExtension}`} />
                                                                Your browser does not support the video tag.
                                                              </video>
                                                            </a>
                                                          )}

                                                          {/* Display document link */}
                                                          {isDocument && (
                                                            <a href={fileUrl} target="_blank" download className="file-link">
                                                              Download {fileExtension.toUpperCase()} file
                                                            </a>
                                                          )}

                                                          {/* If file type is unknown, show a generic download link */}
                                                          {!isImage && !isVideo && !isDocument && (
                                                            <a href={fileUrl} target="_blank" download className="file-link">
                                                              Download file
                                                            </a>
                                                          )}
                                                        </div>
                                                      );
                                                    })}
                                                  {msg.message && <span>{msg.message || "Message was deleted"}</span>}

                                                  {/* <a className="ossn-message-delete" onClick={() => openDeleteModal(msg.id)}>
                                                  <FaEllipsisVertical style={{ color: "white" }} />
                                                </a> */}
                                                </div>
                                                <img
                                                  src={userAvatar || userInfo?.profile_img || petErrImg}
                                                  alt={`${msg?.sender?.name}'s profile`}
                                                  className="message-sender-avatar"
                                                  loading="eager"
                                                />
                                              </div>
                                              <div className="time-created">
                                                {new Date(msg.created_at).toLocaleTimeString("en-US", {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            /* Message is received by the current user */
                                            <div className="col-md-12 pull-left">
                                              <div className="message-row">
                                                <img
                                                  src={chatType === 1 ? selectedChat?.profile_img || petErrImg : chatType === 2 ? msg?.sender?.profile_img || petErrImg : petErrImg}
                                                  alt={`${msg?.sender?.name}'s profile`}
                                                  className="message-sender-avatar"
                                                  loading="eager"
                                                />
                                                {/* <img src={selectedChat?.profile_img || petErrImg} alt={`${msg?.sender?.name}'s profile`} className="message-sender-avatar" /> */}
                                                <div className="d-flex flex-wrap message-box-recieved text">
                                                  {msg.file &&
                                                    msg.file.length > 0 &&
                                                    msg.file.map((fileUrl, index) => {
                                                      // Extract the file extension to handle different file types
                                                      const fileExtension = fileUrl.split(".").pop().toLowerCase();

                                                      // Determine the content type based on the file extension
                                                      const isImage = imageExtensions.includes(fileExtension);
                                                      const isVideo = videoExtensions.includes(fileExtension);
                                                      const isDocument = documentExtensions.includes(fileExtension);

                                                      return (
                                                        <div key={index} className="file-preview ">
                                                          {/* Display image */}
                                                          {isImage && (
                                                            <a target="_blank" href={fileUrl} rel="noopener noreferrer">
                                                              <img className="m-1 " src={fileUrl} alt={`file-${index}`} style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                                            </a>
                                                          )}
                                                          {/* Display video */}
                                                          {isVideo && (
                                                            <>
                                                              <a target="_blank" href={fileUrl} rel="noopener noreferrer">
                                                                <video controls style={{ maxWidth: "300px" }}>
                                                                  <source src={fileUrl} type={`video/${fileExtension}`} />
                                                                  Your browser does not support the video tag.
                                                                </video>
                                                              </a>
                                                            </>
                                                          )}

                                                          {/* Display document link */}
                                                          {isDocument && (
                                                            <a href={fileUrl} target="_blank" download className="file-link" style={{ color: "#000" }}>
                                                              Download {fileExtension.toUpperCase()} file
                                                            </a>
                                                          )}

                                                          {/* If file type is unknown, show a generic download link */}
                                                          {!isImage && !isVideo && !isDocument && (
                                                            <a href={fileUrl} target="_blank" download className="file-link">
                                                              Download file
                                                            </a>
                                                          )}
                                                        </div>
                                                      );
                                                    })}
                                                  {msg.message && <span>{msg.message || "Message was deleted"}</span>}
                                                  {/* <a
                                                  className="ossn-message-delete"
                                                  data-id={msg.id}
                                                  data-href={`https://app.xpets.com/action/message/delete?id=${msg.id}`}
                                                  onClick={() => openDeleteModal(msg.id)}
                                                >
                                                  <FaEllipsisVertical style={{ color: "white" }} />
                                                </a> */}
                                                </div>
                                              </div>
                                              <div className="time-created">
                                                {new Date(msg.created_at).toLocaleTimeString("en-US", {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    ) : (
                                      <div className="h-100 d-flex align-items-center justify-content-center ossn-messages-select-conv">
                                        <i className="fa fa-envelope"></i>
                                      </div>
                                    )}
                                  </InfiniteScroll>
                                </div>
                              </div>

                              {/* Emoji Picker */}
                              <div style={{ position: "relative" }}>
                                {showEmojiPicker && (
                                  <div className="emoji-picker-container">
                                    <EmojiPicker onEmojiClick={handleEmojiSelect} />
                                  </div>
                                )}

                                <form className="ossn-form message-form-form" onSubmit={handleSubmit}>
                                  <fieldset>
                                    <div className="message-container">
                                      <div className="messagesend-box">
                                        <textarea name="message" placeholder="Type your message. . ." value={message} onChange={handleMessageChange}></textarea>
                                        <div className="ossn-message-icon-attachment m-0" onClick={() => fileInputRef.current.click()}>
                                          <img src={paperClip} />
                                        </div>
                                      </div>

                                      {/* File Input (Hidden) */}
                                      <input type="file" ref={fileInputRef} className="d-none" accept="image/*,video/*" multiple onChange={handleFileSelect} />
                                      {/* Submit Button */}
                                      <div className="submit-container">
                                        {sendingMessage ? (
                                          <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                          </Spinner>
                                        ) : (
                                          <button type="submit" className="btn-send-message-btn"></button>
                                        )}
                                      </div>
                                    </div>

                                    {/* Controls */}
                                    <div className="controls">
                                      <div className="ossn-message-attach-photo" onClick={() => setShowEmojiPicker((prev) => !prev)}>
                                        {/* <FaSmile /> */}
                                        <i className="fa fa-smile"></i>
                                      </div>
                                      {/* <div className="ossn-message-pling">
                                        {/* <FaBell /> *}
                                        <i className="fa fa-bell"></i>
                                      </div> */}
                                    </div>

                                    {/* List selected files */}
                                    {selectedFiles.length > 0 && (
                                      <div className="file-list">
                                        {selectedFiles.map((file, index) => (
                                          <div key={index} className="file-item">
                                            {file.name}
                                            <button type="button" className="remove-file btn btn-light mt-0" onClick={() => removeFile(index)}>
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </fieldset>
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <audio id="ossn-chat-sound" src="https://app.xpets.com/components/OssnMessages/sound/pling.mp3" preload="auto"></audio>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      {isDeleteModalOpen && <DeleteModal closeDeleteModal={closeDeleteModal} />}
    </>
  );
};
export default ChatUI;
