import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    const token = localStorage.getItem("token");
    // setLoading(true);
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}?is_new=${1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setNotifications(response.data.data);
      } else {
        toast.error(response.data.message || "Failed to fetch notifications.");
      }
    } catch (error) {
      // console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  // Mark a notification as viewed
  const markNotificationAsViewed = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}/${id}/mark-viewed`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === id
              ? { ...notification, is_view: 1 }
              : notification
          )
        );
        return true;
      } else {
        toast.error(response.data.message || "Failed to mark notification.");
        return false;
      }
    } catch (error) {
      console.error("Error marking notification as viewed:", error);
      toast.error("Error marking notification. Please try again.");
      return false;
    }
  };

  // Initial fetch
  useEffect(() => {
    // fetchNotifications();
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        loading,
        fetchNotifications,
        markNotificationAsViewed,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
