import React from 'react';

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
    if (!showModal) return null;
    return (
        <>
            <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
                <div className="modal-content w-50">
                    <div className="title">
                        Are you sure you want to delete this post?
                        <div className="close" onClick={handleClose}>X</div>
                    </div>
                    <div className="modal-buttons">
                        <button className="btn btn-primary btn-sm" onClick={handleConfirm}>Yes</button>
                        <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationModal;
