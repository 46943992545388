import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import API from "../../utils";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../store/features/user/userSlice";
import Loader from "../common/Loader";
import defalutimages from "../../assets/images/user/user_default.jpg";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";

const StoryUploader = ({ addStory, openStoryViewer, profileImg, postedUser, hasStory }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");

  const fileInputRef = useRef();

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    const validTypes = ["image/jpeg", "image/png", "video/mp4", "video/mov"];
    const maxSize = 15 * 1024 * 1024; // 15MB
    if (!selectedFile) {
      setValidationMsg("Please select a file.");
      return;
    }
    if (!validTypes.includes(selectedFile.type)) {
      setValidationMsg("Invalid file type. Please select a JPEG, PNG, MP4, or MOV file.");
      return;
    }
    if (selectedFile.size > maxSize) {
      setValidationMsg("File size exceeds 15MB limit.");
      return;
    }
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
    setValidationMsg("");
  };

  const handleUploadSubmit = async () => {
    const validTypes = ["image/jpeg", "image/png", "video/mp4", "video/mov"];
    const maxSize = 15 * 1024 * 1024; // 15MB

    if (!file) {
      setValidationMsg("Please select a file.");
      return;
    }

    if (!validTypes.includes(file.type)) {
      setValidationMsg("Invalid file type. Please select a JPEG, PNG, MP4, or MOV file.");
      return;
    }

    if (file.size > maxSize) {
      setValidationMsg("File size exceeds 15MB limit.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("title", "story");
    formData.append("content_type", 2);
    formData.append("type", 2);
    formData.append("files[0]", file);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.posts}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === true) {
        const newStory = {
          id: response.data.payload.id,
          title: response.data.payload.title,
          imageLinks: response.data.payload.image_links || [],
          videoLinks: response.data.payload.video_links || [],
          postedBy: response.data.payload.posted_user,
          createdAt: response.data.payload.created_at,
        };

        addStory(newStory);
        setModalOpen(false);
        setTitle("");
        setFile(null);
        setImagePreview("");
        fileInputRef.current.value = "";
        window.location.reload();
      }
    } catch (error) {
      console.error("Error uploading story:", error);
      toast.error("Error uploading story, please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="story-uploader flex justify-center items-center">
      <div className={`category-inner-circle flex justify-center items-center ${!hasStory ? 'border-4 border-white' : ''} `}>
        <img src={profileImg || defalutimages} alt="User Avatar" className="category-image" onClick={openStoryViewer} />
      </div>

      {postedUser?.id && (
        <div className="icon-wrapper" onClick={() => setModalOpen(true)}>
          <i className="icofont icofont-ui-add"></i>
        </div>
      )}

      {modalOpen && (
        <>
          <div className="ossn-halt ossn-light" style={{ height: "10357px", position: "fixed", display: "block" }}></div>
          <div className="ossn-message-box" style={{ display: "block" }}>
            <div className="title justify-content-between d-flex">
              Add Story
              <div className="close-box" onClick={() => setModalOpen(false)}>X</div>
            </div>
            <hr />
            <div className="contents">
              <div className="ossn-box-inner">
                <form className="ossn-form">
                  <fieldset>
                    <input
                      type="file"
                      className="mb-2"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      accept="image/*,video/*"
                    />
                    {validationMsg && <div className="validation-msg text-start text-danger">{validationMsg}</div>}
                    {imagePreview && (
                      <div className="image-preview-container d-flex">
                        <div className="image-preview ms-2">
                          <img src={imagePreview} style={{ width: "100px", height: "100px", objectFit: "cover" }} alt="Preview" />
                        </div>
                      </div>
                    )}
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="control text-end">
              <button className="btn btn-primary btn-sm m-0 text-end" onClick={handleUploadSubmit} disabled={loading}>
                {loading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CustomStoryViewer = ({ stories, onClose, userId, fetchStoriesAndProfile }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [likes, setLikes] = useState({});
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState("");
  const [commentInputVisible, setCommentInputVisible] = useState(null);
  const [likedStories, setLikedStories] = useState({});
  const [progress, setProgress] = useState(0);
  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    const initialLikes = {};
    const initialComments = {};
    const initialLikedStories = {};
    stories.forEach((story) => {
      initialLikes[story.id] = story.likes || 0;
      initialComments[story.id] = story.comments || [];
      initialLikedStories[story.id] = story.is_liked_by_user || false;
    });
    setLikes(initialLikes);
    setComments(initialComments);
    setLikedStories(initialLikedStories);
  }, [stories]);

  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
    setProgress(0);
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const totalTime = 5000; // 5 seconds
      const newProgress = (elapsedTime / totalTime) * 100;
      setProgress(newProgress);
    }, 100);

    const timer = setTimeout(() => {
      handleNextStory();
    }, 5000); // Switch to the next story after 5 seconds

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [currentStoryIndex, startTime]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex(currentStoryIndex + 1);
      setProgress(0);
    } else {
      onClose();
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex(currentStoryIndex - 1);
      setProgress(0);
    }
  };

  const likeReel = async (storyId) => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.likescomments}`,
        { post_id: storyId, type: "like" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status) {
        fetchStoriesAndProfile();
      }
    } catch (error) {
      // console.error("Error liking the reel", error);
    }
  };

  const unlikeReel = async (is_liked_by_user) => {
    try {
      const response = await axios.delete(`${API.BASE_URL}${API.ENDPOINTS.likescomments}/${is_liked_by_user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status) {
        fetchStoriesAndProfile();
      }
    } catch (error) {
      // console.error("Error unliking the reel", error);
    }
  };

  const handleSendMessage = async (story) => {
    if (!newComment.trim()) return;

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("message", newComment);
    formData.append("sender_id", localStorage.getItem("user_id"));
    formData.append("receiver_id", userId); // Story uploader's ID

    try {
      await axios.post(`${API.BASE_URL}${API.ENDPOINTS.sendMessage}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setComments((prev) => ({
        ...prev,
        [story.id]: [...prev[story.id], { text: newComment, id: Date.now() }],
      }));
      setNewComment("");
      setCommentInputVisible(null); // Close the comment input field
    } catch (error) {
      // console.error("Error adding comment:", error);
    }
  };

  const currentStory = stories[currentStoryIndex];
  // console.log('currentStory',currentStory);

  return (
    <div className="ossn-main-model mobileStoryModal">
      <div className="custom-story-viewer" style={{ height: "100vh" }}>
        <div className="story-header">
          <ProgressBar
            now={progress}
            style={{
              height: '5px',
              width: '100%',
              backgroundColor: 'rgb(255 255 255 / 38%)',
            }}
          />
        </div>
        <div className="story-details">
          <Link to={`/user-profile-view/${currentStory.posted_by}`} className="d-flex" rel="noopener noreferrer" >
            <img src={currentStory.postedBy.profile_img || defalutimages} alt={`${currentStory.postedBy.name}'s profile`} className="story-profile-img" />
            <div className="story-info">
              <h4 className="story-username">{currentStory.postedBy.pet_name}</h4>
              <p
                style={{
                  fontSize: '15px',
                }}
              >
                {new Date(currentStory.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                })}
              </p>

            </div>
          </Link>
        </div>
        <button onClick={onClose} className="close-button m-0">
          &times;
        </button>

        <div className="story-content">
          {currentStory.videoLinks && currentStory.videoLinks.length > 0 ? (
            <video src={currentStory.videoLinks[0]} autoPlay />
          ) : (
            <img src={currentStory.imageLinks[0]} alt="Story" />
          )}
        </div>
        {currentStory.postedBy.id !== parseInt(uid) && (
          <div className="story-actions">
            <div className="likes-comments-container text-end">
              <span
                className="like-button"
                onClick={() =>
                  currentStory.is_liked_by_user
                    ? unlikeReel(currentStory.is_liked_by_user)
                    : likeReel(currentStory.id)
                }
              >
                <svg
                  width="48"
                  height="40"
                  viewBox="0 0 24 24"
                  fill={currentStory.is_liked_by_user ? "red" : "white"}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                </svg>
              </span>
              <span
                className="comment-button"
                onClick={() => setCommentInputVisible((prev) => (prev === currentStory.id ? null : currentStory.id))}
              >
                <svg
                  width="48"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2zm0 14H4V4h16v12z" />
                </svg>
              </span>
            </div>
            {commentInputVisible === currentStory.id && (
              <div className="comment-input-container">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Write a comment..."
                  className="form-control"
                />
                <button
                  className="btn  m-0 position-absolute"
                  onClick={() => handleSendMessage(currentStory)}
                  disabled={!newComment.trim()}
                >
                  <FiSend />
                </button>
              </div>
            )}
          </div>
        )}
        <div className="story-navigation">
          <button onClick={handlePreviousStory} disabled={currentStoryIndex === 0}>

          </button>
          <button onClick={handleNextStory} disabled={currentStoryIndex === stories.length - 1}>

          </button>
        </div>
      </div>
    </div>
  );
};

const StoryViewer = ({ stories, onClose, userId, fetchStoriesAndProfile }) => {
  const uid = localStorage.getItem("user_id"); // Logged-in user ID

  return (
    <>
      <div
        className="ossn-halt ossn-light"
        style={{
          height: "100%",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClose}
      ></div>
      <div className="ossn-message-box-sorty" >
        <CustomStoryViewer
          stories={stories}
          onClose={onClose}
          userId={userId}
          fetchStoriesAndProfile={fetchStoriesAndProfile}
        />
      </div>
    </>
  );
};

const StoryApp = () => {
  const [storiesByUser, setStoriesByUser] = useState({});
  const [isViewerOpen, setViewerOpen] = useState(null);
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id"); // Logged-in user ID
  const profileImg = useSelector(selectUserProfile);
  const postedUser = useSelector((state) => state?.user?.userInfo);
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetchStoriesAndProfile();
  }, [token]);

  const fetchStoriesAndProfile = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.posts}?type=${2}&force_story=${1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const stories = response.data.payload || [];

      const groupedStories = stories.reduce((acc, story) => {
        const userId = story.posted_user.id;
        if (!acc[userId]) {
          acc[userId] = {
            user: story.posted_user,
            stories: [],
          };
        }
        acc[userId].stories.push({
          posted_by: story.posted_user.id,
          id: story.id,
          title: story.title,
          imageLinks: story.image_links || [],
          videoLinks: story.video_links || [],
          createdAt: story.created_at,
          postedBy: story.posted_user,
          is_liked_by_user: story.is_liked_by_user || false,
        });
        return acc;
      }, {});

      setStoriesByUser(groupedStories);
    } catch (error) {
      // console.error("Error fetching stories or profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStoryUploaded = (newStory) => {
    const userId = newStory.postedBy.id;
    setStoriesByUser((prev) => ({
      ...prev,
      [userId]: {
        user: newStory.postedBy,
        stories: [newStory, ...(prev[userId]?.stories || [])],
      },
    }));
  };

  const openStoryViewer = (userId) => {
    const userIdExists = Object.keys(storiesByUser).includes(String(userId));
    if (userIdExists) {
      setViewerOpen(userId);
    }
  };

  const handleCloseViewer = () => {
    setViewerOpen(null);
  };

  return (
    <div className="mainDiv p-3">
      {loading ? (
        <Loader />
      ) : (
        <div className="story-section d-flex">
          <StoryUploader
            profileImg={profileImg}
            addStory={handleStoryUploaded}
            openStoryViewer={() => openStoryViewer(postedUser?.id)}
            postedUser={postedUser}
            hasStory={Object.keys(storiesByUser).includes(String(postedUser?.id))}
          />

          <div className="all-users-stories d-flex">
            {Object.entries(storiesByUser)
              .filter(([userId]) => parseInt(userId) !== parseInt(uid))
              .map(([userId, { user, stories }]) => (
                <div key={userId} className="user-stories">
                  <div
                    className="category-outer-circle"
                    onClick={() => openStoryViewer(parseInt(userId))}
                  >
                    <div className={`category-inner-circle`}>
                      <img
                        src={user.profile_img || defalutimages}
                        alt={`${user.name}'s profile`}
                        className="category-image"
                      />
                    </div>
                  </div>
                  {isViewerOpen === parseInt(userId) && (
                    <StoryViewer stories={stories} onClose={handleCloseViewer} userId={userId} fetchStoriesAndProfile={fetchStoriesAndProfile} />
                  )}
                </div>
              )
              )}
          </div>

          {isViewerOpen === parseInt(uid) && (
            <StoryViewer
              fetchStoriesAndProfile={fetchStoriesAndProfile}
              stories={storiesByUser[uid]?.stories || []}
              onClose={handleCloseViewer}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StoryApp;
