import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../../utils";
import { Modal, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import play from "../../../assets/images/sidebar/play.png";
import Edit from "../../../assets/images/sidebar/pen-big.png";
import Delete from "../../../assets/images/sidebar/trash.png";
import Add from "../../../assets/images/sidebar/plus-cross.png";
import { Spinner } from "react-bootstrap";

const PhotoAlbumView = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const { albumId } = useParams();
  const [album, setAlbum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false); // Edit Album Modal state
  const [showAddImageModal, setShowAddImageModal] = useState(false); // Add Image Modal state
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete Album Modal state
  const [albumName, setAlbumName] = useState("");
  const [privacy, setPrivacy] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      fetchAlbum();
    },
    [BASE_URL, ENDPOINTS, albumId]
  );

  const fetchAlbum = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS.singlealbum}/${albumId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.data.status) {
        setAlbum(response.data.payload);
        setAlbumName(response.data.payload.album_name);
        setPrivacy(response.data.payload.status);
      } else {
        toast.error("Failed to fetch album details.");
      }
    } catch (error) {
      //   toast.error("Error fetching album details.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditAlbum = async () => {
    if (!albumName.trim()) {
      setErrors({ albumName: "Album name is required." });
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("album_name", albumName);
      formData.append("status", privacy);
      formData.append("album_id", albumId);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}${ENDPOINTS.updatealbum}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (response.data.status) {
        toast.success("Album updated successfully");
        setAlbum(response.data.payload);
        setShowEditModal(false);
      } else {
        toast.error("Failed to update album.");
      }
    } catch (error) {
      toast.error("Error updating album.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddImage = async () => {
    if (!selectedImage) {
      toast.error("Please select an image to upload.");
      return;
    }

    try {
      setIsLoading(true); // Set loading to true when starting the upload

      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("album_id", albumId);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}${ENDPOINTS.addphoto}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (response.data.status) {
        toast.success("Image uploaded successfully.");
        setShowAddImageModal(false);
        fetchAlbum();
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      toast.error("Error uploading image.");
    } finally {
      setIsLoading(false); // Set loading to false when upload is complete
    }
  };

  const handleDeleteAlbum = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS.deletealbum}/${albumId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status) {
        toast.success("Album deleted successfully.");
        setShowDeleteModal(false); // Close the Delete Modal after success
        navigate("/users/userEdit?tab=photos"); // Navigate to the desired page
      } else {
        toast.error("Failed to delete album.");
      }
    } catch (error) {
      toast.error("Error deleting album.");
    }
  };

  const openDeleteModal = e => {
    e.preventDefault(); // Prevent the default anchor action (navigating)
    setShowDeleteModal(true); // Open the Delete Confirmation Modal
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!album) {
    return <div>Album not found.</div>;
  }

  const handleImageClick = photoId => {
    navigate(`/photos-details/${albumId}/${photoId}`);
  };

  return (
    <div className="mainDiv">
      <div className="container-fluid">
        <div className="ossn-layout-contents">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner" />
              </div>
            </div>
          </div>
          <div>
            <div className="ossn-layout-module">
              <div className="module-title">
                <div className="title">Photos</div>
                <div className="controls">
                  <a
                    className="button-grey"
                    id="ossn-photos-show-gallery"
                    href="javascript:void(0);"
                    onClick={() => setShowGalleryModal(true)}
                  >
                    <img src={play} alt="Play" />
                  </a>
                  <a
                    className="button-grey"
                    id="ossn-photos-edit-album"
                    onClick={() => setShowEditModal(true)} // Open Edit Modal
                  >
                    <img src={Edit} alt="Edit" />
                  </a>
                  <a
                    id="ossn-add-photos"
                    data-url="?album=3120"
                    className="button-grey"
                    href="javascript:void(0);"
                    onClick={() => setShowAddImageModal(true)}
                  >
                    <img src={Add} alt="Add" />
                  </a>
                  {/* Modified Delete button to trigger modal */}
                  <a
                    className="button-grey ossn-make-sure"
                    href="javascript:void(0);" // Prevent default navigation
                    onClick={openDeleteModal} // Open the delete modal
                  >
                    <img src={Delete} alt="Delete" />
                  </a>
                  <a
                    className="button-grey photos-back"
                    href="/users/userEdit?tab=photos"
                  >
                    Back
                  </a>
                </div>
              </div>
              <div className="module-contents">
                <div className="ossn-photos">
                  <h2>
                    {album.album_name}
                  </h2>
                  <div className="album-photo-list">
                    {album.photos && album.photos.length > 0
                      ? album.photos.map(photo =>
                        <li key={photo.id} className="album-photo-item">
                          <a
                            
                            onClick={e => {
                              e.preventDefault(); // Prevent default link behavior
                              handleImageClick(photo.id); // Call handleImageClick with the photo id
                            }}
                          >
                            <img
                              src={photo.image}
                              alt={`Photo ${photo.id}`}
                              className="pthumb"
                            />
                          </a>
                        </li>
                      )
                      : <p>No photos available in this album.</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Album Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <div className="title d-flex justify-content-between">
          Edit Album
          <div
            className="close-box"
            style={{ cursor: "pointer" }}
            onClick={() => setShowEditModal(false)}
          >
            X
          </div>
        </div>
        <hr />
        <form>
          <div className="mb-3">
            <label className="d-flex">Album Name</label>
            <input
              type="text"
              className="form-control"
              value={albumName}
              onChange={e => setAlbumName(e.target.value)}
            />
            {errors.albumName &&
              <small className="text-danger d-flex">
                {errors.albumName}
              </small>}
          </div>
          <div className="ossn-privacy d-flex">
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 0
                ? "selected"
                : ""}`}
              onClick={() => setPrivacy(0)}
            >
              Public
            </button>
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 1
                ? "selected"
                : ""}`}
              onClick={() => setPrivacy(1)}
            >
              Friends
            </button>
          </div>
          <hr />
          <div className="control d-flex justify-content-end">
            <div className="controls">
              <button
                type="button"
                className="button-grey"
                onClick={handleEditAlbum}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Add Image Modal */}
      <Modal
        show={showAddImageModal}
        onHide={() => setShowAddImageModal(false)}
      >
        <div className="title d-flex justify-content-between">
          Add Image
          <div
            className="close-box"
            style={{ cursor: "pointer" }}
            onClick={() => setShowAddImageModal(false)}
          >
            X
          </div>
        </div>
        <hr />
        <form>
          <div className="mb-3">
            <label className="d-flex">Choose Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={e => setSelectedImage(e.target.files[0])}
            // multiple
            />
          </div>
          <hr />
          <div className="control d-flex justify-content-end">
            <div className="controls">
              <button
                type="button"
                className="button-grey"
                onClick={handleAddImage}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading
                  ? <Spinner animation="border" size="sm" /> // Show loading spinner
                  : "Upload" // Default button text
                }
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <div className="title d-flex justify-content-between">
          Edit Album
          <div
            className="close-box"
            style={{ cursor: "pointer" }}
            onClick={() => setShowEditModal(false)}
          >
            X
          </div>
        </div>
        <hr />
        <form>
          <div className="mb-3">
            <label className="d-flex">Album Name</label>
            <input
              type="text"
              className="form-control"
              value={albumName}
              onChange={e => setAlbumName(e.target.value)}
            />
            {errors.albumName &&
              <small className="text-danger d-flex">
                {errors.albumName}
              </small>}
          </div>
          <div className="ossn-privacy d-flex">
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 0
                ? "selected"
                : ""}`}
              onClick={() => setPrivacy(0)}
            >
              Public
            </button>
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 1
                ? "selected"
                : ""}`}
              onClick={() => setPrivacy(1)}
            >
              Friends
            </button>
          </div>
          <hr />
          <div className="control d-flex justify-content-end">
            <div className="controls">
              <button
                type="button"
                className="button-grey"
                onClick={handleEditAlbum}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Gallery Modal */}
      <Modal
        show={showGalleryModal}
        onHide={() => setShowGalleryModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {album.album_name} - Gallery
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {album.photos && album.photos.length > 0
            ? <Carousel
              activeIndex={currentPhotoIndex}
              onSelect={index => setCurrentPhotoIndex(index)}
            >
              {album.photos.map(photo =>
                <Carousel.Item key={photo.id}>
                  <img
                    className="d-block w-100"
                    src={photo.image}
                    alt={`Photo ${photo.id}`}
                  />
                </Carousel.Item>
              )}
            </Carousel>
            : <div className="text-center">
              <p>No photos available in this album.</p>
            </div>}
        </Modal.Body>
      </Modal>

      {/* Delete Album Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <div className="title d-flex justify-content-between">
          Are you sure you want to delete this album?
          <div
            className="close-box"
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteModal(false)}
          >
            X
          </div>
        </div>
        <div className="control d-flex justify-content-end">
          <div className="controls">
            <button
              type="button"
              className="btn btn-default  ms-2"
              onClick={() => setShowDeleteModal(false)} // Close modal on cancel
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleDeleteAlbum}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PhotoAlbumView;
