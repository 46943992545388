import React, { useEffect, useState } from "react";
import API from "../../utils";
import axios from "axios";

const Footer = props => {
    const [editFormData, setEditFormData] = useState({
        name: '',
        email: '',
        Notification: '',
        NotificationEmail: '',
        Language: '',
        copyright: '',
        Reporting:'',
    });    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const nameParam = 'basic'; // Set the 'name' parameter value

        try {
            const response = await axios.get(
                `${API.BASE_URL}${API.ENDPOINTS.siteSettings}?name=${nameParam}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            if (response.data.status && response.data.payload.length > 0) {
                const settings = response.data.payload[0];

               
                const settingsValue = JSON.parse(settings.value);

                
                setEditFormData({
               
                    name: settingsValue.name,
                email: settingsValue.email,
                Notification: settingsValue.Notification,
                NotificationEmail: settingsValue.NotificationEmail,
                Language: settingsValue.Language,
                copyright: settingsValue.copyright,
                Reporting: settingsValue.Reporting,
                });
            } else {
                // console.error('No settings found in the response payload.');
            }
        } catch (error) {
            // console.error('Error fetching user data:', error);
        }
    };
    useEffect(() => {
        fetchUserData();
    }, []);


    return (
        <footer>
            <div className="footer-contents">
                <div className="ossn-footer-menu">
                    <a className="menu-footer-a_copyrights" >
                       {editFormData.copyright}
                    </a>
                    <a className="menu-footer-about" href="https://www.xpets.com/the-pup-post">
                        About
                    </a>
                    <a className="menu-footer-site" href="https://xpets.com/terms-and-conditions">
                        Terms and Conditions
                    </a>
                    <a className="menu-footer-privacy" href="https://xpets.com/privacy-policy">
                        Privacy
                    </a>
                </div>
            </div>
        </footer>
    )
};

export default Footer;