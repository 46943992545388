import React, { useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";

const AddGroup = () => {
    const token = localStorage.getItem("token");
    const [groupName, setGroupName] = useState("");
    const [description, setDescription] = useState("");
    const [privacy, setPrivacy] = useState("OSSN_PUBLIC");

    const handlePrivacyChange = (optionValue) => {
        console.log(optionValue)
        setPrivacy(optionValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!groupName.trim() || !description.trim()) {
            toast.error("Please fill out all fields.");
            return;
        }

        const formData = new FormData();
        formData.append("name", groupName);
        formData.append("details", description);
        formData.append("group_type", privacy === "OSSN_PUBLIC" ? 1 : 2);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.createEditGroup}`,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.status) {
                toast.success("Group created successfully.");
                // Clear form
                setGroupName("");
                setDescription("");
                setPrivacy("OSSN_PUBLIC");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Error updating settings.");
        }
    };

    return (
        <div className="ossn-message-boxs">
            <div className="title">Add Group</div>
            <hr />
            <div className="contents">
                <div className="ossn-box-inner">
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <div className="row">
                                <label style={{ marginTop: "20px" }}>Group Name</label>
                                <input
                                    type="text"
                                    name="groupname"
                                    className="form-control"
                                    style={{ width: "100%" }}
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                />
                            </div>
                            <div className="row">
                                <label style={{ marginTop: "20px" }}>Description</label>
                                <input
                                    type="text"
                                    name="description"
                                    className="form-control"
                                    style={{ width: "100%" }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div className="group-add-privacy" style={{ marginTop: "20px" }}>
                                <div className="ossn-privacy-options">
                                    <button
                                        type="button"
                                        onClick={() => handlePrivacyChange("OSSN_PUBLIC")}
                                        className={`privacy-option-btn ${privacy === "OSSN_PUBLIC" ? "privacy-option-btn-selected" : ""}`}
                                    >
                                        Public
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handlePrivacyChange("OSSN_FRIENDS")}
                                        className={`privacy-option-btn ${privacy === "OSSN_FRIENDS" ? "privacy-option-btn-selected" : ""}`}
                                    >
                                        Friends
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                        <div className="control">
                            <div className="controls">
                                <button className="btn btn-primary btn-sm m-0" type="submit">
                                    Save
                                </button>
                                <a className="btn btn-default btn-sm ms-2" href="#">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddGroup;
