import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const Reportedcontent = () => {
  // Sample data for reports (can be fetched from an API)
  const [reports, setReports] = useState([
    {
      id: 1081,
      type: "post",
      reason: "Inappropriate content",
      reportedBy: "Chinnu M V",
      reportedByUrl: "https://app.xpets.com/u/chinnumvijayan",
      time: "2 days ago",
      viewUrl: "/report/view",
      markReadUrl: "https://app.xpets.com/action/report/read?guid=1081",
      deleteUrl: "https://app.xpets.com/action/report/delete?guid=1081",
      status: "unread",
    },
    // Add more reports as needed
  ]);

  // Mark a report as read
  const markAsRead = (id) => {
    setReports((prevReports) =>
      prevReports.map((report) =>
        report.id === id ? { ...report, status: "read" } : report
      )
    );
    const report = reports.find((report) => report.id === id);
    if (report) {
      toast.success(`Marked as read: ${report.reason}`);
    }
  };
  
  const deleteReport = (id) => {
    const report = reports.find((report) => report.id === id);
    setReports((prevReports) => prevReports.filter((report) => report.id !== id));
    if (report) {
      toast.success(`Deleted: ${report.reason}`);
    }
  };
  

  return (
    <div className="mainDiv">
      <div className="main-content-container">
        <div className="ossn-system-messages">
          <div className="row">
            <div className="col-md-11">
              <div className="ossn-system-messages-inner"></div>
            </div>
          </div>
        </div>
        <div className="ossn-layout-newsfeed d-lg-flex">
          <div className="newsfeed-container">
            <div className="newsfeed-middle">
              <div className="report-items ">
                {reports.map((report) => (
                  <div className="row mb-4 " key={report.id}>
                    <div className="col-md-4 col-sm-12 report-item-read-bg">
                      <div
                        className={`report-item ${
                          report.status === "read" ? "report-item-read" : ""
                        }`}
                      >
                        <p>
                          <strong>Type:</strong>
                          <span> {report.type}</span>
                        </p>
                        <p>
                          <strong>Reason:</strong>
                          <span> {report.reason}</span>
                        </p>
                        <p>
                          <strong>Reported By:</strong>
                          <span>
                            <a href={report.reportedByUrl}>{report.reportedBy}</a>
                          </span>
                        </p>
                        <p>
                          <strong>Time:</strong>
                          <span> {report.time}</span>
                        </p>
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={report.viewUrl}
                            className="btn btn-primary"
                          >
                            View Content
                          </a>
                          <button
                            className="btn btn-warning ms-2 mt-0"
                            onClick={() => markAsRead(report.id)}
                          >
                            Mark Read
                          </button>
                          <button
                            className="btn btn-danger ms-2 mt-0"
                            onClick={() => deleteReport(report.id)}
                          >
                            Delete
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {reports.length === 0 && (
                  <p className="text-center">No reported content found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reportedcontent;
