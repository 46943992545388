import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../../utils/axiosApiReq";
import API from "../../../utils";
import petErrImg from "../../../assets/images/themes/fozzy.jpg";
import { toast } from "react-toastify";
const { ENDPOINTS } = API;

// Helper to get user data from localStorage
// const getUserFromLocalStorage = () => {
//   const user = localStorage.getItem("user");
//   return user ? JSON.parse(user) : null;
// };

// export const getSoicalAuthKey = createAsyncThunk("getSoicalAuthKey", async (name = "", { rejectWithValue }) => {
//   try {
//     if(!name) toast.error('Select a valid social auth key');
//     const response = await getData(ENDPOINTS.sitesettings, { name });
//     if (!response.status) {
//       toast.error(response?.message);
//       return rejectWithValue(response);
//     }
//     return { name, payload: response.payload};
//   } catch (error) {
//     return rejectWithValue(error.response?.data || { message: "Failed to fetch user data", code: 500 });
//   }
// });

// Async thunk to fetch user data
export const fetchUser = createAsyncThunk("user/fetchUser", async (_, { getState, rejectWithValue }) => {
  try {
    // console.log('redux user api')
    const state = getState().user;

    // Avoid API call if userInfo already exists
    if (state.userInfo) {
      return state.userInfo;
    }

    const response = await getData(ENDPOINTS.profile);
    if (!response.status) {
      toast.error(response?.message);
      return rejectWithValue(response);
    }

    localStorage.setItem("user", JSON.stringify(response.payload)); // Store in localStorage
    return response.payload;
  } catch (error) {
    return rejectWithValue(error.response?.data || { message: "Failed to fetch user data", code: 500 });
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    // userInfo: getUserFromLocalStorage(),
    userInfo: null,
    googleAuthKey: null,
    facebookAuthKey: null,
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.userInfo = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      // .addCase(getSoicalAuthKey.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(getSoicalAuthKey.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // state.socialAuthKey = action.payload;
      //   const { name, payload } = action.payload;

      //   if (name === "google") {
      //     state.googleAuthKey = payload?.OAuth;
      //   } else if (name === "facebook") {
      //     state.facebookAuthKey = payload?.FFMPEG;
      //   }

      // })
      // .addCase(getSoicalAuthKey.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // });
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUserId = (state) => state.user.userInfo?.id;

export default userSlice.reducer;

// Get user details (nested user_details)
export const selectUserDetails = (state) => state?.user?.userInfo?.user_details;
export const selectUserProfile = (state) => state?.user?.userInfo?.profile_img || petErrImg;
