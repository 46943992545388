const API = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}`,

  ENDPOINTS: {
    // login
    // sitesettings: "site-settings",
    sitesettings: "site-settings-without-auth",
    socialauth: "socialauth",
    login: "login",
    register: "register",
    forgotpassword: "forgotpassword",
    changePassword: 'profile/changePassword',

    // POSTS
    posts: "posts",
    trendingTags: 'trending-tags',

    //Post likes-comments
    likesComments: "likes-comments",

    // PROFILE -> VIDEO LOG
    getvideolog: "video-log",
    addVideoLog: "video-log",

    // Chat
    chatGroupUserList: "group-user-list",
    getChatMessages: "get-message",
    sendMessage: "send-message",
    getTotalMessage: "get-total-message",

    // PROFILE -> EVENT
    getevent: "events",
    addevent: "events",

    // videolog:'getAllOwnPost',
    posts: "posts",
    singleposts: 'posts',
    likescomments: "likes-comments",

    getAllPostUserwise: "getAllPostUserwise",

    //user profile

    profile: "profile",
    voiceclips: 'voice-clips',
    voiceclips:"voice-clips",
    profileUpdate: "profile/update",
    inviteFriends: "refers",
    postsUpdate: "posts/update",

    // album
    createalbum: "create-album",
    albumlist: "album-list",
    singlealbum: "single-album",
    updatealbum: "update-album",
    addphoto: "add-photo",
    deletealbum: "delete-album",
    removephoto: "remove-photo",
    postsUpdate: "posts/update",

    //groups
    createEditGroup: "create-edit-group",
    groupList: "group-list",
    deleteGroup: "delete-group",
    sentJoinRequest: "sent-join-request",
    groupDetail: "group-detail",
    groupRequestList: "group-request-list",
    groupRequestStatus: "accept-reject-request",
    rejectGroupInvite: 'remove-member-group',

    // Friends
    relations: "relations",
    friendRequests: "friendRequests",
    followRequests: "relations/update",
    friendSuggestions: "friendSuggestions",
    relationsUpdate: "relations/update",
    relationsunfollow: "relations/update",
    removeSuggestion: "remove-suggestion",
    friendSuggestionsfred: "friends/search",
    upcomingBirthdays: 'upcoming-birthdays',

    //notifications
    notifications: "notifications",
    unreadNotificationCount: "unreadNotificationCount",
    markallviewed: 'markallviewed',
    eventdetails: 'events',
    events_intrest_update: "events-intrest-update",
    edit_event: 'edit-event',
    delete_events: 'events',
    blockFriends: "blockFriends",


    //site-settng
    siteSettings: "site-settings",

    postbytags:"post-by-tags",

    // Supplements 
    supplements : 'supplements',

    // voice-clips
    voiceclips : 'voice-clips',
  },
};

export default API;

export const isSubscribedAccountant = () => {
  const isSubscribed = localStorage.getItem("isSubscriptionValid");
  if (isSubscribed === "true") {
    return true;
  } else {
    return false;
  }
};
