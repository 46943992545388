import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import configDB from "../data/customizer/config";
import PrivateRoute from "./private-route";
import AppLayout from "../components/app";
import Login from "../pages/login";
import Signup from "../pages/signup";
import ForgetPwd from "../pages/forgetPwd";
import Error404 from "../pages/errors/error404";
import { routes } from "./layouts-routes";
import { Loader } from "react-feather";
import UserEdit from "../components/users/userEdit";

const MainRoutes = ({setGoogleOauth}) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    const color = localStorage.getItem("color");
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    if (layout) {
      document.body.classList.add(layout);
    }
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    // document.getElementById("color").setAttribute("href", `/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>

      {/* node --max-old-space-size=4096 node_modules/react-scripts/scripts/build.js */}
      {/* "homepage": "https://fableadtechnolabs.com/endless_admin", */}
      {/* basename={'/endless_admin'} */}

      <BrowserRouter future={{ v7_startTransition: true, v7_fetcherPersist: true, v7_relativeSplatPath: true }}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              {routes.map(({ path, Component }, i) => (
                <Route element={<AppLayout />} key={i}>
                  <Route exact path={path} element={Component} />
                </Route>
              ))}
            </Route>
            <Route path="/login" element={<Login setGoogleOauth={setGoogleOauth}/>} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/resetlogin" element={<ForgetPwd />} />
            <Route path="/404" element={<><Error404 /></>} />
            <Route path="*" element={<><Navigate to="/404" /></>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;