import axios from "axios";

// Create a reusable Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //   timeout: 10000, // Optional: set timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
  },
});

// Add token from localStorage to headers
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Get the token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Add Authorization header
  } else {
    // Token not found: clear localStorage and navigate to login
    localStorage.clear();
    window.location.href = "/login"; // Redirect to login page
    throw new axios.Cancel("Token not found, navigating to login"); // Prevent the request
  }
  return config;
});

/**
 * Reusable GET request function
 * @param {string} endpoint - API endpoint
 * @param {object} params - Query parameters (optional)
 * @param {object} config - Additional Axios config options (optional)
 * @returns {Promise} - Returns the API response or error
 */
export const getData = async (endpoint, params = {}, config = {}) => {
  try {
    const response = await apiClient.get(endpoint, { params, ...config });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Reusable POST request function
 * @param {string} endpoint - API endpoint
 * @param {object} data - Request body
 * @param {object} config - Additional Axios config options (optional)
 * @returns {Promise} - Returns the API response or error
 */
export const postData = async (endpoint, data = {}, isMultipart = false, config = {}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the Authorization header
      },
    };

    // If the data needs to be multipart (contains file uploads), set the Content-Type to multipart/form-data
    if (isMultipart) {
      config.headers["Content-Type"] = "multipart/form-data"; // Important for file uploads
    } else {
      config.headers["Content-Type"] = "application/json"; // Default for regular JSON requests
    }

    const response = await apiClient.post(endpoint, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Reusable PUT request function
 * @param {string} endpoint - API endpoint
 * @param {object} data - Request body
 * @param {object} config - Additional Axios config options (optional)
 * @returns {Promise} - Returns the API response or error
 */
export const putData = async (endpoint, data = {}, config = {}) => {
  try {
    const response = await apiClient.put(endpoint, data, config);
    return response.data;
  } catch (error) {
        throw error;
  }
};

/**
 * Reusable PATCH request function
 * @param {string} endpoint - API endpoint
 * @param {object} data - Request body
 * @param {object} config - Additional Axios config options (optional)
 * @returns {Promise} - Returns the API response or error
 */
export const patchData = async (endpoint, data = {}, config = {}) => {
  try {
    const response = await apiClient.patch(endpoint, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Reusable DELETE request function
 * @param {string} endpoint - API endpoint
 * @param {object} config - Additional Axios config options (optional)
 * @returns {Promise} - Returns the API response or error
 */
export const deleteData = async (endpoint, config = {}) => {
  try {
    const response = await apiClient.delete(endpoint, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// EXAMPALES
/*const result = await getData('/users', { limit: 10 });
const response = await postData('/users', {name: 'John Doe',email: 'johndoe@example.com',});
const response = await putData(`/users/${id}`, {name: 'John Doe',email: 'johndoe@example.com',});
const response = await patchData(`/users/${id}`, {name: 'John Doe',email: 'johndoe@example.com',});
const response = await deleteData(`/users/${id}`);*/
