import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // For making the API call
import Friendsimg from "../../../assets/images/sidebar/friends.png";
import API from "../../../utils";
import messageFil from "../../../assets/images/message-filled.png";
import blockImg from "../../../assets/images/block.png";
import dotsImg from "../../../assets/images/dots.png";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import defalutimages from "../../../assets/images/user/user_default.jpg";

const UserFriends = () => {
  const [friendsData, setFriendsData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null); // Tracks the open dropdown's ID
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { uprofileid } = useParams();

  // Fetch friends data from the API
  const fetchFriendsData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.relations}`, {
        params: { user_id: uprofileid },

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status) {
        setFriendsData(response.data.payload);
      }
    } catch (error) {
    }
  };

  // Block friend API call
  const handleBlockFriend = async (id) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", 3);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.relationsUpdate}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
      }
    } catch (error) {
    }
  };

  // Remove friend API call
  const handleRemoveFriend = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${API.BASE_URL}${API.ENDPOINTS.relations}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };

  // Handle dropdown toggle
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  // Handle navigation to the friends list
  const handleFiendsList = () => {
    navigate("/friends");
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null); // Close any open dropdown
    }
  };

  useEffect(() => {
    fetchFriendsData(); // Fetch data when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const getSetSearchTerm = (e) => setSearchTerm(e.target.value);

  const filteredFriends = useMemo(() => {
    return friendsData.filter((friend) => {
      let friendDetails = null;

      // Check if `uprofileid` matches `sender.id` or `receiver.id` and set `friendDetails` to the opposite user
      if (friend.sender.id === parseInt(uprofileid, 10)) {
        friendDetails = friend.receiver;
      } else if (friend.receiver.id === parseInt(uprofileid, 10)) {
        friendDetails = friend.sender;
      }

      // Ensure we have valid `friendDetails` and it matches the search term
      const isMatch = friendDetails?.details?.pet_name?.toLowerCase().includes(searchTerm.toLowerCase());
      return friendDetails && isMatch;
    });
  }, [friendsData, searchTerm, uprofileid]);


  return (
    <Fragment>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-body">
                  <div className="frinds-div d-flex justify-content-between">
                    <div className="title">Friends</div>
                    <div className="d-flex align-items-center">
                      <div className="search-form freind">
                        <div className="input-group">
                          <span className="input-group-text bg-white" id="basic-addon1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                          </span>
                          <input
                            type="text"
                            name="searchfriends"
                            value={searchTerm}
                            onChange={getSetSearchTerm}
                            className="form-control ps-2 border-start-0"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="friends-list-container" id="friends-list-container">
                    { filteredFriends.length > 0 ? (filteredFriends.map((friend) => {
                      const friendDetails =
                        friend.sender.id === parseInt(uprofileid, 10)
                          ? friend.receiver
                          : friend.sender;

                      return (
                        <div className="ossn-users-list-item" key={friend.id}>
                          <a href={`/user-profile-view/${friendDetails.id}`}>
                            <div className="user-item-container">
                              <img
                                className="img-responsive"
                                src={friendDetails?.profile_img || defalutimages}
                                width="100"
                                height="100"
                                alt="User Avatar"
                              />
                              <div className="uinfo">
                                <span className="ossn-output-user-url userlink">
                                  {friendDetails?.details?.pet_name || "Unknown"}
                                </span>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })) : (<h6 className="no-friends-message">No friends found.</h6>)}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserFriends;
