import React from "react";
import Default from "../components/dashboard/default";

// cards
// users
import UserProfile from "../components/users/userProfile";
import UserEdit from "../components/users/userEdit";
import UserCards from "../components/users/user-cards";
import Chat from "../components/messages/Chat";
import InviteFriendsindex from "../components/Invitefriends";
import Packchatindex from "../components/packchat";
import Groupviewindex from "../components/packchat/view/groupview";
import Reels from "../components/reels";
import VideoDetails from "../components/videoDetails/videoDetails";
import EventDetails from "../components/eventsDetails/eventsDetails";
import PhotosDetails from "../components/photosDetails/photoDetails";
import Reportedcontent from "../components/common/header-component/reported";
import Reportviewindex from "../components/common/header-component/reported/reportviewindex";
import PhotoAlbumView from "../components/users/tabsComponents/photosalbumview";
import FriendsList from "../components/users/tabsComponents/friendsList";
import PostDetails from "../components/common/header-component/notificationPages/post";
import AllNotification from "../components/common/header-component/notificationPages/allNotification";
import Userprofileview from "../components/users/user";
import Trending from "../components/birthDayCoponent/Trending";
import TrendingDefault from "../components/birthDayCoponent";
import PhotoAlbumuserView from "../components/users/usertabsComponents/photosalbumview";


export const routes = [
  { path: '/', Component: <Default /> },
  { path: '/home', Component: <Default /> },
  
  // messages
  { path: '/messages', Component: <Chat /> },
  { path: '/invite', Component: <InviteFriendsindex /> },
  { path: '/groups', Component: <Packchatindex /> },
  { path: '/groups-view/:id', Component: <Groupviewindex /> },
  { path: '/messages/:id', Component: <Chat /> },

  { path: '/reels', Component: <Reels /> },

  // {/* Users */}
  { path: '/user-profile-view/:uprofileid', Component: <Userprofileview /> },
  { path: '/report/list', Component: <Reportedcontent /> },
  { path: '/report/view', Component: <Reportviewindex /> },

  { path: '/users/userEdit', Component: <UserEdit /> },
  { path: '/users/:id', Component: <UserEdit /> },
  { path: '/users/userCards', Component: <UserCards /> },

  { path: '/video-details/:vid', Component: <VideoDetails /> },
  
  { path: '/post-details/:postId', Component: <PostDetails /> },
  { path: '/event-details/:id', Component: <EventDetails /> },
  { path: '/photos-details/:albumId/:photoId', Component: <PhotosDetails /> },
  {path:"/album/view/:albumId", Component:<PhotoAlbumView />},
  {path:"/album-user/view/:albumId", Component:<PhotoAlbumuserView />},

  { path: '/photos-details', Component: <PhotosDetails /> },
  { path: '/friends', Component: <FriendsList /> },
  { path: '/notification/all', Component: <AllNotification/> },
  { path: '/trending/:trending', Component: <TrendingDefault /> },
];
