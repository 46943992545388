import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NewsfeedContainer from "./groupdetails";
import jsonData from "../view/group.json";
import { toast } from "react-toastify";
import API from "../../../utils";
import axios from "axios";
import NewsfeedRight from "../../birthDayCoponent/newsfeedright";

const Groupviewindex = () => {
  const { id } = useParams(); // Get the 'id' parameter from the URL
  const token = localStorage.getItem("token");
const [data,setData]=useState("")
  useEffect(() => {
    if (id) {
      fetchGroups(id); // Call API only if the ID exists
    }
  }, [id, token]);

  const fetchGroups = async (groupId) => {

    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.groupDetail}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === true) {
        setData(response.data.payload)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="container-fluid">
          <div className="ossn-layout-contents ossn-layout-group">
            <div className="ossn-system-messages">
              <div className="row">
                <div className="col-md-11">
                  <div className="ossn-system-messages-inner"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="ossn-group-profile">
                  <div className="ossn-group-top-row"></div>
                  <div className="ossn-group-bottom-row margin-top-10">
                    <div className="row" style={{ margin: "20px" }}>
                      <div
                        className="newsfeed-container group-section p-4"
                        style={{ marginBottom: "20px" }}
                      >
                        <NewsfeedContainer data={data} />
                      </div>
                      <div className="newsfeed-right">
                        <NewsfeedRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Groupviewindex;
