import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import API from '../../../../../utils';

const ValidationModal = ({ showModal, handleClose, postId }) => {

    const sharePost = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
      
        formData.append('type', 1);
        formData.append('title', 'share post');
        formData.append('description', postId);
        formData.append('content_type', 4);
      
        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.posts}`,
            formData,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data', // Required for FormData
              },
            }
          );
      
          if (response.data.status === true) {
            toast.success("Post updated successfully!");
            handleClose(); 
            // fetchData()
          } else {
            toast.error(response.data.message || "Failed to update post.");
          }
        } catch (error) {
          toast.error("Failed to update post.");
        }
      };
    if (!showModal) return null;
    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                Something went wrong!
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                Something went wrong! Reload this page and try again.
                </div>
                <div className="control">
                    <div className="controls">
                       
                        <a  onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ValidationModal;
