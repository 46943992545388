import React, { useEffect, useState } from "react";
import Photo from "../../../assets/images/sidebar/photo-green.png";
import Videogreen from "../../../assets/images/sidebar/video-green.png";
import Calendargreen from "../../../assets/images/sidebar/calendar-green.png";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Stories from "../stories";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';
import defalutimages from "../../../assets/images/user/user_default.jpg";
import { selectUserProfile } from "../../../store/features/user/userSlice";
import { useSelector } from "react-redux";
import { BsInfoLg } from "react-icons/bs";

const PostForm = ({ fetchData, addNewPost }) => {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [location, setLocation] = useState("");
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [privacy, setPrivacy] = useState(0);
  const [postBackground, setPostBackground] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [taggedFriends, setTaggedFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]); // Store the selected friends
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id")
  const [isLoading, setIsLoading] = useState(false); // Manage loading state
  const [isFriendsLoading, setIsFriendsLoading] = useState(false); // Manage friends loading state
  const [noFriendsFound, setNoFriendsFound] = useState(false); // Manage "No friends found" message
  const [infoModalOpen, setInfoModalOpen] = useState(false); // Manage info modal visibility

  const openInfoModal = () => {
    setInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  const handleInputChange = (e) => {
    setPostContent(e.target.value);
    if (e.target.value.trim() === "") {
      setPostBackground(null);
    }
  };

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedPhotos((prevPhotos) => [...prevPhotos, ...files]);
  };

  const handleVideoUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedVideos((prevVideos) => [...prevVideos, ...files]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    if (!postContent.trim()) {
      toast.error("Please enter some content before posting.");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    const hasMedia = uploadedPhotos.length > 0 || uploadedVideos.length > 0;

    formData.append("content_type", hasMedia ? 2 : 1);
    formData.append("type", 1);
    formData.append("title", postContent.trim());
    formData.append("location", location);
    formData.append("priority", privacy);

    selectedFriends.forEach((friend, index) => {
      formData.append(`tag_friends[${index}]`, friend.id);
    });

    uploadedPhotos.forEach((file, index) => {
      formData.append(`files[${index}]`, file); // Add photos
    });

    uploadedVideos.forEach((file, index) => {
      formData.append(`files[${index}]`, file); // Add videos
    });

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.posts}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status) {
        toast.success(response.data.message);
        setPostContent('');
        setUploadedPhotos([]);
        setLocation('');
        setPostBackground(null);
        setActiveSection(null);
        setSelectedFriends([]);
        fetchData();
        window.location.reload();
        // addNewPost(response.data.payload);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        // Extract and display specific error messages
        const errorMessages = error.response.data.message;
        if (errorMessages.title) {
          toast.error(`Title Error: ${errorMessages.title.join(", ")}`);
        } else {
          toast.error("Error: " + JSON.stringify(errorMessages));
        }
      } else {
        toast.error("Error uploading post.");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Handle Background Click to change the textarea background
  const handleBackgroundClick = (backgroundUrl) => {
    setPostBackground({ url: backgroundUrl });
  };

  const handleEmojiClick = (emoji) => {
    setPostContent((prevContent) => prevContent + emoji); // Append emoji to the postContent
  };

  // Handle Privacy Select
  const handlePrivacyClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectPrivacyOption = (optionValue) => {
    setPrivacy(optionValue);
    closeModal();
  };

  const userAvatar = useSelector(selectUserProfile);

  useEffect(() => {
    const fetchUserAvatar = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          // setUserAvatar(response.data.payload.profile_img); // Assuming the API returns an `avatar_url` property.
        } else {
          toast.error("Failed to load user avatar.");
        }
      } catch (error) {
        // toast.error("Error loading user avatar.");
      }
    };

    // fetchUserAvatar();
  }, []);

  const debouncedFetchFriends = debounce(async (search, offset = 0, limit = 10) => {
    setIsFriendsLoading(true); // Start loading
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.relations}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header
          },
          params: {
            search,
            offset,
            limit,
          },
        }
      );

      if (response.data.status) {
        setTaggedFriends(response.data.payload);
        setNoFriendsFound(response.data.payload.length === 0);
      } else {
        toast.error('Failed to load friends.');
      }
    } catch (error) {
      // toast.error('Error loading friends.');
    } finally {
      setIsFriendsLoading(false); // Stop loading
    }
  }, 300);

  const handleFriendInputChange = (e) => {
    const search = e.target.value;
    debouncedFetchFriends(search);
  };

  const handleFriendSelection = (friend) => {
    setSelectedFriends((prevFriends) => [...prevFriends, friend]);
    setTaggedFriends([]);
  };

  const handleFriendRemove = (index) => {
    setSelectedFriends((prevFriends) => prevFriends.filter((_, i) => i !== index));
  };

  return (
    <div className="ossn-wall-container mb-1">
      <Stories />
      <form
        action="https://app.xpets.com/action/wall/post/a"
        id="ossn-wall-form"
        encType="multipart/form-data"
        className="ossn-form"
        method="post"
        onSubmit={handleFormSubmit}
      >
        <fieldset>
          <input
            type="hidden"
            name="ossn_ts"
            value="1732602274"
          />
          <input
            type="hidden"
            name="ossn_token"
            value="3fa4ad022e06e3f5b49770635487ea6028f28d116901b9e2d42e563f1fc912ac"
          />

          {/* Tabs for different media types */}
          <div className="tabs-input">
            <div className="wall-tabs">
              <li className="item ossn-wall-container-menu-post" data-name="post">
                <a onClick={() => toggleSection("addPhoto")}>
                  <img src={Photo} alt="Post" />
                </a>
              </li>
              <li className="item ossn-wall-container-menu-videos" data-name="videos">
                <a onClick={() => toggleSection("addVideo")}>
                  <img src={Videogreen} alt="Videos" />
                </a>
              </li>
              <li className="item ossn-wall-container-menu-events" data-name="events">
                <a onClick={() => toggleSection("tagFriends")}>
                  <img src={Calendargreen} alt="Friends" />
                </a>
              </li>
            </div>
          </div>

          <div className="ossn-wall-container-data ossn-wall-container-data-post">
            {/* Post Content Textarea */}
            <div className="ossn-wall-post-container">
              <div className="user-icon">
                <img
                  src={userAvatar || defalutimages}
                  alt="User Avatar"
                  className="image-responsive "
                  style={{ width: '47px', height: '47px' }}
                />
              </div>
              <div className="ossn-loading" id="caption-loader" style={{ display: "none" }}></div>
              <textarea
                id="ossn-wall-post-text"
                style={{
                  display: "block",
                  background: postBackground
                    ? `url('${postBackground.url}') center / cover`
                    : "none",
                }}
                placeholder="Feeling Pawsome today!"
                name="post"
                value={postContent}
                onChange={handleInputChange}
              />
            </div>

            {activeSection === "tagFriends" && (
              <div id="ossn-wall-friend">
                <input
                  type="text"
                  placeholder="Tag Friends"
                  name="friends"
                  id="ossn-wall-friend-input"
                  onChange={handleFriendInputChange}
                />
                {isFriendsLoading ? (
                  <p>Loading friends...</p>
                ) : taggedFriends.length > 0 ? (
                  <ul className="tag-friends">
                    {taggedFriends.map((friend, index) => {
                      let friendDetails = null;
                      if (friend?.auth_user !== friend?.sender.id && friend?.auth_user === friend?.receiver.id) {
                        friendDetails = friend.sender;
                      } else if (friend?.auth_user === friend?.sender.id && friend?.auth_user !== friend?.receiver.id) {
                        friendDetails = friend.receiver;
                      }
                      return (
                        <li
                          key={index}
                          onClick={() => handleFriendSelection(friendDetails)}
                          style={{ cursor: "pointer" }}
                          className="my-1"
                        >
                          {friendDetails?.user_details?.pet_name}
                        </li>
                      );
                    })}
                  </ul>
                ) : noFriendsFound ? (
                  <p>No friends found.</p>
                ) : null}
              </div>
            )}

            {selectedFriends.length > 0 && (
              <div id="ossn-wall-friend">
                <ul>
                  {selectedFriends.map((friend, index) => (
                    <li key={index} className="m-2" style={{ display: "flex", alignItems: "center" }}>
                      <b className="w-25">{friend?.user_details?.pet_name}</b>
                      <button className="btn btn-default btn-sm m-0 " onClick={() => handleFriendRemove(index)}>Delete</button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {activeSection === "addLocation" && (
              <div id="ossn-wall-location">
                <input
                  type="text"
                  placeholder="Enter Location"
                  name="location"
                  id="ossn-wall-location-input"
                />
              </div>
            )}
            {activeSection === "addPhoto" && (
              <div id="ossn-wall-photo">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handlePhotoUpload}
                />
              </div>
            )}

            {activeSection === "addVideo" && (
              <div id="ossn-wall-video " className="mx-4">
                <input
                  type="file"
                  accept="video/*"
                  multiple
                  onChange={handleVideoUpload}
                />
              </div>
            )}
            {activeSection === "emojiSelector" && (
              <div id="ossn-wall-emoji">
                <div className="emoji-list">
                  {[
                    "😊", "😂", "😍", "😎", "😢", "😅", "🤔", "😜", "😇", "😉", "😱", "🤗", "🤩",
                    "🥳", "🤐", "😋", "🤪", "🥺", "😌", "🤧", "😤", "😞", "🙄", "😏", "😜", "🥰",
                    "😶", "😯", "😴", "😜", "👻", "💀", "🎃", "🐱", "🐶", "🐵", "🐮", "🦁", "🐯",
                    "🐷", "🐮", "🐸", "🐣", "🐦", "🐍", "🐛", "🦋", "🐜", "🦄", "🦑", "🐙", "🦞",
                    "🦐", "🐡", "🐠", "🐟", "🦓", "🦒", "🦘", "🐪", "🐫", "🐘", "🦏", "🐗", "🐖",
                    "🦃", "🦢", "🦦", "🦨", "🦩", "🦡", "🐆", "🐅", "🐈", "🐇", "🦔", "🐉", "🐍",
                    "🦅", "🦆", "🦢", "🐦", "🦋", "🐜", "🦇", "🐕", "🐩", "🐈‍⬛", "🐦", "🐧",
                    "🐓", "🐢", "🦎", "🐉", "🦖", "🦦", "🦩", "🦨", "🦦", "🐁", "🐀", "🐇", "🐿",
                    "🦔", "🐾", "🦨", "🐚", "🦻", "🦶", "🐁", "🐿", "🐇", "🐊", "🦦", "🦧", "🐒",
                    "🦉", "🦋", "🐜", "🦁", "🐅", "🐈", "🐇", "🦔", "🐉", "🐍",
                    "🦅", "🦆", "🦢", "🐦", "🦋", "🐜", "🦇", "🐕", "🐩", "🐈‍⬛", "🐦", "🐧",
                    "🐓", "🐢", "🦎", "🐉", "🦖", "🦦", "🦩", "🦨", "🦦", "🐁", "🐀", "🐇", "🐿",
                    "🦔", "🐾", "🦨", "🐚", "🦻", "🦶", "🐁", "🐿", "🐇", "🐊", "🦦", "🦧", "🐒",
                    "🦉", "🦋", "🐜", "🦁", "🐅", "🦄", "🦥", "🦩"
                  ].map((emoji, index) => (
                    <span
                      key={index}
                      onClick={() => handleEmojiClick(emoji)}
                      style={{ fontSize: "24px", cursor: "pointer", margin: "5px" }}
                    >
                      {emoji}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {activeSection === "postBackground" && (
              <div
                id="ossn-wall-postbg"
                style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                data-toggle="1"
              >
                {["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg"].map(
                  (img, index) => (
                    <span
                      key={index}
                      className=""
                      onClick={() =>
                        handleBackgroundClick(`https://app.xpets.com/components/OssnPostBackground/images/${img}`)
                      }
                      style={{
                        background: `url('https://app.xpets.com/components/OssnPostBackground/images/${img}') center / cover no-repeat`,
                      }}
                    ></span>
                  )
                )}
              </div>
            )}

            <div className="controls-container">
              <div className="ossn-loading ossn-hidden"></div>
              <div className="controls">
                {/* <li
                  className="ossn-wall-friend ossn-wall-container-control-menu-tag-friend"
                  onClick={() => toggleSection("tagFriends")}
                >
                  <img
                    src="https://app.xpets.com/themes/xpets/images/tagfriends.png"
                    alt="Tag Friends"
                  />
                </li>
                <li
                  className="ossn-wall-location ossn-wall-container-control-menu-location"
                  onClick={() => toggleSection("addLocation")}
                >
                  <img
                    src="https://app.xpets.com/themes/xpets/images/addlocation.png"
                    alt="Add Location"
                  />
                </li>
                <li
                  className="ossn-wall-photo ossn-wall-container-control-menu-photo"
                  onClick={() => toggleSection("addPhoto")}
                >
                  <img
                    src="https://app.xpets.com/themes/xpets/images/addphoto.png"
                    alt="Add Photo"
                  />
                </li>
                <li
                  className="ossn-wall-container-control-menu-emojii-selector"
                  onClick={() => toggleSection("emojiSelector")}
                >
                  <img
                    src="https://app.xpets.com/themes/xpets/images/smile.png"
                    alt="Emoji Selector"
                  />
                </li>
                <li
                  className="ossn-wall-container-control-menu-postbg-selector"
                  onClick={() => toggleSection("postBackground")}
                >
                  <img
                    src="https://app.xpets.com/themes/xpets/images/pen.png"
                    alt="Post Background"
                  />
                </li> */}
              </div>

              <div className="ossn-wall-post-button-container">
                <div className="ossn-wall-privacy" style={{  height: "34px"}}  onClick={openInfoModal}>
                  <span >
                    <BsInfoLg style={{  fontSize: "19px", color: "#005248" }} />
                  </span>
                </div>

                <div className="ossn-wall-privacy" onClick={handlePrivacyClick}>
                  <span >
                    <img
                      id="selectedprivacyimage"
                      src="https://app.xpets.com/themes/xpets/images/Icon_Paw_Filled.png"
                      alt="Privacy Icon"
                    />
                    <span id="selectedprivacyspan">
                      {privacy === 1 ? "Friends" : "Public"}
                    </span>
                  </span>
                  <input type="hidden" name="privacy" id="selectedprivacy" value={privacy} />
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <button
                    className="btn btn-primary btn-sm ossn-wall-post m-0"
                    type="submit"
                    value="Share"
                    disabled={isLoading}
                  >
                    {isLoading ? "Posting..." : "Post"}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <Modal isOpen={isModalOpen} toggle={closeModal}>
        <ModalBody className="p-0">
          <ModalHeader toggle={closeModal} className="p-0">Select Privacy</ModalHeader>
          <p>Please select privacy for wall post</p>
          <button className="privacy-option-btn" onClick={() => selectPrivacyOption(0)}>Public</button>
          <button className="privacy-option-btn" onClick={() => selectPrivacyOption(1)}>Friends</button>
        </ModalBody>
        <ModalFooter className="p-0">
          <button className="btn btn-default btn-sm" onClick={closeModal}>Cancel</button>
        </ModalFooter>
      </Modal>
      <Modal lg isOpen={infoModalOpen} toggle={closeInfoModal}>
        <ModalBody className="p-0">
          <ModalHeader toggle={closeInfoModal} className="p-1">Posting Options</ModalHeader>
          <div className="my-4 text-start">
            <h5 className="mb-2">Photo</h5>
            <p className="text-muted">
              Attach multiple photos in one post. Use the caption generator to create fun and unique captions for each image. Share your pet’s best moments with ease!
            </p>
          </div>
          <div className="mb-4 text-start">
            <h5 className="mb-2">Video</h5>
            <p className="text-muted">
              Upload one video at a time to share with everyone. Videos are automatically added to the reels for everyone to enjoy, unless you choose to keep them private.
            </p>
          </div>
          <div className="mb-4 text-start">
            <h5 className="mb-2">Tagging</h5>
            <p className="text-muted">
              Tag multiple furry friends in your posts. Let your buddies join in the fun and stay connected!
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="p-0">
          <button className="btn btn-default btn-sm" onClick={closeInfoModal}>Close</button>
        </ModalFooter>
      </Modal>
    </div >
  );
};

export default PostForm;
