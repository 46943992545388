import React from 'react';

const ConfirmationModalLogout = ({ showModal, handleClose, handleConfirm }) => {
    if (!showModal) return null;
    return (
        <>

            <div
                className="ossn-wall-item ossn-wall-item-476">
                <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
                    <div className="modal-content logout-content">
                        <div className="title justify-content-center" >
                            Are you sure you want to log out?<div className="close ms-2 mb-0" onClick={handleClose}>X</div>
                        </div>
                        <div className="modal-buttons">
                            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>Yes</button>
                            <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ConfirmationModalLogout;
