import React, { useState } from "react";
import logo from "../assets/images/logo/logo.png";
import auth from "../assets/images/auth/left-image2.webp";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import API from "../utils";
import { toast } from "react-toastify";
import Loader from "../components/common/Loader";

const Signup = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // State for form fields
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    petname: "",
    pettype: "",
    pet_type_other: "",
    petage: "",
    favouritesnack: "",
    description: "",
    location: "",
    gdpr_agree: false,
  });

  // Validation state
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    // Required fields validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formData.firstname.trim()) {
      newErrors.firstname = "Firstname is required";
    }
    if (!formData.lastname.trim()) {
      newErrors.lastname = "Lastname is required";
    }
    if (!formData.username.trim()) {
      newErrors.username = "Username is required";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.trim().length < 8) {
      newErrors.password = "Password must be at least 8 characters";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.petname.trim()) {
      newErrors.petname = "Pet name is required";
    }
   
    if (!formData.pettype.trim()) {
      newErrors.pettype = "Pet type is required";
    } else if (formData.pettype.trim() === "Other" && !formData.pet_type_other.trim()) {
      newErrors.pet_type_other = `Please specify the pet type`;
    }

    // if (!formData.pettype.trim()) {
    //   newErrors.pettype = "Pet type is required";
    // }

    // if (!formData.pet_type_other.trim()) {
    //   newErrors.pet_type_other = "Please specify the pet type";
    // }

    if (formData.petage.trim()) {
      const petAge = Number(formData.petage.trim());
      if (isNaN(petAge)) {
        newErrors.petage = "Pet age must be a valid number";
      } else if (petAge <= 0) {
        newErrors.petage = "Pet age must be greater than 0";
      } else {
        delete newErrors.petage; // Remove error if petage is valid
      }
    }

    if (!formData.gdpr_agree) {
      newErrors.gdpr_agree = "You must agree to the terms and conditions";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setLoading(true);
    try {
      const { firstname, lastname, email, username, password, confirmPassword, petname, pettype, pet_type_other, petage, favouritesnack, description, location, gdpr_agree } =
        formData;

      const postForm = new FormData();
      postForm.append("role", "user");
      postForm.append("name", firstname);
      postForm.append("last_name", lastname);
      postForm.append("username", username);
      postForm.append("email", email);
      postForm.append("password", password);
      postForm.append("pet_name", petname);
      postForm.append("pet_age", petage);
      postForm.append("pet_snack", favouritesnack);
      postForm.append("about_pet", description);
      postForm.append("type_of_pet", pettype);
      postForm.append("location", location);
      if(pettype == "Other") postForm.append("pet_type_other", pet_type_other);
      // postForm.append('favourite_treat',);
      // postForm.append('hobbies',);
      // postForm.append('description',);
      // postForm.append('language',);
      const response = await axios.post(`${BASE_URL}${ENDPOINTS.register}`, postForm);
      const { status, message, payload } = response.data;
      if (status) {
        toast.success(message || "Register successful");
        navigate(`/login`);
      } else {
        toast.error(message || "Register failed");
      }
    } catch (error) {
      const { data } = error.response;

      if (data  && Object.keys(data.message).length > 0) {
        if (data.message.email) {
          data.message.email.forEach((msg) => toast.error(msg));
        }
        if (data.message.username) {
          data.message.username.forEach((msg) => toast.error(msg));
        }
      } else toast.error(error.response?.data.message);
      // console.error("Error:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="ossn-layout-startup">
      <div className="container-fluid">
        <div className="ossn-home-container">
          <div className="row ">
            <div className="col-center ossn-page-contents">
              <div className="row ">
                {/* Left Section */}
                <div className="col-md-6 home-left-contents">
                  <img className="img-responsive" src={auth} alt="Welcome" />
                </div>

                {/* Right Section */}
                <div className="col-md-6 home-right-contents">
                  <div className="logo">
                    <img src={logo} alt="xPets Logo" />
                  </div>
                  <div className="widget-heading-home">Welcome to xPets</div>
                  {/* widget-contents */}
                  <div className="">
                    <p className="subtitle">Register now and start your adventure.</p>
                  </div>
                  <div className="ossn-widget-login">
                    <form
                      onSubmit={handleSubmit}
                      id="ossn-home-signup"
                      //   action="https://app.xpets.com/action/user/register"
                      method="post"
                      className="ossn-form"
                      encType="multipart/form-data"
                    >
                      <fieldset>
                        {/* Hidden Fields */}
                        <input type="hidden" name="ossn_ts" value="1732514835" />
                        <input type="hidden" name="ossn_token" value="0f2bc012..." />

                        {/* Name Fields */}
                        <div className="form-inline">
                          <div className="form-group ossn-form-group-half">
                            <input type="text" className="form-control" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="Your first name" />
                            {errors.firstname && <div className="error">{errors.firstname}</div>}
                          </div>
                          <div className="form-group ossn-form-group-half">
                            <input type="text" className="form-control" name="lastname" value={formData.lastname} onChange={handleChange} placeholder="Your last name" />
                            {errors.lastname && <div className="error">{errors.lastname}</div>}
                          </div>
                        </div>

                        {/* Email and Username */}
                        <div className="form-inline">
                          <div className="form-group ossn-form-group-half">
                            <input type="text" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Your email" />
                            {errors.email && <div className="error">{errors.email}</div>}
                          </div>
                          <div className="form-group ossn-form-group-half">
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              value={formData.username}
                              onChange={handleChange}
                              placeholder="Your username"
                              maxLength="50"
                            />
                            {errors.username && <div className="error">{errors.username}</div>}
                          </div>
                        </div>

                        {/* Password Fields */}
                        <div className="form-inline">
                          <div className="form-group ossn-form-group-half">
                            <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} placeholder="Your password" />
                            {errors.password && <div className="error">{errors.password}</div>}
                          </div>
                          <div className="form-group ossn-form-group-half">
                            {/* <input type="password" className="form-control" name="password_re" value={formData.confirmPassword} onChange={handleChange} placeholder="Confirm password" /> */}
                            <input
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm password"
                              value={formData.confirmPassword}
                              onChange={handleChange}
                              className="form-control"
                            />
                            {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
                          </div>
                        </div>

                        {/* Pet Details */}
                        <div className="form-inline">
                          <div className="form-group ossn-form-group-half">
                            <input type="text" className="form-control" name="petname" value={formData.petname} onChange={handleChange} placeholder="Your pet's name" />
                            {errors.petname && <div className="error">{errors.petname}</div>}
                          </div>
                          <div className="form-group ossn-form-group-half">
                            <input type="text" className="form-control" name="petage" value={formData.petage} onChange={handleChange} placeholder="Your pet's age (optional)" />
                            {errors.petage && <div className="error">{errors.petage}</div>}
                          </div>
                        </div>
                        <div className="form-inline">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="favouritesnack"
                              value={formData.favouritesnack}
                              onChange={handleChange}
                              placeholder="Your pet's favourite snack (optional)"
                            />
                          </div>
                        </div>
                        <div className="form-inline">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                              placeholder="Some facts about your pet (optional)"
                            />
                          </div>
                        </div>

                        {/* Pet Type and Location */}
                        <div className="form-inline">
                          <div className="form-group ossn-form-group-half">
                            {/* <input type="text" className="form-control" name="pettype" placeholder="Your pet type" /> */}
                            <select name="pettype" value={formData.pettype} onChange={handleChange} className="form-control">
                              <option value="">Select pet type</option>
                              <option value="Dog">Dog</option>
                              <option value="Cat">Cat</option>
                              <option value="Bird">Bird</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.pettype && <div className="error">{errors.pettype}</div>}
                          </div>
                          <div className="form-group ossn-form-group-half">
                            <input
                              type="text"
                              className="form-control"
                              name="pet_type_other"
                              value={formData.pet_type_other}
                              onChange={handleChange}
                              placeholder="If 'other', please specify"
                            />
                            {errors.pet_type_other && <div className="error">{errors.pet_type_other}</div>}
                          </div>
                        </div>
                        <div className="form-inline">
                          <div className="form-group">
                            <input type="text" className="form-control" name="location" value={formData.location} onChange={handleChange} placeholder="Your location (optional)" />
                          </div>
                        </div>

                        {/* GDPR Checkbox */}
                        <div className="gdpr">
                          <p>
                            <input type="checkbox" name="gdpr_agree" checked={formData.gdpr_agree} onChange={handleChange} /> I confirm to agree with your website{" "}
                            <a target="_blank" href="https://www.xpets.com/work/emerge-f42y8">
                              Terms and Conditions
                            </a>{" "}
                            and{" "}
                            <a target="_blank" href="https://www.xpets.com/privacy-policy">
                              Privacy Policy
                            </a>
                            {errors.gdpr_agree && <div className="error">{errors.gdpr_agree}</div>}
                          </p>
                        </div>

                        {/* Submit Button */}
                        <input
                          type="submit"
                          style={{ width: "100%" }}
                          id="ossn-submit-button"
                          className="btn btn-save"
                          value="Create an account"
                        />

                      </fieldset>
                    </form>
                  </div>

                  {/* Login Link */}
                  <div className="buttons">
                    <span>Already have an account? </span>
                    <Link to="/login" className="loginlink">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-contents">
                  <div className="ossn-footer-menu">
                    <a className="menu-footer-a_copyrights" href="/">
                      © COPYRIGHT xpets
                    </a>
                    <a className="menu-footer-about" href="https://www.xpets.com/work/emerge-f42y8">
                      About
                    </a>
                    <a className="menu-footer-site" href="https://xpets.com/terms-of-service">
                      Terms and Conditions
                    </a>
                    <a className="menu-footer-privacy" href="https://www.xpets.com/privacy-policy">
                      Privacy
                    </a>
                  </div>
                </div>
              </footer>
            </div>
            {/* Footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
