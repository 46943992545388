import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import pawgreen from "../../../assets/icoicon/paw-green.svg";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import dotsImg from "../../../assets/images/dots.png";
import blockImg from "../../../assets/images/block.png";
import { FaPlay, FaStop } from "react-icons/fa";
import { deleteData, getData, postData } from "../../../utils/axiosApiReq";
import VoiceRecorder from "./VoiceRecorder";
import Delete from "../../../assets/images/sidebar/trash.png";
import defalutimages from "../../../assets/images/user/user_default.jpg";
import { setUser } from "../../../store/features/user/userSlice";
import { useDispatch } from "react-redux";

const Profile = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("basic");
  const [profileData, setProfileData] = useState({
    username: "",
    email: "",
    current_password: "",
    new_password: "",
    confirm_password: "",
    name: "",
    last_name: "",
    dob: "",
    phone: "",
    pet_name: "",
    pet_age: "",
    type_of_pet: "",
    pet_type_other: "",
    location: "",
    language: "",
  });
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const [relationshipFormVisible, setRelationshipFormVisible] = useState(false);
  const [familyFormVisible, setFamilyFormVisible] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [relationshipForm, setRelationshipForm] = useState({
    type: "",
    privacy: "2",
    friendGuid: "",
    since: "",
  });
  const [familyForm, setFamilyForm] = useState({
    friendGuid: "",
    type: "",
    privacy: "2",
  });
  const [showModal, setShowModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [audioDataToDelete, setAudioDataToDelete] = useState(null);
  const [showUnblockConfirmationModal, setShowUnblockConfirmationModal] = useState(false);
  const [userToUnblock, setUserToUnblock] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...validationErrors };
    switch (name) {
      case "current_password":
        if (!value) {
          errors.current_password = "Current password is required.";
        } else {
          delete errors.current_password;
        }
        break;
      case "new_password":
        if (value && value.length < 6) {
          errors.new_password = "New password must be at least 6 characters long.";
        } else {
          delete errors.new_password;
        }
        break;
      case "confirm_password":
        if (value !== profileData.new_password) {
          errors.confirm_password = "Passwords do not match.";
        } else {
          delete errors.confirm_password;
        }
        break;
      case "name":
        if (!value) {
          errors.name = "First name is required.";
        } else {
          delete errors.name;
        }
        break;
      case "last_name":
        if (!value) {
          errors.last_name = "Last name is required.";
        } else {
          delete errors.last_name;
        }
        break;
      case "dob":
        if (!value) {
          errors.dob = "Date of birth is required.";
        } else {
          delete errors.dob;
        }
        break;
      case "pet_dob":
        if (!value) {
          errors.pet_dob = "Pet Date of birth is required.";
        } else {
          delete errors.pet_dob;
        }
        break;
      // case "phone":
      //   if (!value) {
      //     errors.phone = "";
      //   } else if (!/^\d{10}$/.test(value)) {
      //     errors.phone = "phone number must be 10 digits.";
      //   } else {
      //     delete errors.phone;
      //   }
      //   break;
      case "pet_name":
        if (!value) {
          errors.pet_name = "Pet name is required.";
        } else {
          delete errors.pet_name;
        }
        break;
      case "pet_age":
        if (!value) {
          errors.pet_age = "Pet age is required.";
        } else if (!/^\d+$/.test(value)) {
          errors.pet_age = "Pet age must be a numeric value.";
        } else {
          delete errors.pet_age;
        }
        break;

      // case "type_of_pet":
      //   if (!value) {
      //     errors.type_of_pet = "Pet type is required.";
      //   } else {
      //     delete errors.type_of_pet;
      //   }
      //   break;
      case "location":
        if (!value) {
          errors.location = "Location is required.";
        } else {
          delete errors.location;
        }
        break;
      case "language":
        if (!value) {
          errors.language = "Language is required.";
        } else {
          delete errors.language;
        }
        break;
      default:
        break;
    }
    setValidationErrors(errors);
  };

  const updateNewPassword = async () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("current_password", profileData.current_password);
    formData.append("new_password", profileData.new_password);
    formData.append("confirm_password", profileData.confirm_password);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.changePassword}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === true) {
        toast.success("Password updated successfully!");
        setProfileData({
          ...profileData,
          current_password: "",
          new_password: "",
          confirm_password: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const messages = error.response.data.message; // Extract error messages

        // Display individual error messages
        Object.entries(messages).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((msg) => toast.error(msg)); // Display each error message
          } else {
            toast.error(value);
          }
        });
      } else {
        toast.error("Failed to update password.");
      }
    }
  };

  useEffect(() => {
    fetchUserData();
    getVoiceClip();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    const uid = localStorage.getItem("user_id");

    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}/${uid}`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === true) {
        const data = response.data.payload.user;
        dispatch(setUser(data));
        setProfileData({
          username: data.username || "",
          email: data.email || "",
          password: "",
          new_password: "",
          name: data.name || "",
          last_name: data.last_name || "",
          dob: data.user_details.dob || "",
          pet_dob: data.user_details.pet_dob || "",
          phone: data.user_details.phone || "",
          pet_name: data.user_details.pet_name || "",
          pet_age: data.user_details.pet_age || "",
          type_of_pet: data.user_details.type_of_pet || "",
          pet_type_other: data.user_details.pet_type_other || "",
          location: data.user_details.location || "",
          language: data.user_details.language || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) { }
  };

  const [blockedUsers, setBlockedUsers] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        const response = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.blockFriends}`, // Replace with your API endpoint
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === true) {
          setBlockedUsers(response.data.payload); // Adjust based on API structure
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        // toast.error("Failed to fetch blocked users.");
      }
    };

    fetchBlockedUsers();
  }, [token]);

  const validateAllFields = () => {
    let errors = {};
    Object.keys(profileData).forEach((key) => {
      validateField(key, profileData[key]);
      if (validationErrors[key]) {
        errors[key] = validationErrors[key];
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const updateProfileData = async () => {

  //   // Exclude `password` and `new_password` fields
  //   const dataToSend = Object.fromEntries(
  //     Object.entries(profileData).filter(([key]) => key !== "password" && key !== "new_password")
  //   );

  //   const token = localStorage.getItem('token');
  //   try {
  //     const response = await axios.post(
  //       `${API.BASE_URL}${API.ENDPOINTS.profileUpdate}`,
  //       dataToSend,
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.data.status === true) {
  //       toast.success("Profile updated successfully!");
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     toast.error("Failed to update profile data.");
  //   }
  // };
  const updateProfileData = async () => {
    // Exclude `password` and `new_password` fields
    const dataToSend = Object.fromEntries(Object.entries(profileData).filter(([key]) => key !== "password" && key !== "new_password"));

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.profileUpdate}`, dataToSend, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        toast.success("Profile updated successfully!");
        window.location.reload();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const messages = error.response.data.message; // Extract error messages

        // Display individual error messages
        Object.entries(messages).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((msg) => toast.error(msg)); // Display each error message
          } else {
            toast.error(value);
          }
        });
      } else {
        toast.error("Failed to update profile data.");
      }
    }
  };

  const handleRelationshipFormChange = (e) => {
    const { name, value } = e.target;
    setRelationshipForm({ ...relationshipForm, [name]: value });
  };

  const handleFamilyFormChange = (e) => {
    const { name, value } = e.target;
    setFamilyForm({ ...familyForm, [name]: value });
  };

  const handleRelationshipSubmit = (e) => {
    e.preventDefault();
  };

  const handleFamilySubmit = (e) => {
    e.preventDefault();
    // Submit the familyForm data here via API
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleUnblock = (user) => {
    setUserToUnblock(user);
    setShowUnblockConfirmationModal(true);
  };
  const handleConfirmUnblock = async () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("id", userToUnblock.id);
    formData.append("status", 1);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.relationsunfollow}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to unblock user.");
    } finally {
      setShowUnblockConfirmationModal(false);
      setUserToUnblock(null);
    }
  };

  // const handleUnblock = async (id) => {
  //   const token = localStorage.getItem("token");

  //   const formData = new FormData();
  //   formData.append("id", id);
  //   formData.append("status", 1);
  //   try {
  //     const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.relationsunfollow}`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (response.data.status === true) {
  //       toast.success(response.data.message);
  //       window.location.reload();
  //     }
  //   } catch (error) { }
  // };

  const handleConfirmDelete = () => {
    if (audioDataToDelete) {
      deleteVoiceClip(audioDataToDelete);
      setShowDeleteConfirmationModal(false);
      setAudioDataToDelete(null);
    }
  };

  const handleCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(false);
    setAudioDataToDelete(null);
  };

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showRecordingBtn, setShowRecordingBtn] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
        // toast.error(audioBlob.size+' : 428')
        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURL(audioURL);

        // Reset audio chunks for future recordings
        // audioChunks.current = [];
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      toast.error("Error accessing microphone: ", error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    setShowDeleteBtn(false);
  };

  const saveAudio = async () => {
    // if (audioURL) {
    //   const link = document.createElement("a");
    //   link.href = audioURL;
    //   link.download = "recording.webm";
    //   link.click();
    // }

    try {
      const formData = new FormData();
      const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
      // toast.error("size"+audioBlob.size)
      formData.append("voice", audioBlob, "recording.webm");

      const response = await postData(API.ENDPOINTS.voiceclips, formData, true);
      const { status, message, payload } = response;
      if (status) {
        setAudioData(payload);
        // setAudioURL(audioBlob);
        setAudioURL(payload?.file_path_url);
        setShowDeleteBtn(true);
        audioChunks.current = [];
        setIsRecording(false);
        toast.success(message);
      } else {
        toast.error(message || "Error posting my voice.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Error posting my voice.");
    }
  };

  const getVoiceClip = async () => {
    try {
      // setLoading(true);
      const response = await getData(API.ENDPOINTS.voiceclips);
      const { status, message, payload } = response;
      if (status) {
        if (payload) {
          setShowRecordingBtn(false)
          setAudioData(payload);
          setAudioURL(payload?.file_path_url);
          // if(payload?.file_path_url)
          setShowDeleteBtn(true);
        } else setShowRecordingBtn(true)
      } else {
        toast.error(message || "Error fetching supplyments");
      }
    } catch (error) {
      toast.error(error || "Error fetching video log");
    } finally {
      // setLoading(false);
    }
  };

  const deleteVoiceClip = async (id) => {
    try {
      // setLoading(true);
      const response = await deleteData(`${API.ENDPOINTS.voiceclips}/${id}`);
      const { status, message } = response;
      if (status) {
        setShowRecordingBtn(true)
        setShowDeleteBtn(false);
        setAudioData(null);
        setAudioURL(null);
        toast.success(message);
      } else {
        toast.error(message || "Error deleting supplement");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Error processing request");
    } finally {
      // setLoading(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "basic":
        return (
          <>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Username</label>
                <input type="text" className="form-control" name="username" value={profileData.username} onChange={handleInputChange} disabled />
                {validationErrors.username && <div className="text-danger">{validationErrors.username}</div>}
              </div>
              <div className="col-md-6">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" name="email" value={profileData.email} onChange={handleInputChange} readOnly />
                {validationErrors.email && <div className="text-danger">{validationErrors.email}</div>}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Owner's First Name</label>
                <input type="text" className="form-control" name="name" value={profileData.name} onChange={handleInputChange} />
                {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}
              </div>
              <div className="col-md-6">
                <label className="form-label">Owner's Last Name</label>
                <input type="text" className="form-control" name="last_name" value={profileData.last_name} onChange={handleInputChange} />
                {validationErrors.last_name && <div className="text-danger">{validationErrors.last_name}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Date of Birth</label>
                <input type="date" className="form-control" name="dob" value={profileData.dob} onChange={handleInputChange} max={new Date().toISOString().split("T")[0]} // Setting max to today's date
                />
                {validationErrors.dob && <div className="text-danger">{validationErrors.dob}</div>}
              </div>
              <div className="col-md-6">
                <label className="form-label">Pet Date of Birth</label>
                <input type="date" className="form-control" name="pet_dob" value={profileData.pet_dob} onChange={handleInputChange} max={new Date().toISOString().split("T")[0]} // Setting max to today's date
                />
                {validationErrors.pet_dob && <div className="text-danger">{validationErrors.pet_dob}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">phone</label>
                <input type="tel" className="form-control" name="phone" value={profileData.phone} onChange={handleInputChange} />
                {/* {validationErrors.phone && (
                  <div className="text-danger">{validationErrors.phone}</div>
                )} */}
              </div>
              <div className="col-md-6">
                <label className="form-label">Pet Name</label>
                <input type="text" className="form-control" name="pet_name" value={profileData.pet_name} onChange={handleInputChange} />
                {validationErrors.pet_name && <div className="text-danger">{validationErrors.pet_name}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Pet Age</label>
                <input type="text" className="form-control" name="pet_age" value={profileData.pet_age} onChange={handleInputChange} />
                {validationErrors.pet_age && <div className="text-danger">{validationErrors.pet_age}</div>}
              </div>
              <div className="col-md-6">
                <label className="form-label">Pet Type</label>
                <select className="form-control" name="type_of_pet" value={profileData.type_of_pet} onChange={handleInputChange}>
                  <option value="">Select pet type</option>
                  <option value="Dog">Dog</option>
                  <option value="Cat">Cat</option>
                  <option value="Bird">Bird</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Other</label>
                <input type="text" className="form-control" name="pet_type_other" value={profileData.pet_type_other} onChange={handleInputChange} />
              </div>
              <div className="col-md-6">
                <label className="form-label">Location</label>
                <input type="text" className="form-control" name="location" value={profileData.location} onChange={handleInputChange} />
                {validationErrors.location && <div className="text-danger">{validationErrors.location}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Language</label>
                <input type="text" className="form-control" name="language" value={profileData.language} onChange={handleInputChange} />
                {validationErrors.language && <div className="text-danger">{validationErrors.language}</div>}
              </div>
            </div>

            <div className="col-md-12">
              <label className="form-label">Voice Recording</label>
            </div>
            {audioURL ? (
              <>
                <div className="align-items-center d-flex mt-3">
                  <audio controls src={audioURL}></audio>
                  {audioURL && showDeleteBtn && (
                    <button
                      className="button-grey ossn-make-sure border-0 mt-0 ms-2"
                      onClick={() => {
                        setAudioDataToDelete(audioData.id);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      <img src={Delete} alt="Delete" style={{ width: "26px", height: "26px" }} />
                    </button>
                  )}
                </div>
                {audioURL && !showDeleteBtn && (
                  <div>
                    <button className="btn btn-primary" onClick={saveAudio}>
                      Save Recording
                    </button>
                  </div>
                )}
              </>
            ) : null}
            {!audioURL && showRecordingBtn && (
              <button className="btn btn-primary" onClick={isRecording ? stopRecording : startRecording}>
                {isRecording ? <FaStop /> : <FaPlay />}
              </button>
            )}

          </>
        );
      case "Password":
        return (
          <>
            <div className="row">
              <div className="col-md-12  mb-3">
                <label className="form-label">Current Password</label>
                <input type="password" className="form-control" name="current_password" value={profileData.current_password} onChange={handleInputChange} />
                {validationErrors.current_password && <div className="text-danger">{validationErrors.current_password}</div>}
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">New Password</label>
                <input type="password" className="form-control" name="new_password" value={profileData.new_password} onChange={handleInputChange} />
                {validationErrors.new_password && <div className="text-danger">{validationErrors.new_password}</div>}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12  mb-3">
                <label className="form-label">Confirm New Password</label>
                <input type="password" className="form-control" name="confirm_password" value={profileData.confirm_password} onChange={handleInputChange} />
                {validationErrors.confirm_password && <div className="text-danger">{validationErrors.confirm_password}</div>}
              </div>
            </div>
            <div>
              <Button className="btn btn-primary" onClick={updateNewPassword}>
                Save Changes
              </Button>
            </div>
          </>
        );
      case "blocking":
        return (
          <div className="profile-edit-layout-right">
            <p className="blocked-title">Blocking</p>
            <p>This page contains the list of people you blocked.</p>
            <div className="ossn-block-lists">
              <div className="row">
                {blockedUsers.length > 0 ? (
                  blockedUsers.map((user) => (
                    <div className="ossn-users-list-item mx-2" key={user.receiver.id}>
                      <div className="friends-list-controls-menu" id={`users-list-controls-menu-${user.id}`}>
                        <img className="img-responsive" src={dotsImg} alt="Options" onClick={() => toggleDropdown(user.id)} style={{ cursor: "pointer" }} />
                        {openDropdownId === user.id && (
                          <div className="custom-dropdown" ref={dropdownRef}>
                            <div>
                              <a className="users-list-controls-menu-item" onClick={() => handleUnblock(user)}>
                                <div>Unblock</div>
                                <img className="img-responsive icon" src={blockImg} alt="Block Icon" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="user-item-container">
                        <img className="img-responsive" src={user.receiver.profile_img || defalutimages} width="100" height="100" alt="User Avatar" />

                        <div className="uinfo">
                          <a className="ossn-output-user-url userlink" data-username={user.receiver.pet_name}>
                            {user.receiver.details.pet_name}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No blocked users found.</p>
                )}
              </div>
            </div>
          </div>
        );
      case "deleteacc":
        return (
          <div className="profile-edit-layout-right">
            <p className="alert delete-alert">Warning! The account delete will be permanent. This action is not reversible.</p>
            <Button className="btn btn-sm btn-primary" onClick={handleOpenModal}>
              Delete Account
            </Button>
          </div>
        );
      case "familyrelationships":
        return (
          <>
            <div className="profile-edit-layout-right">
              <div className="family-relationships-container">
                {/* Relationship Section */}
                <div className="family-status">
                  <div className="family-relationships-title profile-edit-layout-title">Relationship</div>
                  <button className="add-relationship-member add-family-status" onClick={() => setRelationshipFormVisible(!relationshipFormVisible)}>
                    <img src={pawgreen} alt="Add Relationship" />
                    Add relationship status
                  </button>
                  {relationshipFormVisible && (
                    <div className="relation-member-add-form" style={{ display: "block" }}>
                      <form onSubmit={handleRelationshipSubmit}>
                        <div>
                          <select className="ossn-dropdown-input" name="type" value={relationshipForm.type} onChange={handleRelationshipFormChange}>
                            <option value="0">-</option>
                            <option value="Single">Single</option>
                            <option value="InaRelationship">In a Relationship</option>
                            <option value="Engaged">Engaged</option>
                            <option value="Married">Married</option>
                            <option value="ItsComplicated">It's Complicated</option>
                            <option value="InanOpenRelationship">In an Open Relationship</option>
                            <option value="Widowed">Widowed</option>
                            <option value="Separated">Separated</option>
                            <option value="Divorced">Divorced</option>
                            <option value="InaCivilUnion">In a Civil Union</option>
                            <option value="InaDomesticPartnership">In a Domestic Partnership</option>
                          </select>
                        </div>
                        <div className="margin-top-10">
                          <label>Privacy</label>
                          <select className="ossn-dropdown-input" name="privacy" value={relationshipForm.privacy} onChange={handleRelationshipFormChange}>
                            <option value="2">Public</option>
                            <option value="3">Friends</option>
                          </select>
                        </div>
                        <div className="margin-top-10">
                          <input
                            type="text"
                            className="form-control mb-3"
                            name="friendGuid"
                            placeholder="Search friends list"
                            value={relationshipForm.friendGuid}
                            onChange={handleRelationshipFormChange}
                          />
                        </div>
                        <div>
                          <input type="date" className="form-control" name="since" placeholder="Since" value={relationshipForm.since} onChange={handleRelationshipFormChange} />
                        </div>
                        <p>We will send a confirmation request to the member for this relationship.</p>
                        <button type="button" className="btn btn-sm btn-primary btn btn-primary">
                          Save
                        </button>
                      </form>
                    </div>
                  )}
                  <div className="family-members-list relationship-status"></div>
                </div>

                <div className="family-status">
                  <div className="family-relationships-title margin-top-10 profile-edit-layout-title">Family Members</div>
                  <button className="add-family-member" onClick={() => setFamilyFormVisible(!familyFormVisible)}>
                    <img src={pawgreen} alt="Add Family Member" />
                    Add family member
                  </button>
                  {familyFormVisible && (
                    <div className="relation-member-add-form" style={{ display: "block" }}>
                      <form onSubmit={handleFamilySubmit}>
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <input
                              type="text"
                              className="form-control mb-3"
                              name="friendGuid"
                              placeholder="Search friends list"
                              value={familyForm.friendGuid}
                              onChange={handleFamilyFormChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <select className="ossn-dropdown-input" name="type" value={familyForm.type} onChange={handleFamilyFormChange}>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Son">Son</option>
                              <option value="Sister">Sister</option>
                              <option value="Brother">Brother</option>
                              <option value="Auntie">Auntie</option>
                              <option value="Uncle">Uncle</option>
                              <option value="Niece">Niece</option>
                              <option value="Nephew">Nephew</option>
                              <option value="Grandmother">Grandmother</option>
                              <option value="Grandfather">Grandfather</option>
                            </select>
                          </div>
                        </div>
                        <div className="margin-top-10">
                          <label>Privacy</label>
                          <select className="ossn-dropdown-input" name="privacy" value={familyForm.privacy} onChange={handleFamilyFormChange}>
                            <option value="2">Public</option>
                            <option value="3">Friends</option>
                          </select>
                        </div>
                        <p>We will send a confirmation request to the member for this relationship.</p>
                        <button type="button" className="btn btn-sm btn-primary btn btn-primary">
                          Save
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <h5>Select a tab to view content</h5>;
    }
  };

  return (
    <div className="container-fluid">
      <div className="edit-profile">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 p-2">
            <div className="profile-edit-tabs">
              <div className="profile-edit-layout-title">Edit</div>
              <div>
                <a className={`profile-edit-tab-item ${activeTab === "basic" ? "profile-edit-tab-item-active" : ""}`} onClick={() => setActiveTab("basic")}>
                  Basic Settings
                </a>
                <a className={`profile-edit-tab-item ${activeTab === "Password" ? "profile-edit-tab-item-active" : ""}`} onClick={() => setActiveTab("Password")}>
                  Change Password
                </a>
                <a className={`profile-edit-tab-item ${activeTab === "blocking" ? "profile-edit-tab-item-active" : ""}`} onClick={() => setActiveTab("blocking")}>
                  Blocking
                </a>
                <a className={`profile-edit-tab-item ${activeTab === "deleteacc" ? "profile-edit-tab-item-active" : ""}`} onClick={() => setActiveTab("deleteacc")}>
                  Delete Account
                </a>
                {/* <a
                  className={`profile-edit-tab-item ${activeTab === "familyrelationships"
                    ? "profile-edit-tab-item-active"
                    : ""
                    }`}
                  onClick={() => setActiveTab("familyrelationships")}
                >
                  Family and Relationships
                </a> */}
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-9 p-2">
            <div className="card">
              <div className="card-body">
                {renderTabContent()}
                {activeTab === "basic" && (
                  <div>
                    <Button className="btn btn-primary" onClick={updateProfileData}>
                      Save Changes
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal showModal={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmDelete} />
      <DeleteConfirmationModal
        showModal={showDeleteConfirmationModal}
        handleClose={handleCloseDeleteConfirmationModal}
        handleConfirm={handleConfirmDelete}
      />
      <UnblockConfirmationModal
        showModal={showUnblockConfirmationModal}
        handleClose={() => setShowUnblockConfirmationModal(false)}
        handleConfirm={handleConfirmUnblock}
        user={userToUnblock}
      />
    </div>
  );
};

const UnblockConfirmationModal = ({ showModal, handleClose, handleConfirm, user }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="unblockConfirmationModal" className="modal" style={{ display: "block" }}>
        <div className="modal-content" style={{ width: 'max-content' }}>
          <div className="title">
            Are you sure you want to unblock {user.receiver.details.pet_name}?
            <div className="close mb-0 ms-5" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons  m-0 justify-content-end">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="confirmationModal" className="modal" style={{ display: "block" }}>
        <div className="modal-content" style={{ width: 'max-content' }}>
          <div className="title">
            Are you sure you want to delete your account?
            <div className="close mb-0 ms-5" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons  m-0 justify-content-end">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="deleteConfirmationModal" className="modal" style={{ display: "block" }}>
        <div className="modal-content" style={{ width: 'max-content' }}>
          <div className="title">
            Are you sure you want to delete this voice clip?
            <div className="close mb-0 ms-5" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons  m-0 justify-content-end">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
