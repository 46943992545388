import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import man from "../../../assets/images/user/user_default.jpg";
import Friendsimg from "../../../assets/images/plus.png";
import API from '../../../utils';
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const FriendsList = () => {
  const token = localStorage.getItem('token');
  const [activeTab, setActiveTab] = useState("requests");
  const [friendRequests, setFriendRequests] = useState([]);
  const [friendSuggestions, setFriendSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  // Fetch friend requests
  const fetchFriendsData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.friendRequests}`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status) {
        setFriendRequests(response.data.payload);
      }
    } catch (error) {
    }
  };

  // Fetch friend suggestions
  const fetchFriendSuggestions = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.friendSuggestions}`, {
        params: { offset, limit },
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        const newSuggestions = response.data.payload;
        setFriendSuggestions((prevSuggestions) => [
          ...prevSuggestions,
          ...newSuggestions,
        ]);
        setHasMore(newSuggestions.length === limit);
        setOffset((prevOffset) => prevOffset + 1);
      }
    } catch (error) {
    }
  };

  const handleAddFriends = async (id) => {
    const token = localStorage.getItem('token');

    const formData = new FormData();
    formData.append("receive_by", id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.relations}`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
        // fetchFriendSuggestions()
        window.location.reload();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };


  // Handle suggestion reject request
  const handleSuggetionRejectRequest = async (id) => {

    try {
      const response = await axios.delete(`${API.BASE_URL}${API.ENDPOINTS.relations}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.status) {
        window.location.reload();
        toast.success("Suggestion removed!");
      }
    } catch (error) {
      toast.error("Failed to remove suggestion.");
    }
  };
  const handleSuggetionremovsuggestion = async (id) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.removeSuggestion}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.status) {
        window.location.reload();
        toast.success("Suggestion removed!");
      }
    } catch (error) {
      toast.error("Failed to remove suggestion.");
    }
  };
  const handleConfirmDelete = async () => {
    if (currentSuggestionId) {
      try {
        await handleSuggetionremovsuggestion(currentSuggestionId); // Call the API
        handleCloseModal(); // Close the modal after successful deletion
      } catch (error) {
        toast.error("Failed to remove suggestion.");
      }
    }
  };


  const handleAcceptRequest = async (id) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', 1);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.followRequests}`, formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        toast.success(response.data.message);
        setFriendRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== id)
        );

        // fetchFriendsData();
      } else {
      }
    } catch (error) {
    }
  };
  // Fetch data based on active tab
  useEffect(() => {
    if (activeTab === "requests") {
      fetchFriendsData();
    } else if (activeTab === "suggestions") {
      fetchFriendSuggestions();
    }
  }, [activeTab]);

  // Filtered suggestions based on search term
  const filteredSuggestions = friendSuggestions.filter((suggestion) =>
    suggestion?.user_details?.pet_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [currentSuggestionId, setCurrentSuggestionId] = useState(null);

  const handleOpenModal = (id) => {
    setCurrentSuggestionId(id); // Set the ID of the suggestion to remove
    setShowDeleteConfirmationModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteConfirmationModal(false);
    setCurrentSuggestionId(null); // Clear the ID
  };

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const handleOpenRejectModal = (id) => {
    setCurrentRequestId(id); // Set the ID of the request to reject
    setShowRejectModal(true); // Open the modal
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false); // Close the modal
    setCurrentRequestId(null); // Clear the ID
  };

  const handleConfirmReject = async () => {
    if (currentRequestId) {
      try {
        await handleSuggetionRejectRequest(currentRequestId); // Call the API
        handleCloseRejectModal(); // Close the modal after successful rejection
      } catch (error) {
        toast.error("Failed to reject the request.");
      }
    }
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="ossn-layout-module px-0 bg-none" style={{ backgroundColor: 'rgb(240, 240, 240, 0%)', boxShadow: 'none' }}>
          <div className="row">
            <div className="col-lg-12">
              <form className="card" onSubmit={(e) => e.preventDefault()}>
                <div className="card-body">
                  {/* Tabs */}
                  <ul className="nav nav-tabs">
                    <li
                      className={`nav-item ${activeTab === "requests" ? "tab-active" : "not-active"}`}
                      onClick={() => setActiveTab("requests")}
                    >
                      <a className="nav-link">Friend Requests</a>
                    </li>
                    <li
                      className={`nav-item ${activeTab === "suggestions" ? "tab-active" : "not-active"}`}
                      onClick={() => setActiveTab("suggestions")}
                    >
                      <a className="nav-link">Friend Suggestions</a>
                    </li>
                  </ul>

                  {/* Tab Content */}
                  <div className="tab-content mt-3">
                    {activeTab === "requests" && (
                      <div className="tab-pane active">
                        {friendRequests.length === 0 ? (
                          <p className="d-flex justify-content-center">No friend requests available.</p>
                        ) : (
                          <div className="row">
                            {friendRequests.map((request) => (
                              <div key={request.id} className="col-lg-4 col-md-4 col-sm-4 p-0 m-0 d-flex align-items-center">
                                <div className="card text-center  m-0" style={{ position: "relative" }}>
                                  <img src={request.sender.profile_img || man} alt="Default" className="card-img-top" style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%", margin: "10px auto" }} />
                                  <div className="card-body">
                                    <h6 className="card-title">{request?.sender?.user_details?.pet_name || ""}</h6>
                                    <div>
                                      <button
                                        className="btn btn-success btn-sm mx-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleAcceptRequest(request.id);
                                        }}
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleOpenRejectModal(request.id); // Open modal on click
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {activeTab === "suggestions" && (
                      <>
                        <div className="mb-3 ">
                          <input
                            type="text"
                            className="form-control w-25"
                            placeholder="Search friends..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                        {filteredSuggestions.length === 0 && searchTerm ? (
                          <p className="d-flex justify-content-center">No results found.</p>
                        ) : (
                          <InfiniteScroll
                            dataLength={filteredSuggestions.length}
                            next={fetchFriendSuggestions}
                            hasMore={hasMore}
                            style={{ overflow: 'none' }}
                            loader={
                              <h6 className="text-center">
                                <Spinner animation="border" role="status" className="content-loader">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              </h6>
                            }
                            endMessage={<p className="d-none">No more posts to load</p>}
                          >
                            <div className="row m-0">
                              {filteredSuggestions.map((suggestion) => (
                                <div key={suggestion.id} className="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex flex-column ">
                                  <div className="card text-center" style={{ position: "relative" }}>
                                    <img
                                      src={suggestion.profile_img || man}
                                      alt="Default"
                                      className="card-img-top"
                                      style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%", margin: "10px auto" }}
                                    />
                                    <button
                                      className="mt-0 btn btn-danger btn-sm"
                                      style={{ position: "absolute", top: "5px", right: "5px", borderRadius: "50%", padding: "5px 7px" }}
                                      onClick={() => handleOpenModal(suggestion.id)} // Pass the suggestion ID
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                    <div className="card-body">
                                      <h6 className="card-title">{suggestion?.user_details?.pet_name || ""}</h6>
                                      {suggestion.request_sent ? (
                                        <button
                                          className="btn btn-danger"
                                          onClick={() => handleSuggetionRejectRequest(suggestion.request_sent_id)}
                                        >
                                          Cancel
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => handleAddFriends(suggestion.id)}
                                        >
                                          Add Friend
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </InfiniteScroll>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        showModal={showDeleteConfirmationModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
      />
      <RejectConfirmationModal
        showModal={showRejectModal}
        handleClose={handleCloseRejectModal}
        handleConfirm={handleConfirmReject}
      />

    </Fragment>
  );
};
const DeleteConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="deleteConfirmationModal" className="modal" style={{ display: "block" }}>
        <div className="modal-content" style={{ width: 'max-content' }}>
          <div className="title">
            Are you sure you want to delete this suggestion?
            <div className="close mb-0 ms-5" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const RejectConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <div id="rejectConfirmationModal" className="modal" style={{ display: "block" }}>
      <div className="modal-content" style={{ width: 'max-content' }}>
        <div className="title">
          Are you sure you want to reject this friend request?
          <div className="close mb-0 ms-5" onClick={handleClose}>
            X
          </div>
        </div>
        <div className="modal-buttons">
          <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
            Yes
          </button>
          <button className="btn btn-default btn-sm" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};


export default FriendsList;
