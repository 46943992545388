import React, { useEffect, useRef, useState } from "react";
import Share from "../../assets/images/sidebar/share.png";
import Comment from "../../assets/images/sidebar/message-blank.png";
import Like from "../../assets/images/sidebar/heart_new.png";
import Menu from "../../assets/images/sidebar/dots.png";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import EditModal from "./modals/editModal";
import ReportModal from "./modals/reportModal";
import ShareModal from "./modals/shareModal";
import ConfirmationModal from "./modals/confirmationModal";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import API from "../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import { formatDistanceToNow } from 'date-fns';
const Video = ({ postId, userData = {}, initialPostContent }) => {
  const { vid } = useParams();
  const navigate = useNavigate()
  const { BASE_URL, ENDPOINTS } = API;
  const [showReportModal, setShowReportModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [postContent, setPostContent] = useState(initialPostContent);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dropdownRef = useRef();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleEditPost = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowReportModal = () => setShowReportModal(true);
  const handleSaveEdit = (editedContent) => {
    setPostContent(editedContent);
    setShowEditModal(false);
  };

  const handleCloseReportModal = () => setShowReportModal(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdowns({});
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
  }, []);

  const {
    avatarUrl = "https://default-avatar-url.com/default-avatar.jpg",
    name,
    username,
  } = userData;

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleDeletePost = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(
        `${BASE_URL}${ENDPOINTS.addVideoLog}/${vid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


      if (response.data.status === true) {
        //   fetchData()
        toast.success(response.data.message);
        handleCloseModal()
        navigate("/users/userEdit?tab=video")
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // toast.error("Failed to fetch owner data.");
    } finally {
      // setLoading(false); 
    }
  };

  const handleCommentPost = () => {
    if (commentText.trim()) {
      const newComment = { user: "User", text: commentText };
      setComments((prevComments) => [...prevComments, newComment]);
      setCommentText("");
      // socket.emit('add_comment', { postId, comment: newComment });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachmentPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLikePost = () => {
    setIsLiked(!isLiked);
    setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
  };

  const handleSharePost = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };



  const fetchVideoData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS.addVideoLog}/${vid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.data.status) {
        setVideoData(response.data.payload);
        setLoading(false);
      } else {
        toast.error("Failed to fetch album details.");
      }
    } catch (error) {
      //   toast.error("Error fetching album details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoData();
  }, []);
  const formattedDate = videoData.created_at
    ? formatDistanceToNow(new Date(videoData.created_at), { addSuffix: true })
    : 'Unknown';

    const truncateDescription = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

  return (
    <div
      className="ossn-wall-item ossn-wall-item-476"
      id={`activity-item-${vid}`}
    >
      <div className="row">
        <div className="widget-heading">{videoData.title}</div>
        <div className="video-section">
          <ReactPlayer
            url={videoData.file}
            playing
            loop
            muted
            controls
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </div>

        <div className="ossn-video-description">
          <p>
            Description{" "}<br />
            <span className="time-created">
              {videoData.description
                ? truncateDescription(videoData.description, 200)
                : "No description available"}
            </span>
          </p>
        </div>


        <div className="ossn-video-description">
          <p className="m-0">
            This is for public <span className="time-created">{formattedDate}</span>
          </p>
        </div>

        <div className="video-posted-by">
          Posted by <a href="#">{videoData.posted_by?.pet_name || "Unknown"}</a>
        </div>


        <div className="comments-list">

          <div className="ossn-video-controls">
            <Button
              className="btn btn-danger me-2 btn-sm"
              onClick={handleShowModal}
            >
              Delete
            </Button>
            {/* <Button className="btn btn-sm" onClick={handleEditPost}>
              Edit
            </Button> */}
          </div>
        </div>
      </div>
      {/* <input
        type="file"
        id="file-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {attachmentPreview && (
        <div className="attachment-preview">
          <img
            src={attachmentPreview}
            alt="Attachment Preview"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        </div>
      )} */}
      <ConfirmationModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleDeletePost}
      />
      <ShareModal
        showModal={showShareModal}
        handleClose={handleCloseShareModal}
        postId={postId}
      />
      <ReportModal
        showModal={showReportModal}
        handleClose={handleCloseReportModal}
        postId={postId}
      />
      <EditModal
        showModal={showEditModal}
        handleClose={handleCloseEditModal}
        postContent={postContent}
        handleSave={handleSaveEdit}
      />
    </div>
  );
};

export default Video;
