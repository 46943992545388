import React, { Fragment, useEffect, useId, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import API from "../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import Post from "./post";
import { Spinner } from "react-bootstrap";
import defalutimages from "../../../assets/images/user/user_default.jpg";
import NewsfeedRight from "../../birthDayCoponent/newsfeedright";

const TimeLine = ({activeTab}) => {
  const token = localStorage.getItem("token");
  const { BASE_URL, ENDPOINTS } = API;
  const uid = localStorage.getItem("user_id");
  const [posts, setPosts] = useState([]);

  const dropdownRef = useRef();
  const [openDropdowns, setOpenDropdowns] = useState({});

  const [page, setPage] = useState(1);
  const [perPagePosts, setPerPagePosts] = useState(10);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const formatPost = (post) => ({
    postId: post.id,
    userData: {
      avatarUrl: post.posted_user.profile_img || defalutimages,
      username: post.posted_user.name,
      name: post.posted_user.pet_name,
      postedId: post.posted_user.id,
      title: post.title,
      updated_at: format(new Date(post.created_at), "MM/dd/yyyy, hh:mm:ss a"),
      likes: post.like_comment_count.like,
      comment: post.like_comment_count.comment,
      image_links: post.image_links,
      video_links: post.video_links,
      sharedPost: post.related_post_details ? formatPost(post.related_post_details) : null,
      is_liked_by_user: post.is_liked_by_user,
    },
  });

  const fetchData = async (perpage = 0, type = 1, limit = 10) => {
    try {
      setLoadingPosts(true);
      const response = await axios.get(`${BASE_URL}${ENDPOINTS.getAllPostUserwise}/${uid}?type=${type}&offset=${perpage}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status === true) {
        const formattedPosts = response.data.payload.map(formatPost);
        setPosts((prevPosts) => [...prevPosts, ...formattedPosts]);
        if (response.data.payload.length < perpage) {
          setHasMore(false); // No more data to fetch
        }
        // if (perpage === 0) {
        //   setPosts(formattedPosts);
        // } else {
        //   setPosts((prevPosts) => [...prevPosts, ...formattedPosts]);
        // }

        setHasMore(formattedPosts.length === limit);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    } finally {
      setLoadingPosts(false);
    }
  };

  const resetTimeLine = () => {
    // setIsResetTimeLine(true)
    // setPosts([])
    // setPage(1);
    // setHasMore(true)
    // setIsResetTimeLine(false)
  }

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdowns({});
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const fetchMorePosts = () => {
    if (!loadingPosts && hasMore) {
      setPage((prevPage) => prevPage + 1);
      // fetchData(page + 1);
      // fetchData(perPagePosts, page + 1);
    }
  };

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
            <div className="newsfeed-container">
              <div className="newsfeed-middle">
                { activeTab == "timeline" && <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMorePosts}
                  hasMore={hasMore} // Whether there is more data to load
                  loader={
                    <h6 className="text-center">
                      <Spinner animation="border" role="status" className="content-loader">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </h6>
                  } // Loader when fetching more data
                  endMessage={<p className="d-none">No more posts to load</p>} // Message when there are no more videos
                >
                  {posts.map((post,i) => (
                    <Post key={`${Date.now()}${post.postId}${i}`} postId={post.postId} userData={post.userData} fetchData={fetchData} resetTimeLine={resetTimeLine} />
                  ))}
                </InfiniteScroll>}
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TimeLine;
