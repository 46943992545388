import React, { Fragment, useState, useEffect } from "react";
import PostForm from "./defaultCompo/postform";
import Post from "./defaultCompo/post";
import API from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../utils/axiosApiReq";
import { Spinner } from "react-bootstrap";
import Loader from "../common/Loader";
import defalutimage from "../../assets/images/user/user_default.jpg";
import NewsfeedRight from "../birthDayCoponent/newsfeedright";

const Default = () => {
  const [posts, setPosts] = useState([]);

  const formatPost = (post) => ({
    postId: post.id,
    userData: {
      avatarUrl: post.posted_user.profile_img || defalutimage,
      username: post.posted_user.name,
      name: post.posted_user.pet_name,
      postedId: post.posted_user.id,
      tagged_users: post.tagged_users.map((user) => ({
        id: user.id,
        name: user.name,
        pet_name: user.pet_name,
        profile_img: user.profile_img || defalutimage,
      })),
      title: post.title,
      updated_at: format(new Date(post.created_at), "MM/dd/yyyy, hh:mm:ss a"),
      // created_at: format(new Date(post.created_at), "MM/dd/yyyy, hh:mm:ss a"),
      likes: post.like_comment_count.like,
      comment: post.like_comment_count.comment,
      image_links: post.image_links,
      video_links: post.video_links,
      prioritys: parseInt(post.priority, 10),
      sharedPost: post.related_post_details ? formatPost(post.related_post_details) : null,
      is_liked_by_user: post.is_liked_by_user,
    },
  });

  const [page, setPage] = useState(1);
  const [perPagePosts, setPerPagePosts] = useState(10);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [postType, setPostType] = useState(0);

  const fetchData = async (per_page = 10, page = 1, friendpost = postType, type = 1) => {
    const token = localStorage.getItem("token");
    try {
      // setLoadingPosts(true);
      const postTypeValue = type === 1 ? 0 : 1;

      const response = await getData(API.ENDPOINTS.posts, { type: type, limit: per_page, offset: page, friendpost });
      const { status, message, payload } = response;
      if (status) {
        const formattedPosts = payload.map(formatPost);
        setPosts((prevPosts) => [...prevPosts, ...formattedPosts]);
        if (payload.length < per_page) {
          setHasMore(false); // No more data to fetch
        }
        // setPosts(formattedPosts);
      } else {
        toast.error(message);
      }
      /*const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.posts}`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status) {
        const formattedPosts = response.data.payload.map(formatPost);
        setPosts(formattedPosts);
      } else {
        toast.error(response.data.message);
      }*/
    } catch (error) {
      // toast.error(error?.data?.payload?.message || error || "Failed to load posts!");
      // console.error("Error fetching owner data:", error);
    } finally {
      setLoadingPosts(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
    setPosts([]);
    setHasMore(true); 
    setPage(1);
    fetchData(perPagePosts);
  }, []);

  // Handle fetching more videos on scroll
  const fetchMorePosts = () => {
    if (!loadingPosts && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      fetchData(perPagePosts, page + 1, postType); // Fetch next page of videos
    }
  };

  const addNewPost = (newPost) => {
    const formattedPost = formatPost(newPost);
    setPosts((prevPosts) => [formattedPost, ...prevPosts]);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
            <div className="newsfeed-container">
              <div className="newsfeed-middle">
                <PostForm addNewPost={addNewPost} fetchData={fetchData} />
                <div className="py-2">
                  <div className="post-type-selector d-flex justify-content-end align-items-center">
                    <select
                      id="postType"
                      value={postType} // Use value to control the selected option
                      onChange={(e) => {
                        const selectedType = Number(e.target.value);
                        setPostType(selectedType); // Update post type
                        setPosts([]); // Clear current posts to show the new set
                        setHasMore(true); // Reset hasMore to true
                        setPage(1); // Reset page number to 1
                        fetchData(perPagePosts, 1, selectedType); // Fetch posts based on selected type
                      }}
                      className="form-select w-50"
                    >
                      <option value={0}>The World 🌍</option>
                      <option value={1}>My Buddies 🐾</option>
                    </select>
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMorePosts}
                  hasMore={hasMore} // Whether there is more data to load
                  loader={
                    <h6 className="text-center">
                      <Spinner animation="border" role="status" className="content-loader">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </h6>
                  } // Loader when fetching more data
                  endMessage={<p className="d-none">No more posts to load</p>} // Message when there are no more videos
                >
                  {posts.length > 0
                    ? posts.map((post, i) => <Post key={`${Date.now()}${post.postId}${i}`} postId={post.postId} userData={post.userData} fetchData={fetchData} />)
                    : !loadingPosts && (
                        <div className="text-center py-3"></div>
                      )
                      // !loadingPosts && <div className="text-center py-3">No posts to display.</div>
                  }
                </InfiniteScroll>
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Default;
