import React, { Fragment, useState, useEffect } from "react";
import API from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../utils/axiosApiReq";
import { Spinner } from "react-bootstrap";
import Loader from "../common/Loader";
import defalutimage from "../../assets/images/user/user_default.jpg"
import NewsfeedRight from "../birthDayCoponent/newsfeedright";
import Trending from "./Trending";
import { useParams } from "react-router-dom";

const TrendingDefault = () => {
  const [posts, setPosts] = useState([]);
  const { trending } = useParams();

  const formatPost = (post) => ({
    postId: post.id,
    userData: {
      avatarUrl: post.posted_user.profile_img || defalutimage,
      username: post.posted_user.name,
      name: post.posted_user.pet_name,
      postedId: post.posted_user.id,
      title: post.title,
      updated_at: format(new Date(post.created_at), "MM/dd/yyyy, hh:mm:ss a"),
      likes: post.like_comment_count.like,
      comment: post.like_comment_count.comment,
      image_links: post.image_links,
      video_links: post.video_links,
      sharedPost: post.related_post_details ? formatPost(post.related_post_details) : null,
      is_liked_by_user: post.is_liked_by_user
    },
  });
  
  const [page, setPage] = useState(1);
  const [perPagePosts, setPerPagePosts] = useState(10);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchData = async (perpage = 1, page = 1, type = trending) => {
    try {
      // setLoadingPosts(true);
      const response = await getData(API.ENDPOINTS.postbytags, { tag: type, perpage, page });
      const { status, message, payload } = response;
      if (status) {
        const formattedPosts = payload.map(formatPost);
        setPosts((prevPosts) => [...prevPosts, ...formattedPosts]);
        if (payload.length < perpage) {
          setHasMore(false); // No more data to fetch
        }
        // setPosts(formattedPosts);
      } else {
        toast.error(message);
      }
    } catch (error) {
      // toast.error(error?.data?.payload?.message || error || "Failed to load posts!");
      // console.error("Error fetching owner data:", error);
    } finally {
      setLoadingPosts(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
    fetchData(perPagePosts);
  }, []);

  // Handle fetching more videos on scroll
  const fetchMorePosts = () => {
    if (!loadingPosts && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      fetchData(perPagePosts, page + 1); // Fetch next page of videos
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
            <div className="newsfeed-container">
              <div className="newsfeed-middle">
                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMorePosts}
                  hasMore={hasMore}
                  loader={
                    <h6 className="text-center">
                      <Spinner animation="border" role="status" className="content-loader">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </h6>
                  } // Loader when fetching more data
                  endMessage={<p className="d-none">No more posts to load</p>} // Message when there are no more videos
                >
                  {posts.map((post) => (
                    <Trending key={post.postId} postId={post.postId} userData={post.userData} fetchData={fetchData} />
                  ))}
                </InfiniteScroll>
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TrendingDefault;