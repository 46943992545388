import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import pawgreen from '../../../assets/icoicon/paw-green.svg';
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import defalutimages from "../../../assets/images/user/user_default.jpg";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../../store/features/user/userSlice";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState("basic");
  const [profileData, setProfileData] = useState({
    username: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    name: "",
    last_name: "",
    dateOfBirth: "",
    mobile: "",
    petName: "",
    petAge: "",
    petType: "",
    other: "",
    location: "",
    language: "",
  });
  const [audioURL, setAudioURL] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [relationshipForm, setRelationshipForm] = useState({
    type: "",
    privacy: "2",
    friendGuid: "",
    since: "",
  });
  const [familyForm, setFamilyForm] = useState({
    friendGuid: "",
    type: "",
    privacy: "2",
  });
  const [showModal, setShowModal] = useState(false);
  const { uprofileid } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  useEffect(() => {
    fetchUserData();
    getVoiceClip();
  }, [uprofileid]);


  // const [userAvatar, setUserAvatar] = useState("");
  const userAvatar = useSelector(selectUserProfile);

  useEffect(() => {
    const fetchUserAvatar = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          // setUserAvatar(response.data.payload.profile_img || defalutimages); 
        } else {
          toast.error("Failed to load user avatar.");
        }
      } catch (error) {
        // toast.error("Error loading user avatar.");
      }
    };

    // fetchUserAvatar();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.profile}/${uprofileid}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === true) {
        const data = response.data.payload.user;
        setProfileData({
          username: data.username || "",
          email: data.email || "",
          currentPassword: "",
          newPassword: "",
          name: data.name || "",
          last_name: data.last_name || "",
          dateOfBirth: data.user_details.dob || "",
          pet_dob: data.user_details.pet_dob || "",
          mobile: data.user_details.phone || "",
          petName: data.user_details.pet_name || "",
          petAge: data.user_details.pet_age || "",
          petType: data.user_details.type_of_pet || "",
          other: data.user_details.other || "",
          location: data.user_details.location || "",
          language: data.user_details.language || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };

  const getVoiceClip = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.voiceclips}/${uprofileid}`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
      const { status, message, payload } = response.data;
      if (status) {
        setAudioData(payload);
        setAudioURL(payload?.file_path_url);
      } else {
        toast.error(message || "Error fetching supplyments");
      }
    } catch (error) {
      toast.error(error || "Error fetching video log");
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="edit-profile">
        <div className="row">
          {/* Tabs on the left */}
          <div className="col-sm-12 col-md-12 col-lg-12 p-2">
            <div className="profile-edit-tabs">
              <div className="profile-edit-layout-title">Edit</div>
              <div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={profileData.username}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={profileData.email}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Owner's First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={profileData.name}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Owner's Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      value={profileData.last_name}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Date of Birth</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dateOfBirth"
                      value={profileData.dateOfBirth}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Pet Date of Birth</label>
                    <input
                      type="date"
                      className="form-control"
                      name="pet_dob"
                      value={profileData.pet_dob}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Mobile</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="mobile"
                      value={profileData.mobile}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Pet Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="petName"
                      value={profileData.petName}
                      onChange={handleInputChange} disabled
                    />
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Pet Age</label>
                    <input
                      type="text"
                      className="form-control"
                      name="petAge"
                      value={profileData.petAge}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Pet Type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="petType"
                      value={profileData.petType}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Other</label>
                    <input
                      type="text"
                      className="form-control"
                      name="other"
                      value={profileData.other}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      name="location"
                      value={profileData.location}
                      onChange={handleInputChange} disabled
                    />
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Language</label>
                    <input
                      type="text"
                      className="form-control"
                      name="language"
                      value={profileData.language}
                      onChange={handleInputChange} disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label className="form-label">Voice Recording</label>
                  </div>
                  {audioURL ? (
                    <>
                      <div className="mt-3">
                        <audio controls src={audioURL}></audio>
                      </div>
                    </>
                  ) : (
                    <p>No voice clip found</p>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div >
  );
};


export default UserProfile;
