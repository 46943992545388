import axios from 'axios';
import React, { useState } from 'react';
import API from '../../../../../utils';
import { toast } from 'react-toastify';

const EditModal = ({ showModal, handleClose, postId, title ,fetchData, setUserData, resetTimeLine}) => {
  const [updatedTitle, setUpdatedTitle] = useState(title || ""); // State for updated title

  const updatePostData = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
  
    formData.append('id', postId);
    formData.append('title', updatedTitle);
  
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.postsUpdate}`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data', // Required for FormData
          },
        }
      );
  
      if (response.data.status === true) {
        // setUserData((prevData) => ({
        //   ...prevData,
        //   title: updatedTitle,
        // }));
        toast.success("Post updated successfully!");
        handleClose();
        // resetTimeLine()
        // fetchData();
        window.location.href = "userEdit?tab=timeline"
      } else {
        toast.error(response.data.message || "Failed to update post.");
      }
    } catch (error) {
      console.log('error',error);
      
      toast.error("Failed to update post.");
    }
  };
  

  if (!showModal) return null;

  return (
    <>
      <div
        className="ossn-halt ossn-light"
        style={{ height: '100vh', position: 'fixed', display: 'block' }}
      ></div>
      <div className="ossn-message-box" style={{ display: 'block' }}>
        <div className="title">
          Edit Post
          <div className="close-box" onClick={handleClose}>X</div>
        </div>
        <div className="contents">
          <div className="ossn-box-inner">
            <div style={{ width: '100%', margin: 'auto' }}>
              <form id="edit-post-form" className="ossn-form">
                <fieldset>
                  <div>
                    <textarea
                      id="post-edit"
                      name="post"
                      value={updatedTitle}
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      style={{ width: '100%', height: '100px' }}
                    />
                  </div>
                  <input type="hidden" name="guid" value={postId} />
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div className="control">
          <div className="controls">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={updatePostData}
            >
              Save
            </button>
            <button
              onClick={handleClose}
              className="btn btn-default btn-sm"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModal;
