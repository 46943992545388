import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';
import { Link } from 'react-router-dom';

const MyGroup = () => {
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
  

    fetchGroups();
  }, [token]);

  const fetchGroups = async () => {
    const formData = new FormData();
    // formData.append("own", 1);
    formData.append("members", 1);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.groupList}`,
        formData, // Sending null in the request body
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === true) {
        setGroups(response.data.payload);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };

  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.get(
      `${API.BASE_URL}${API.ENDPOINTS.deleteGroup}/${itemToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    

      if (response.data.status) {
        fetchGroups()
        toast.success(response.data.message);
        handleClose()
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // toast.error("Failed to fetch owner data.");
    } finally {
      // setLoading(false); 
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setItemToDelete(null);
  };

  return (
    <div className="search-data">
      <div className="title">MY Groups</div>
      <hr />
      <div className="group-search-items">
        {groups.length > 0 ? (
          groups.map((group) => (
            <div className="row" key={group.id}>
              <div className="col-md-10 col-lg-10 col-sm-12">
                <Link to={`/groups-view/${group.id}`}>
                  <div
                    className="icon"
                    style={{ float: 'left', marginRight: '20px' }}
                    width="100"
                    height="100"
                  >
                    {group.image ? (
                      <img
                        src={group.image}
                        alt={group.name}
                        style={{ width: 50, height: 50, borderRadius: '50%' }}
                      />
                    ) : (
                      <span>{group.name.charAt(0).toUpperCase()}</span>
                    )}
                  </div>
                  <div className="group-search-details">
                    <p
                      className="group-name"
                      style={{ wordBreak: 'break-word', fontWeight: 'bold' }}
                    >
                      {group.name}
                    </p>
                    <p className="ossn-group-search-by m-0">{group.details}</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-lg-2 col-sm-12 d-flex align-items-center justify-content-center justify-content-lg-end">
                <button
                  onClick={() => handleDelete(group.id)}
                  className="btn m-0 btn-default btn-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="no-data-message">No groups found. Create a new group or check back later.</p>
        )}
      </div>
  
      <ConfirmationModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </div>
  );  
};

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
        <div className="modal-content">
          <div className="title">
            Are you sure you want to delete this group?
            <div className="close" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyGroup;
