import React, { useCallback, useEffect, useState } from "react";
import Stories from "react-insta-stories";
import API from "../../../utils";
import axios from "axios";
import { selectUserProfile } from "../../../store/features/user/userSlice";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";

const StoryViewer = ({ stories, onClose }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [commentInputVisible, setCommentInputVisible] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [progress, setProgress] = useState(0);

  const currentStory = stories[currentStoryIndex];
  const storyCount = stories.length;

  useEffect(() => {
      if (!storyCount) return;
  
      // Progress logic with cleanup
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            handleNextStory();
            return 0;
          }
          return prev + 10; 
        });
      }, 250);
  
      return () => clearInterval(timer); // Clean up on unmount or prop changes
    }, [currentStoryIndex]);

  const buildStoryContent = (story) => {
    const storyContent = [];

    if (story.imageLinks && story.imageLinks.length > 0) {
      story.imageLinks.forEach((url) => {
        storyContent.push({
          url: url,
          type: "image",
          header: {
            heading: story.postedBy?.petName,
            profileImage: story.postedBy?.profileImage,
          },
        });
      });
    }

    if (story.videoLinks && story.videoLinks.length > 0) {
      story.videoLinks.forEach((url) => {
        storyContent.push({
          url: url,
          type: "video",
          header: {
            heading: story.postedBy?.petName,
            profileImage: story.postedBy?.profileImage,
          },
        });
      });
    }

    return storyContent;
  };

  const handlePreviousStory = useCallback(() => {
      setProgress(0);
      if (currentStoryIndex > 0) {
        setCurrentStoryIndex(currentStoryIndex - 1);
      }
    }, [currentStoryIndex]);
  
    const handleNextStory = useCallback(() => {
      setProgress(0);
      if (currentStoryIndex < stories.length - 1) {
        setCurrentStoryIndex(currentStoryIndex + 1);
      }else {
        onClose();
      }
    }, [currentStoryIndex, storyCount, onClose]);

  const likeReel = (storyId) => {
    // Implement like functionality
  };

  const unlikeReel = (storyId) => {
    // Implement unlike functionality
  };

  const handleSendMessage = (story) => {
    // Implement send message functionality
  };

  return (
    <>
      <div className="story-section d-flex">
        <div className="story-bg-story">
          <div
            className="ossn-halt ossn-light"
            style={{
              height: "100%",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
          ></div>
          <div className="ossn-message-box-sorty" >
            <div className="ossn-main-model mobileStoryModal">
              <div className="custom-story-viewer" style={{ height: "100vh" }}>
                <div className="story-header">
                  <ProgressBar
                    now={progress}
                    style={{
                      height: '5px',
                      width: '100%',
                      backgroundColor: 'rgb(255 255 255 / 38%)',
                    }}
                  />
                </div>
                <div className="story-details">
                  <img src={currentStory.postedBy.profileImage || "default-avatar-url.jpg"} alt={`${currentStory.postedBy.petName}'s profile`} className="story-profile-img" />
                  <div className="story-info">
                    <h4>{currentStory.postedBy.petName}</h4>
                    {/* <p>{new Date(currentStory.createdAt).toLocaleDateString()}</p> */}
                  </div>
                </div>
                <button onClick={onClose} className="close-button m-0">
                  &times;
                </button>

                <div className="story-content">
                  {currentStory.videoLinks && currentStory.videoLinks.length > 0 ? (
                    <video src={currentStory.videoLinks[0]} autoPlay />
                  ) : (
                    <img src={currentStory.imageLinks[0]} alt="Story" loading="eager" />
                  )}
                </div>


                <div className="story-navigation">
                  <button onClick={handlePreviousStory} disabled={currentStoryIndex === 0}>

                  </button>
                  <button onClick={handleNextStory} disabled={currentStoryIndex === stories.length - 1}>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomCalendar = () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isViewerOpen, setViewerOpen] = useState(false);
  const profileImg = useSelector(selectUserProfile);
  const [stories, setStories] = useState([]);
  const [storiesByDate, setStoriesByDate] = useState({});

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const formatDateKey = (date) => date?.toISOString().split("T")[0];

  const handleDateClick = (date) => {
    const dateKey = formatDateKey(date);
    if (storiesByDate[dateKey]) {
      setSelectedDate(date);
      setViewerOpen(true); // Open the story viewer
    } else {
      setSelectedDate(date);
    }
  };

  const handleCloseViewer = () => {
    setViewerOpen(false);
  };

  useEffect(() => {
    const fetchStoriesAndProfile = async () => {
      try {
        // Fetch stories
        const storiesResponse = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.posts}?type=${2}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const formattedStories = storiesResponse.data.payload
          .filter((story) => story.posted_by === parseInt(uid)) // Filter stories by logged-in user
          .map((story) => ({
            id: story.id,
            title: story.title,
            description: story.description,
            type: story.type,
            imageLinks: story.image_links || [],
            videoLinks: story.video_links || [],
            postedBy: story.posted_user
              ? {
                name: story.posted_user.name,
                email: story.posted_user.email,
                petName: story.posted_user.pet_name,
                profileImage: story.posted_user.profile_img,
              }
              : null,
            createdAt: new Date(story.created_at), // Ensure correct date parsing
            likeCommentCount: story.like_comment_count,
          }));

        setStories(formattedStories);

        // Organize stories by date
        const storiesByDate = {};
        formattedStories.forEach((story) => {
          const dateKey = formatDateKey(story.createdAt); // Correctly formatted date
          const adjustedDateKey = formatDateKey(new Date(story.createdAt.setDate(story.createdAt.getDate() - 1))); // Adjust for the next day
          if (!storiesByDate[adjustedDateKey]) {
            storiesByDate[adjustedDateKey] = [];
          }
          storiesByDate[adjustedDateKey].push(story); // Store in the adjusted date (next day)
        });

        setStoriesByDate(storiesByDate);

        // Fetch profile data
        /*const profileResponse = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.profile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const profileData = profileResponse.data?.payload;
        setProfileImg(profileData?.profile_img || "default-avatar-url.jpg");*/
      } catch (error) {
        console.error("Error fetching stories or profile:", error);
      }
    };

    fetchStoriesAndProfile();
  }, []);

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const totalDays = daysInMonth(month, year);

    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      const currentDateKey = formatDateKey(new Date(year, month, day));
      calendarDays.push(
        <div
          key={day}
          className={`day ${storiesByDate[currentDateKey] ? "has-story" : ""} ${selectedDate?.getDate() === day &&
            selectedDate.getMonth() === month &&
            selectedDate.getFullYear() === year
            ? "selected"
            : ""
            }`}
          onClick={() => handleDateClick(new Date(year, month, day))}
        >
          <div className="inner-div-date">{day}</div>
        </div>
      );
    }

    return calendarDays;
  };

  const changeMonth = (direction) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1)
    );
  };

  return (
    <div className="calendar-section">
      <div className="custom-calendar">
        <div className="calendar-header">
          <button onClick={() => changeMonth(-1)}><i className="fa fa-arrow-left"></i>

          </button>
          <span>
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentDate.getFullYear()}
          </span>
          <button onClick={() => changeMonth(1)}><i className="fa fa-arrow-right"></i>
          </button>
        </div>
        {/* <hr /> */}
        <div className="calendar-grid mt-3">
          <div className="day-label">Sun</div>
          <div className="day-label">Mon</div>
          <div className="day-label">Tue</div>
          <div className="day-label">Wed</div>
          <div className="day-label">Thu</div>
          <div className="day-label">Fri</div>
          <div className="day-label">Sat</div>
          {renderCalendar()}
        </div>
      </div>
      {isViewerOpen && selectedDate && (
        <StoryViewer
          stories={storiesByDate[formatDateKey(selectedDate)]}
          onClose={handleCloseViewer}
        />
      )}
    </div>
  );
};

export default CustomCalendar;
