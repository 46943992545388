import React, { useEffect, useRef, useState } from 'react';
import Share from '../../assets/images/sidebar/share.png';
import Comment from '../../assets/images/sidebar/message-blank.png';
import Like from '../../assets/images/sidebar/heart_new.png';
import Menu from '../../assets/images/sidebar/dots.png';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPen } from 'react-icons/fa';
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import profileImage from "../../assets/images/profilePageImage.jpg"
import defalutimages from "../../assets/images/user/user_default.jpg";

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
    if (!showModal) return null;

    return (
        <>
            <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
                <div className="modal-content">
                    <div className="title">
                        Are you sure you want to delete this post?
                        <div className="close" onClick={handleClose}>X</div>
                    </div>
                    <div className="modal-buttons">
                        <button className="btn btn-primary btn-sm" onClick={handleConfirm}>Yes</button>
                        <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const ShareModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Share
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form
                                action="https://app.xpets.com/action/post/share"
                                id="share-post-form"
                                className="ossn-form"
                                method="post"
                                encType="multipart/form-data"
                            >
                                <fieldset>
                                    <input type="hidden" name="ossn_ts" value="1732683242" />
                                    <input type="hidden" name="ossn_token" value="43d92f178962e05f6ff6f7476c7986598c26bcdb69c7d2406daa64bd32223391" />
                                    <div>
                                        <label>Select Type</label>
                                        <select className="ossn-dropdown-input" name="type" id="sharepost-select-type">
                                            <option disabled selected hidden>Select Type</option>
                                            <option value="newsfeed">Newsfeed</option>
                                            <option value="friend">Friend Profile</option>
                                            <option value="group">Group</option>
                                        </select>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="share_type" value="object" />
                                    <input type="submit" className="hidden" id="sharepost-cb" value="Save" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('sharepost-cb').click()} className="btn btn-primary btn-sm">Share</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditModal = ({ showModal, handleClose, postContent, handleSave }) => {
    const [editedContent, setEditedContent] = useState(postContent);
    const [formData, setFormData] = useState({
        title: "2",
        info: "Rgfgfdg",
        start_time: "1:00 AM",
        end_time: "5:00 AM",
        location: "Fdggfdg",
        date: "23-11-2024",
        event_cost: "5",
        allowed_comments: "1",
        is_finished: "no",
        picture: null,
    });

    const [guid] = useState(430);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle file upload
    const handleFileChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            picture: e.target.files[0],
        }));
    };

    // Handle date changes
    const handleDateChange = (date) => {
        setFormData((prev) => ({
            ...prev,
            date: date.format("MM-DD-YYYY"), // Format date as needed
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
    };




    if (!showModal) return null;


    return (
        <div id="editModal" className="modal" style={{ display: "block" }}>
            <div className="modal-content">
                <div className="newsfeed-middle">
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div className="widget-heading">Edit</div>
                            <div className="close" onClick={handleClose}>X</div>
                        </div>
                        <div className="widget-body">

                            <form onSubmit={handleSubmit} className="ossn-form" encType="multipart/form-data">
                                <fieldset>
                                    {/* Event Title */}
                                    <div>
                                        <label>Event Title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Event Description */}
                                    <div>
                                        <label>Event Description</label>
                                        <textarea
                                            name="info"
                                            value={formData.info}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Start Time */}
                                    <div className="time-picker-start">
                                        <label>Start Time</label>
                                        <input
                                            type="text"
                                            name="start_time"
                                            value={formData.start_time}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* End Time */}
                                    <div className="time-picker-end">
                                        <label>End Time</label>
                                        <input
                                            type="text"
                                            name="end_time"
                                            value={formData.end_time}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Location */}
                                    <div>
                                        <label>Location</label>
                                        <input
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Date */}
                                    <div>
                                        <label>Date</label>
                                        <DateTime
                                            value={formData.date}
                                            onChange={handleDateChange}
                                            dateFormat="MM/DD/YYYY"
                                            timeFormat={false}
                                        />
                                    </div>

                                    {/* Price */}
                                    <div>
                                        <label>Price (if any)</label>
                                        <input
                                            type="text"
                                            name="event_cost"
                                            value={formData.event_cost}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Allow Comments & Likes */}
                                    <div>
                                        <label>Allow Comments & Likes</label>
                                        <select
                                            name="allowed_comments"
                                            value={formData.allowed_comments}
                                            onChange={handleChange}
                                        >
                                            <option value="1">Allowed</option>
                                            <option value="0">Not Allowed</option>
                                        </select>
                                    </div>

                                    {/* Close the Event */}
                                    <div>
                                        <label>Close the Event? (Finished)</label>
                                        <select
                                            name="is_finished"
                                            value={formData.is_finished}
                                            onChange={handleChange}
                                        >
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </div>

                                    {/* Image Upload */}
                                    <div>
                                        <label>Image</label>
                                        <input type="file" name="picture" onChange={handleFileChange} />
                                    </div>

                                    {/* Submit Button */}
                                    <div className='mt-3'>
                                        <input type="submit" value="Save" className="btn btn-primary" />
                                    </div>
                                </fieldset>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ReportModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Report this
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form">
                                <fieldset>
                                    <div>
                                        <label>Reason</label>
                                        <textarea name="reason"></textarea>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="type" value="post" />
                                    <input type="submit" className="hidden" id="ossn-file-report" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('ossn-file-report').click()} className="btn btn-primary btn-sm">Save</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

const CommentSection = ({ postId, userData = {}, initialPostContent }) => {
    const [showReportModal, setShowReportModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [attachmentPreview, setAttachmentPreview] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [postContent, setPostContent] = useState(initialPostContent);
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);

    const dropdownRef = useRef();
    const [openDropdowns, setOpenDropdowns] = useState({});
    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleEditPost = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowReportModal = () => setShowReportModal(true);
    const handleSaveEdit = (editedContent) => {
        setPostContent(editedContent);
        setShowEditModal(false);
    };

    const handleCloseReportModal = () => setShowReportModal(false);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdowns({});
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        // Listen for real-time updates
        // socket.on('comment_added', (newComment) => {
        //     setComments((prevComments) => [...prevComments, newComment]);
        // });

        // socket.on('like_updated', (newLikes) => {
        //     setLikes(newLikes);
        // });

        // return () => {
        //     document.removeEventListener('mousedown', handleOutsideClick);
        //     socket.off('comment_added');
        //     socket.off('like_updated');
        // };
    }, []);

    const { avatarUrl = defalutimages, name, username } = userData;

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleDeletePost = () => {
        setShowModal(false);
    };

    const handleCommentPost = () => {
        if (commentText.trim()) {
            const newComment = { user: 'User', text: commentText };
            setComments((prevComments) => [...prevComments, newComment]);
            setCommentText('');
            // socket.emit('add_comment', { postId, comment: newComment });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAttachment(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachmentPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLikePost = () => {
        setIsLiked(!isLiked);
        setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
        // Emit the like update to the backend
        // socket.emit('update_like', { postId, isLiked: !isLiked });
    };

    const handleSharePost = () => {
        setShowShareModal(true);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };


    return (
        <div className="ossn-wall-item ossn-wall-item-476" id={`activity-item-${postId}`}>
            <div className="row">
                <div className="comments-likes">
                    <div className="menu-likes-comments-share">
                        <li>
                            <a href="javascript:void(0);" className="post-control-like" onClick={handleLikePost}>
                                <img src={Like || defalutimages} alt="Like" /> <span>{likes}</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="post-control-comment comment-post">
                                <img src={Comment} alt="Comment" />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="post-control-shareobject share-object-select" onClick={handleSharePost}>
                                <img src={Share} alt="Share" />
                            </a>
                        </li>
                    </div>
                </div>
                <div className="comments-list">
                    {comments.map((comment, index) => (
                        <div className="comments-item" key={index}>
                            <div className="row">
                                <div className="col-md-1">
                                    <img className="comment-user-img" src={profileImage}/>
                                </div>
                                <div className="col-md-10">
                                    <div className="comment-contents">
                                        <p>
                                            <a className="ossn-output-user-url owner-link" >
                                                {name}
                                            </a>
                                            
                                        </p>
                                        {/* <div className="comment-metadata">
                                            <div className="time-created">37 minutes ago</div>
                                            <a className="ossn-like-comment" href="javascript:void(0);">Like it</a>
                                        </div> */}
                                        <span className="comment-text">{comment.text}</span>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div className="ossn-comment-menu">
                                        <div className='dropdown' ref={dropdownRef}>
                                            <a
                                                role="button"
                                                className="btn btn-link p-0"
                                                onClick={() => toggleDropdown(`comment-${index}`)}
                                            >
                                                <img
                                                    src={Menu}
                                                    alt="Menu"
                                                />
                                            </a>
                                            {openDropdowns[`comment-${index}`] && (
                                                <ul className="dropdown-menu multi-level dropdown-menu-end" role="menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item post-control-report ossn-report-this"
                                                            href="javascript:void(0);"
                                                            onClick={handleShowReportModal}
                                                        >
                                                            Report this
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item post-control-delete ossn-wall-post-delete"
                                                            onClick={handleShowModal}
                                                        >
                                                            Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="comments-item">
                        <div className="row">
                            <div className="col-md-1">
                                <img className="comment-user-img" src={defalutimages} alt="User Avatar" />
                            </div>
                            <div className="col-md-11">
                                <div className="ossn-comment-attach-photo" onClick={() => document.getElementById('file-input').click()}>
                                    <i className="fa fa-camera"></i>
                                </div>
                                <div className="ossn-comment-attach-photo">
                                    <i className="fa fa-smile"></i>
                                </div>
                                <span
                                    id="comment-box-p607"
                                    className="comment-box"
                                    contentEditable
                                    onInput={(e) => setCommentText(e.currentTarget.textContent)}
                                    placeholder="Leave a comment..."
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{ __html: commentText }}
                                ></span>
                                <button className="btn btn-primary btn-sm comment-post-btn" onClick={handleCommentPost}>
                                    Post
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} />
            {attachmentPreview && (
                <div className="attachment-preview">
                    <img src={attachmentPreview} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </div>
            )}




            <ConfirmationModal
                showModal={showModal}
                handleClose={handleCloseModal}
                handleConfirm={handleDeletePost}
            />
            <ShareModal
                showModal={showShareModal}
                handleClose={handleCloseShareModal}
                postId={postId}
            />
            <ReportModal
                showModal={showReportModal}
                handleClose={handleCloseReportModal}
                postId={postId}
            />
            <EditModal
                showModal={showEditModal}
                handleClose={handleCloseEditModal}
                postContent={postContent}
                handleSave={handleSaveEdit}
            />
        </div>
    );
};

export default CommentSection;
