import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tab, Nav } from "react-bootstrap";

import TimeLine from "./tabsComponents/timeLine";
import Friends from "./tabsComponents/friends";
import Photos from "./tabsComponents/photos";
import Profile from "./tabsComponents/profile";
import DailyDairy from "./tabsComponents/dailyDairy";
import VideoLog from "./tabsComponents/videoLog";
import Supplements from "./tabsComponents/supplements";
import Events from "./tabsComponents/events";
import { useLocation } from "react-router-dom";
import axios from "axios";
import API from "../../utils";
import { toast } from "react-toastify";
import changeProfile from "../../assets/images/change-photo.svg";
import defalutprofile from "../../assets/images/user/user_default.jpg";
import Bgcoverimg from "../../assets/images/cover-img.webp";
import Loader from "../common/Loader";
import { GrTooltip } from "react-icons/gr";
import InfoModal from "./InfoModal";
import { BsInfoLg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/features/user/userSlice";

const UserEdit = () => {
  const dispatch = useDispatch();
  const uid = localStorage.getItem("user_id");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTabFromUrl = searchParams.get("tab") || "profile";
  const tabs = [
    { key: "profile", title: "Furry Profile" },
    { key: "dairy", title: "Daily Diary" },
    { key: "supplements", title: "Supplements" },
    { key: "timeline", title: "Timeline" },
    { key: "friends", title: "Furry Friends" },
    { key: "photos", title: "Photo Book" },
    { key: "video", title: "Video Log" },
    // { key: "events", title: "Events" },
  ];
  const [activeTab, setActiveTab] = useState(tabs.some((tab) => tab.key === activeTabFromUrl) ? activeTabFromUrl : "profile");
  const [infoModalOpen, setInfoModalOpen] = useState(false); // Manage info modal visibility

  const openInfoModal = () => {
    setInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  useEffect(() => {
    if (tabs.some((tab) => tab.key === activeTabFromUrl)) {
      setActiveTab(activeTabFromUrl);
    } else {
      setActiveTab("profile");
    }
  }, [activeTabFromUrl]);

  const [showCoverControls, setShowCoverControls] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    age: "",
    location: "",
    coverImage: "",
    profileImage: "",
  });

  const [data, setData] = useState({});

  const coverInputRef = useRef(null);
  const profileInputRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleImageUpload = async (file, type) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append(type, file);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.profileUpdate}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === true) {
        window.location.reload();
        // fetchUserData();
        toast.success("Profile updated successfully!");
        if (type === "cover_img") {
          setCoverImage(response.data.payload.cover_img);
        } else if (type === "profile_img") {
          setProfileImage(response.data.payload.profile_img);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // toast.error("Failed to update profile.");
    }
  };

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleImageUpload(file, "cover_img");
    }
  };

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleImageUpload(file, "profile_img");
    }
  };
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}/${uid}`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === true) {
        setData(response.data.payload.user);
        const data = response.data.payload.user;
        dispatch(setUser(data));
        setUserData({
          name: data.user_details.pet_name,
          age: data.user_details.pet_age,
          location: data.user_details.location,
          coverImage: data.cover_img || "",
          profileImage: data.profile_img || "",
        });
        setCoverImage(data.cover_img || Bgcoverimg);
        setProfileImage(data.profile_img || defalutprofile);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch owner data.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Fragment>
        <div className="container-fluid">
          <div className="ossn-profile">
            <div className="top-container">
              <div id="container" className="profile-cover" onMouseEnter={() => setShowCoverControls(true)} onMouseLeave={() => setShowCoverControls(false)}>
                {showCoverControls && (
                  <div
                    className="profile-cover-controls"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <button
                      className="btn-action change-cover"
                      onClick={() => coverInputRef.current.click()}
                      style={{
                        padding: "5px 10px",
                        margin: "0 5px",
                      }}
                    >
                      Change Cover
                    </button>
                  </div>
                )}
                <input type="file" ref={coverInputRef} style={{ display: "none" }} onChange={handleCoverChange} />
                <img id="draggable" className="profile-cover-img" src={coverImage || Bgcoverimg} alt="Profile Cover" style={{ left: 0, top: "-100px" }} />
              </div>
              <div className="profile-photo" id="profile-icon">
                <div className="upload-photo">
                  <span onClick={() => profileInputRef.current.click()}>
                    <img style={{ width: "34px", height: "34px" }} src={changeProfile} alt="Change Profile" />
                  </span>
                  <input type="file" ref={profileInputRef} style={{ display: "none" }} onChange={handleProfileChange} />
                </div>
                <img
                  src={profileImage || defalutprofile}
                  alt="Profile"
                  height="170"
                  width="170"
                // onError={(e) => e.target.src = '../../../assets/images/user/user_default.jpg'}
                />
              </div>
              <div className="user-fullname" id="profile-fullname" data-verified="0">
                {userData.name}
                <img src="https://app.xpets.com/themes/xpets/images/orange-tick.png" alt="Verified Tick" />
              </div>
              <div className="pet-detail-container">
                {userData.age && (
                  <div
                    className="pet-detail"
                    id="pet-detail-age"
                    style={{
                      border: "1px solid rgb(215, 230, 222)",
                      background: "rgba(247, 254, 250, 0.8)"
                    }}
                  >
                    <img
                      src="https://app.xpets.com/themes/xpets/images/gift.png"
                      alt="Gift Icon"
                    />
                    <div className="pet-age" id="pet-age">
                      <small>{userData.age} years old</small>
                    </div>
                  </div>
                )}
                {userData.location && (
                  <div className="pet-detail" id="pet-detail-location" style={{ border: "1px solid rgb(215, 230, 222)", background: "rgba(247, 254, 250, 0.8)" }}>
                    <img src="https://app.xpets.com/themes/xpets/images/pin.png" alt="Location Icon" />
                    <div className="profile-location" id="profile-location">
                      <small>
                        {userData.location}
                      </small>
                    </div>
                  </div>
                )}
                <div className="ossn-wall-privacy p-1" onClick={openInfoModal}>
                  <span>
                    <BsInfoLg style={{ height: "24px", fontSize: "19px", color: "#005248" }} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="tabination-div">
            <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
              <div>
                <div id="profile-hr-menu" className="profile-hr-menu d-block d-sm-none">
                  <ul>
                    <li className="dropdown">
                      <a onClick={toggleDropdown} aria-expanded={isDropdownOpen}>
                        <i className="fa fa-bars fa-2x"></i>
                      </a>
                      {isDropdownOpen && (
                        <ol className={`dropdown-menu`}>
                          {tabs.map(({ key, title }) => (
                            <li key={key}>
                              <a className={`dropdown-item menu-user-timeline-${key}`} onClick={() => setActiveTab(key)} href={`?tab=${key}`}>
                                {title}
                              </a>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  </ul>
                </div>

                <div className="d-none d-sm-block">
                  <Nav variant="tabs">
                    {tabs.map(({ key, title }) => (
                      <Nav.Item key={key}>
                        <Nav.Link eventKey={key} className="tab-name">
                          {title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>

              <Tab.Content className="mt-3">
                {tabs.map(({ key }) => (
                  <Tab.Pane eventKey={key} key={key}>
                    {key === "profile" && <Profile />}
                    {key === "dairy" && <DailyDairy />}
                    {key === "supplements" && <Supplements />}
                    {key === "timeline" && <TimeLine activeTab={activeTab}/>}
                    {key === "friends" && <Friends />}
                    {key === "photos" && <Photos />}
                    {key === "video" && <VideoLog activeTab={activeTab} />}
                    {key === "events" && <Events />}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Fragment>
      <InfoModal show={infoModalOpen} onHide={closeInfoModal} />
    </>
  );
};

export default UserEdit;
