import { Home, Box, DollarSign, UserPlus, Users, Chrome, Settings, Airplay, FolderPlus, File, Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail, MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search, AlertOctagon, Lock, Briefcase, BarChart, Target, List } from "react-feather";

export const MENUITEMS = [
  {
    title: "Home",
    icon: Home,
    image: require('../../../assets/images/sidebar/home.png'),
    path: "/home",
    type: "link",
    active: false,
  },
  {
    title: "Reels",
    icon: Home,
    image: require('../../../assets/images/sidebar/video-player-svgrepo-com.png'),
    path: "/reels",
    type: "link",
    active: false,
  },
  {
    title: "Furry Friends",
    icon: Home,
    image: require('../../../assets/images/sidebar/friends.png'),
    path: "/users/userEdit",
    type: "link",
    active: false,
    // data: '?section=photos',
    data: 'friends',
  },
  {
    title: "Photos Book",
    icon: Home,
    image: require('../../../assets/images/sidebar/photos.png'),
    path: "/users/userEdit",
    type: "link",
    active: false,
    // data: '?section=photos',
    data: 'photos',
  },
  {
    title: "Video Log",
    icon: Box,
    image: require('../../../assets/images/sidebar/video.png'),
    path: "/users/userEdit",
    type: "link",
    active: false,
    // data: '?section=photos',
    data: 'video',
  },
  {
    title: "Barks and meows",
    icon: Box,
    image: require('../../../assets/images/sidebar/envelope.png'),
    type: "link",
    active: false,
    path: "/messages",
  },
  // {
  //   title: "Pack chat",
  //   icon: Cloud,
  //   image: require('../../../assets/images/sidebar/groups.png'),
  //   path: "/groups",
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Events",
  //   icon: Command,
  //   image: require('../../../assets/images/sidebar/calendar.png'),
  //   path: "/users/userEdit",
  //   type: "link",
  //   active: false,
  //   // data: '?section=photos',
  //   data: 'events',
  // },
  {
    title: "Shop",
    icon: Command,
    image: require('../../../assets/images/sidebar/cart.png'),
    path: "https://www.xpets.com/",
    type: "link",
    active: false,
    isExternal: true, // Add this property
  },
  // {
  //   title: "Fetch Friends",
  //   icon: FolderPlus,
  //   image: require('../../../assets/images/sidebar/invite-friends.png'),
  //   type: "link",
  //   active: false,
  //   path: "/invite",
  // },
  {
    title: "Blogs",
    icon: Command,
    image: require('../../../assets/images/sidebar/groups.png'),
    path: "https://www.xpets.com/work/test",
    type: "link",
    active: false,
    isExternal: true, // Add this property
  },
  // {
  //   title: "Support",
  //   icon: MessageSquare,
  //   type: "link",
  //   path: "/chat-app/chat",
  //   image: require('../../../assets/images/sidebar/support.png'),
  //   active: false,
  //   bookmark: true,
  // },
  {
    title: "Support",
    image: require('../../../assets/images/sidebar/support.png'),
    icon: MessageSquare,
    path: "https://www.xpets.com/support",
    type: "link",
    active: false,
    isExternal: true,
  },
  {
    title: "Settings",
    icon: Headphones,
    type: "link",
    image: require('../../../assets/images/sidebar/settings.png'),
    path: "/users/userEdit",
    type: "link",
    active: false,
    // data: '?section=photos',
    data: 'profile',
  },
];
