import React, { Fragment, useEffect, useState } from "react";

import NewsfeedRight from "../birthDayCoponent/newsfeedright";
import Event from "./event";
import API from "../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";

const EventDetails = () => {
  const [apiData, setApiData] = useState('');
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); // Assuming your route includes an :id parameter
  const Fetchuserlist = async (id) => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
        const response = await axios.get(
            `${API.BASE_URL}${API.ENDPOINTS.eventdetails}/${id}`, // Include the ID in the URL
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.data.status) {
            setApiData(response.data.payload);
        } else {
            toast.error(response.data.message, {
                className: 'toast-error', // Custom error class
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    } catch (error) {
        // toast.error("Failed to fetch user data.");
    } finally {
        setLoading(false); // Stop loading
    }
};



useEffect(() => {
    Fetchuserlist(id);
}, [id]);



  
  const [posts, setPosts] = useState([
    {
      postId: 1,
      userData: {
        avatarUrl: "https://app.xpets.com/avatar/chinnumvijayan/small/8638f832ab254412bd3cee8c15614e03.jpeg",
        username: "chinnumvijayan",
        name: "Promy",
      },
      postContent: "This is the first post.",
    },
    
  ]);

  const [newPostContent, setNewPostContent] = useState("");

  const handleAddPost = () => {
    if (newPostContent.trim()) {
      const newPost = {
        postId: posts.length + 1,
        userData: {
          avatarUrl: "https://app.xpets.com/avatar/default-avatar.jpg", 
          username: "newuser",
          name: "New User",
        },
        postContent: newPostContent,
      };
      setPosts([newPost, ...posts]);
      setNewPostContent("");
    }
  };

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
          <div className="newsfeed-container">
              <div className="newsfeed-middle">
               
                {/* <PostForm /> */}
                {posts.map((post) => (
                  <Event
                    key={post.postId}
                    postId={post.postId}
                    userData={post.userData}
                    postContent={post.initialPostContent }
                  />
                ))}
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventDetails;
