import React, { Fragment, useEffect, useId, useState } from "react";
import moment from "moment"; // Import moment
import API from "../../utils";
import { getData } from "../../utils/axiosApiReq";
import { Link } from "react-router-dom";

const NewsfeedRight = () => {
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [trendingTags, setTrendingTags] = useState([]);
  const uniqueId = useId();

  const getUpcomingBirthdays = async () => {
    try {
      const response = await getData(API.ENDPOINTS.upcomingBirthdays);
      const { status, message, payload } = response;
      if (status) {
        setUpcomingBirthdays(payload);
      } else {
        console.error("Error fetching upcomingBirthdays:", message);
      }
    } catch (error) {
      console.error("Error fetching upcomingBirthdays:", error);
    }
  };

  const getTrendingTags = async () => {
    try {
      const response = await getData(API.ENDPOINTS.trendingTags);
      const { status, message, payload } = response;
      if (status) {
        setTrendingTags(payload);
      } else {
        console.error("Error fetching getTrendingTags:", message);
      }
    } catch (error) {
      console.error("Error fetching getTrendingTags:", error);
    }
  };

  useEffect(() => {
    getUpcomingBirthdays();
    getTrendingTags();
  }, []);

  return (
    <>
      <div className="ossn-widget birthdays">
        <div className="widget-heading">
          <span>Birthdays upcoming</span>
        </div>
        <div className="widget-contents">
          <ul>
            {upcomingBirthdays && upcomingBirthdays.length > 0 ? (
              upcomingBirthdays.map((birthdayFriend) => {
                // Log and format date using moment
                console.log("Raw pet_dob:", birthdayFriend?.pet_dob);

                const formattedDate = moment(birthdayFriend?.pet_dob).isValid()
                  ? moment(birthdayFriend?.pet_dob).format("MMMM Do, YYYY")
                  : "Unknown Date"; // Fallback if date is invalid

                return (
                  <Link
                    to={`/user-profile-view/${birthdayFriend.id}`}
                    key={birthdayFriend.id}
                  >
                    <li>
                      <img
                        className="birthday-icon"
                        src="https://app.xpets.com/themes/xpets/images/birthday.png"
                        alt="Birthday Icon"
                      />
                      <div className="birthday-text">
                        {birthdayFriend?.pet_name}
                        <br />
                        <span className="birthday-date">{formattedDate}</span>
                      </div>
                    </li>
                  </Link>
                );
              })
            ) : (
              <div>No upcoming birthdays!</div>
            )}
          </ul>

        </div>
      </div>

      <div className="ossn-widget hashtag-trending">
        <div className="widget-heading">Trending</div>
        <div className="widget-contents">
          {trendingTags && trendingTags.length > 0 ? (
            trendingTags.map((tag) => {
              return (
                <Fragment key={`${uniqueId}${tag.tag}`}>
                  <Link
                    className="hashtag-trending-link"
                    to={`/trending/${tag?.tag.replace("#", "")}`}
                  >
                    <div className="hashtag-trending-container mb-2">
                      <div className="d-inline-block">
                        <div className="hashtag-trending-title">{tag.tag}</div>
                        <div className="hashtag-trending-count">
                          {tag?.post_count} wall posts
                        </div>
                      </div>
                    </div>
                  </Link>
                  <br />
                </Fragment>
              );
            })
          ) : (
            <div>No trending tags!</div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsfeedRight;
