import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
// import "./index.scss";
import "../src/i18n";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/index.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
// import "./assets/css/temp.css";
// ** Import custom components for redux **
import { Provider, useDispatch } from "react-redux";
// import store from "./store";
import store from "./store/store";
import MainRoutes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { toast } from "react-toastify";
import axios from "axios";

const Root = () => {
  // const dispatch = useDispatch();
  const [googleOAuthProvider, setGoogleOAuthProvider] = useState(null);

  const setGoogleOauth = (authId) => setGoogleOAuthProvider(authId);

  useEffect(() => {
    // dispatch(getSoicalAuthKey("facebook"))
    // const getSoicalAuthKey = async () => {
    //   try {
    //     const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.sitesettings}?name=google`);

    //     if (response.data.status) {
    //       setGoogleOAuthProvider(response.data.payload.OAuth);
    //     } else {
    //       toast.error(response.data.message);
    //     }
    //   } catch (error) {
    //     console.log(error, "error");
    //     // toast.error(error.response.data.message);
    //   }
    // };
    // getSoicalAuthKey();
  }, []);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID || googleOAuthProvider }`}>
        <Provider store={store}>
          <MainRoutes setGoogleOauth={setGoogleOauth}/>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.unregister();
